type DateValue = string | Date

export const setDayDateZero = (dateDay: number): string => {
  return String(dateDay < 10 ? '0' + dateDay : dateDay)
}

export const dateFormatter = (
  value: string | Date
): {
  day: string
  month: {
    numeric: string
    long: string
  }
  year: string
  fullDateEN: string
} | null => {
  const date = new Date(value)

  if (!date) return null

  const dayNumeric = setDayDateZero(date?.getDate())
  const monthNumeric = setDayDateZero(date?.getMonth() + 1)
  const monthLong = date?.toLocaleString('pt-BR', {
    month: 'long'
  })
  const year = date?.toLocaleString('pt-BR', {
    year: 'numeric'
  })

  console.log(
    {
      day: dayNumeric,
      month: {
        numeric: monthNumeric,
        long: monthLong
      },
      year,
      fullDateEN: `${year}-${monthNumeric}-${dayNumeric}`
    },
    'aquui'
  )

  return {
    day: dayNumeric,
    month: {
      numeric: monthNumeric,
      long: monthLong
    },
    year,
    fullDateEN: `${year}-${monthNumeric}-${dayNumeric}`
  }
}

export const formattedDateToNumericPT = (date: DateValue) => {
  if (!date) return null

  let convertDate = new Date(date).toISOString().substring(0, 10)

  convertDate = new Date(convertDate).toLocaleDateString('pt-BR', {
    timeZone: 'UTC'
  })

  return convertDate
}

export const formattedStringDateToStringDateEn = (date: string) => {
  if (!date) return null

  const splitDate = date.split('/')

  if (splitDate.length < 3) return null

  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
}
