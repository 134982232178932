import React, { useState } from 'react'
import {
  Button,
  Box,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  CircularProgress,
  FormControl,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  ModalCloseButton,
  useToast,
  Input
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'

import { Divider } from '@/components/Elements'
import SmsService from '@/services/SmsService'
import ApiService from '@/services/ApiService'
import useDidMount from '@/contexts/hooks/useDidMount'
import useValidation, { ErrorType } from '@/contexts/hooks/useValidation'

interface ISmsConfirmationProps {
  phone: string
  onSuccess: () => void
  onChangePhoneSuccess: (newPhoneNumber: string) => Promise<void>
}
const SmsConfirmation = (props: ISmsConfirmationProps) => {
  const { phone, onSuccess, onChangePhoneSuccess } = props

  const toast = useToast()
  const [resendTimeLeft, setResendTimeLeft] = useState<number>(30)

  const [mobilePhoneInput, setMobilePhoneInput] = useState<string>('')
  const [openChangeMobilePhoneModal, setOpenChangeMobilePhoneModal] =
    useState<boolean>(false)
  const [mobilePhoneLoading, setMobilePhoneLoading] = useState<boolean>(false)
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null)
  const [smsConfirmationLoading, setSmsConfirmationLoading] =
    useState<boolean>(false)

  const { triggerValidation, clearValidation, validation } = useValidation()

  const handleSmsCodeChange = (smsCode: string | null) => {
    clearValidation('confirmation_code')
    setConfirmationCode(smsCode)
  }

  const timerCountdown = () => {
    let timeInSeconds = 30

    const interval = setInterval(function () {
      if (timeInSeconds > 0) {
        timeInSeconds -= 1
        setResendTimeLeft(timeInSeconds)
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }

  const toggleMobilePhoneModal = () => {
    handleSmsCodeChange(null)

    setOpenChangeMobilePhoneModal(currentState => !currentState)
  }

  const onChangeMobilePhoneSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    setMobilePhoneLoading(true)
    try {
      await ApiService.patch(
        '/user/phone',
        {
          phone
        },
        {
          params: {
            phone: mobilePhoneInput
          }
        }
      )

      await onChangePhoneSuccess(mobilePhoneInput)

      toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Número alterado com sucesso'
      })

      toggleMobilePhoneModal()
    } catch (error) {
      triggerValidation(error as ErrorType)
    }
    setMobilePhoneLoading(false)
  }

  const handleSmsConfirmationSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    setSmsConfirmationLoading(true)

    try {
      await SmsService.validateMobilePhone({
        confirmation_code: confirmationCode
      })

      toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Celular confirmado!'
      })
      onSuccess()
    } catch (error) {
      triggerValidation(error as ErrorType)
    }

    setSmsConfirmationLoading(false)
  }

  const handleResendConfirmationCode = async () => {
    setSmsConfirmationLoading(true)
    handleSmsCodeChange(null)

    try {
      setResendTimeLeft(30)

      await SmsService.sendSmsConfirmation({
        phone
      })
      toast({
        status: 'info',
        title: 'Sucesso',
        description: 'SMS enviado!'
      })

      timerCountdown()
    } catch (error) {
      triggerValidation(error as ErrorType)
    }
    setSmsConfirmationLoading(false)
  }

  useDidMount(() => {
    timerCountdown()
  })

  return (
    <>
      <form onSubmit={handleSmsConfirmationSubmit}>
        <Flex justify="center">
          <Box>
            <FormControl id="sms_confirmation_code">
              <HStack>
                <PinInput
                  isInvalid={Boolean(validation.confirmation_code)}
                  errorBorderColor="crimson"
                  colorScheme="cyan"
                  isDisabled={smsConfirmationLoading}
                  value={confirmationCode || ''}
                  onChange={handleSmsCodeChange}
                  autoFocus
                  size="lg"
                >
                  <PinInputField borderColor="#92969b" />
                  <PinInputField borderColor="#92969b" />
                  <PinInputField borderColor="#92969b" />
                  <PinInputField borderColor="#92969b" />
                  <PinInputField borderColor="#92969b" />
                </PinInput>
              </HStack>

              <FormHelperText textAlign="center" color="crimson">
                {validation.confirmation_code}
              </FormHelperText>
            </FormControl>
          </Box>
        </Flex>

        <Divider size={3} orientation="horizontal" />

        <Flex justify="center" flexWrap={['wrap']}>
          <Box width="100%">
            <Button
              variant="link"
              color="gray.700"
              textDecoration="none"
              type="submit"
              isDisabled={smsConfirmationLoading}
              rightIcon={
                smsConfirmationLoading ? (
                  <CircularProgress
                    isIndeterminate
                    size={'16px'}
                    color="palette.primary"
                  />
                ) : undefined
              }
              isFullWidth
            >
              Confirmar
            </Button>
          </Box>

          <Divider size={1.5} orientation="horizontal" />

          <Box textAlign="center" width={['100%', '50%']} mb={['8px', 0]}>
            <Button
              onClick={handleResendConfirmationCode}
              variant="link"
              color="gray.500"
              isDisabled={resendTimeLeft > 0 || smsConfirmationLoading}
            >
              Reenviar código {resendTimeLeft > 0 ? `(${resendTimeLeft})s` : ''}
            </Button>
          </Box>

          <Box textAlign="center" width={['100%', '50%']}>
            <Button
              onClick={toggleMobilePhoneModal}
              variant="link"
              color="gray.500"
              isDisabled={smsConfirmationLoading}
            >
              Alterar número de celular
            </Button>
          </Box>
        </Flex>
      </form>

      <Modal
        isOpen={openChangeMobilePhoneModal}
        onClose={toggleMobilePhoneModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent background="white">
          <ModalHeader>
            Alterar o número de celular
            <Divider size={2} orientation="horizontal" />
            <Text textStyle="caption">
              Adicione um número de celular válido
            </Text>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody mt={2} mb={4}>
            <form onSubmit={onChangeMobilePhoneSubmit}>
              <Text mb="8px">Celular:</Text>
              <Input
                tabIndex={1}
                id="input_phone"
                as={InputMask}
                mask="(99) 9 9999-9999"
                placeholder="(99) 9 9999-9999"
                name="phone"
                value={mobilePhoneInput}
                onChange={({ target }) => setMobilePhoneInput(target.value)}
                isRequired
                isInvalid={Boolean(validation.phone)}
                isDisabled={mobilePhoneLoading}
                borderColor="gray.300"
                _placeholder={{ color: 'gray.500' }}
              />
              {validation.phone && (
                <Text color="crimson" fontSize={'0.875rem'}>
                  {validation.phone}
                </Text>
              )}

              <Divider size={3} orientation="horizontal" />

              <Button
                border="1px solid gray.500"
                type="submit"
                isDisabled={mobilePhoneLoading}
                rightIcon={
                  mobilePhoneLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size={'16px'}
                      color="palette.primary"
                    />
                  ) : undefined
                }
                isFullWidth
              >
                Alterar
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SmsConfirmation
