/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Select,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack
} from '@chakra-ui/react'
import { IScholarshipData } from '@/protocols/User.protocol'
import { motion } from 'framer-motion'
import { validateBr } from 'js-brasil'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import PacmanLoader from 'react-spinners/PacmanLoader'
import ReactPaginate from 'react-paginate'

import {
  Page,
  Overlay,
  Grid,
  Card,
  CardStep,
  CardImage,
  CardTitle,
  CardDescription,
  Form,
  Heading,
  Row,
  Button
} from '@/styles/pages/Subscription'

import InputMask from 'react-input-mask'
import SyncLoader from 'react-spinners/SyncLoader'

import { Container } from '@/styles/global'

import SuggestionInput from '@/components/Elements/SuggestionInput'
import SearchFilters from '@/components/Elements/SearchFilters'
import ScholarshipCard from '@/components/Elements/ScholarshipCard'
import Menu from '@/components/Elements/Menu'

import useForm from '@/contexts/hooks/use-form'

import noteSrc from '@/assets/images/Note.png'
import checkSrc from '@/assets/images/Check.png'
import womanSrc from '@/assets/images/Woman.png'
import ApiService from '@/services/ApiService'
import colors from '@/styles/colors'
import {
  Divider,
  Loading,
  SmsConfirmation,
  SearchCityBoxFilter,
  InputEmail
} from '@/components/Elements'
import useDidMount from '@/contexts/hooks/useDidMount'
import { useGlobalStateStore } from '@/store/GlobalState'
import SmsService from '@/services/SmsService'

import { formatEmail } from '@/utils/email.util'

type IModalityFilter =
  | 'ead_modality'
  | 'semiPresential_modality'
  | 'presential_modality'
  | 'all_modality'

type IShiftFilter =
  | 'morning_shift'
  | 'afternoon_shift'
  | 'night_shift'
  | 'ead_shift'

const Subscription = () => {
  const history = useHistory()
  const location = useLocation()
  const { step } = useParams() as {
    step: string
  }
  const toast = useToast()
  const {
    user: userData,
    campaign: campaignData,
    setUserData
  } = useGlobalStateStore()

  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  })

  const [modalityFilter, setModalityFilter] = useState<IModalityFilter | null>(
    null
  )
  const [shiftFilter, setShiftFilter] = useState<IShiftFilter | null>(null)

  const [show, setShow] = useState<boolean>(false)
  const [error, setError] = useState<{ [error: string]: string | null } | null>(
    null
  )

  const [scrollIdent, setScrollIdent] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [scholarships, setScholarships] = useState<{
    meta: {
      last_page?: number
    }
    data: IScholarshipData[]
  }>({
    data: [],
    meta: {
      last_page: 0
    }
  })
  const [openSmsConfirmationModal, setOpenSmsConfirmationModal] =
    useState<boolean>(false)
  const [openEmailConfirmationModal, setOpenEmailConfirmationModal] =
    useState<boolean>(false)

  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [emailLoading, setEmailLoading] = useState<boolean>(false)

  const toggleSmsConfirmationModal = () => {
    setOpenSmsConfirmationModal(currentState => !currentState)
  }

  const toggleEmailConfirmationModal = () => {
    setOpenEmailConfirmationModal(currentState => !currentState)
  }

  const onSmsConfirmationSuccess = async () => {
    toggleSmsConfirmationModal()
  }

  const onChangePhoneSuccess = async (newMobilePhone: string) => {
    await SmsService.sendSmsConfirmation({
      phone: newMobilePhone
    })
  }

  const {
    courses,
    cpf,
    setCpf,
    birthDate,
    setBirthDate,
    genre,
    setGenre,
    city,
    setCity,
    setCities,
    courseId,
    setCourseId,
    setScholarship,
    searchCity,
    setSearchCity
  } = useForm() as any

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 500)

    return () => {
      setShow(false)
    }
  }, [])

  const handleEmailChange = (email: string) => {
    setUserData({ ...userData, email })
  }

  const handleErrorChange = (type: 'email', message: string | null) => {
    setError(currentState => ({
      ...currentState,
      [type]: message
    }))
  }

  async function getScholarships(page: number) {
    setLoading(true)
    setScholarships({
      data: [],
      meta: {}
    })

    const params = {
      courseId: courseId ? (courseId !== '' ? courseId : undefined) : undefined,
      city: city,
      page: page || 1,
      modality: modalityFilter,
      shift: shiftFilter
    }
    try {
      const { data: scholarshipsData } = await ApiService.get(
        'campaign/scholarships',
        {
          params
        }
      )

      setScholarships(scholarshipsData)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  async function hasCpf() {
    setLoading(true)
    let hasCpf = false
    try {
      await ApiService.get('user/cpf', {
        params: {
          cpf
        }
      })

      hasCpf = true
    } catch (error) {
      hasCpf = false
    }

    setLoading(false)

    return hasCpf
  }
  const hasEmail = async () => {
    let hasCpf = false
    try {
      await ApiService.get('user/email', {
        params: {
          email: userData.email
        }
      })

      hasCpf = false
    } catch (error) {
      hasCpf = true
    }

    return hasCpf
  }

  const validatedEmail = async () => {
    setEmailLoading(true)

    if (await hasEmail()) {
      toast({
        title: 'Atenção!',
        description: 'Este e-mail já está em uso no sistema. Tente outro.',
        status: 'warning',
        duration: 5000,
        isClosable: true
      })
    } else {
      history.push({
        pathname: '/inscricao/confirmacao',
        search: location.search
      })
    }

    setEmailLoading(false)
  }

  const onModalityFilterChange = (data: IModalityFilter) => {
    setModalityFilter(data)
  }

  const onShiftFilterChange = (data: IShiftFilter) => {
    setShiftFilter(data)
  }

  // const handleOpenSmsConfirmationModal = async (phone: string) => {
  //   try {
  //     await SmsService.sendSmsConfirmation({
  //       phone
  //     })

  //     toggleSmsConfirmationModal()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    if (Number(step) === 2 && !loading) {
      getScholarships(page)
    }
  }, [step, page, courseId, city, modalityFilter, shiftFilter])

  const initialize = async () => {
    setLoadingPage(true)

    // try {
    //   const isConfirmed = await SmsService.checkIfMobilePhoneIsConfirmed()

    //   if (!isConfirmed) {
    //     handleOpenSmsConfirmationModal(userData.profile.phone)
    //   }
    // } catch (error) {
    //   history.push('/')
    //   console.log(error)
    // }

    setLoadingPage(false)
  }

  useDidMount(() => {
    initialize()
  })

  return (
    <Page>
      <Menu inProcess scrollTop={scrollIdent} />

      <Loading loading={loadingPage}>
        <Overlay
          onScroll={(e: any) => {
            setScrollIdent(e.nativeEvent.target.scrollTop)
          }}
        >
          {isMobileDevice && <div style={{ height: 80 }} />}
          {show && (
            <Container>
              <Grid>
                <Card>
                  <CardStep>{step}</CardStep>

                  {Number(step) === 1 && (
                    <CardTitle>Veja as opções de bolsas disponíveis</CardTitle>
                  )}

                  {Number(step) === 2 && (
                    <CardTitle>Selecione uma bolsa</CardTitle>
                  )}

                  {Number(step) === 3 && (
                    <CardTitle>Complete seus dados</CardTitle>
                  )}

                  <CardDescription>
                    Faculdades de todo o Brasil ofertam bolsas de estudos de até
                    100% na campanha <b>Vale Bolsa Beduka</b>.
                    <br />
                    <br />
                    Os estudantes com as melhores classificações na Prova de
                    Conhecimento do Beduka serão premiados.
                    <br />
                    <br />{' '}
                    <Text
                      textAlign="justify"
                      dangerouslySetInnerHTML={{
                        __html: `<b>Data da prova:</b> ${campaignData.exam_date_instructions}`
                      }}
                    />
                    <br />
                    Informe a cidade e curso para ver se existem bolsas
                    disponíveis.
                    <br />
                    <br />
                    Escolha a que for mais adequada pra você e conclua a sua
                    inscrição!
                  </CardDescription>

                  {Number(step) === 1 && <CardImage src={noteSrc} />}

                  {Number(step) === 2 && <CardImage src={checkSrc} />}

                  {Number(step) === 3 && <CardImage src={womanSrc} />}
                </Card>
                <div>
                  <Form>
                    {Number(step) === 1 && (
                      <Heading>Selecione um curso disponível abaixo</Heading>
                    )}
                    {Number(step) === 2 && (
                      <Heading>
                        Selecione uma das bolsas disponíveis abaixo para
                        prosseguir com sua inscrição
                      </Heading>
                    )}
                    {Number(step) === 3 && (
                      <Heading>
                        Agora é só informar seus dados para finalizar sua
                        inscrição
                      </Heading>
                    )}
                    {Number(step) === 1 && (
                      <>
                        <SuggestionInput
                          label="Digite o nome do curso:"
                          data={courses?.filter((course: { name: string }) =>
                            course.name
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .toLowerCase()
                              .includes(
                                searchCity
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .toLowerCase()
                              )
                          )}
                          onSelect={(option: {
                            id: number
                            cities: string[]
                          }) => {
                            setCourseId(option.id)

                            setCities(option.cities)
                            setCity(null)
                          }}
                          readyToSearch={setSearchCity}
                          nameToPrint="name"
                          debounce={null}
                          reset={null}
                          showOnFocus={null}
                        />
                      </>
                    )}

                    {Number(step) === 2 && (
                      <>
                        <SearchFilters onReset={() => setPage(1)} />

                        <Divider size={3} orientation="horizontal" />

                        <Box
                          borderRadius="5px"
                          padding="20px"
                          background="#F3FAFB"
                        >
                          <HStack
                            spacing={[0, 0, 4]}
                            flexWrap={['wrap', 'wrap', 'nowrap']}
                            alignItems="center"
                          >
                            <Box
                              mt={['12px !important', '12px !important', '4px']}
                              width={['100%', '100%', 'auto']}
                            >
                              <Text fontWeight={600}>Refinar busca</Text>
                            </Box>

                            <Box
                              mt={['12px !important', '12px !important', '4px']}
                              width={['100%', '100%', 'auto']}
                            >
                              <Text fontSize="12px" color="gray.600">
                                Modalidade
                              </Text>
                              <Select
                                color="gray.500"
                                onChange={({ target }) =>
                                  onModalityFilterChange(
                                    target.value as IModalityFilter
                                  )
                                }
                                value={modalityFilter || ''}
                              >
                                <option disabled selected value="">
                                  Modalidade
                                </option>
                                <option value="all_modality">Todas</option>
                                <option value="presential_modality">
                                  Presencial
                                </option>
                                <option value="semiPresential_modality">
                                  Semipresencial
                                </option>
                                <option value="ead_modality">Ead</option>
                              </Select>
                            </Box>

                            <Box
                              mt={['12px !important', '12px !important', '4px']}
                              width={['100%', '100%', 'auto']}
                            >
                              <Text fontSize="12px" color="gray.600">
                                Turno
                              </Text>
                              <Select
                                color="gray.500"
                                onChange={({ target }) =>
                                  onShiftFilterChange(
                                    target.value as IShiftFilter
                                  )
                                }
                                value={shiftFilter || ''}
                              >
                                <option disabled selected value="">
                                  Turno
                                </option>
                                <option value="all_shift">Todos</option>
                                <option value="morning_shift">Manhã</option>
                                <option value="afternoon_shift">Tarde</option>
                                <option value="night_shift">Noite</option>
                                <option value="ead_shift">Ead</option>
                              </Select>
                            </Box>

                            <Box
                              mt={['12px !important', '12px !important', '4px']}
                              width={['100%', '100%', 'auto']}
                            >
                              <Text fontSize="12px" color="gray.600">
                                Cidade
                              </Text>
                              <Box>
                                <SearchCityBoxFilter
                                  onReset={() => setPage(1)}
                                />
                              </Box>
                            </Box>
                          </HStack>
                        </Box>

                        <Divider size={3} orientation="horizontal" />

                        {loading && (
                          <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 50 }}
                            transition={{
                              delay: 0.8
                            }}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: 60
                            }}
                          >
                            <PacmanLoader color="var(--primary)" size={30} />
                            <h4 style={{ marginTop: 40, textAlign: 'center' }}>
                              Estamos buscando as bolsas
                              <br />
                              disponíveis, por favor, aguarde...
                            </h4>
                          </motion.div>
                        )}
                        <Row>
                          {!loading &&
                          scholarships &&
                          scholarships.data.length ? (
                            scholarships?.data.map((el, idx) => (
                              <ScholarshipCard
                                key={idx}
                                data={el}
                                type=""
                                onSelect={() => {
                                  setScholarship(el)
                                  history.push({
                                    pathname: '/inscricao/etapa/3',
                                    search: location.search
                                  })
                                }}
                              />
                            ))
                          ) : (
                            <Box width="100%" mt="32px">
                              <Text
                                fontWeight={600}
                                color={colors.palette.primary}
                                textAlign="center"
                              >
                                Não encontramos resultados para essa busca
                              </Text>
                            </Box>
                          )}
                        </Row>
                        {!isMobileDevice && (
                          <Divider size={2} orientation="horizontal" />
                        )}

                        {!loading && scholarships?.data.length ? (
                          <ReactPaginate
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            disabledClassName="disabled"
                            pageCount={scholarships?.meta?.last_page || 0}
                            forcePage={page - 1}
                            pageRangeDisplayed={isMobileDevice ? 2 : 5}
                            marginPagesDisplayed={1}
                            previousLabel={
                              isMobileDevice ? '<-' : 'Página anterior'
                            }
                            nextLabel={isMobileDevice ? '->' : 'Próxima página'}
                            onPageChange={({ selected }) => {
                              window.scrollTo(0, 0)
                              setPage(() => Number(selected) + 1)
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {Number(step) === 3 && (
                      <>
                        <div>
                          <label>CPF</label>
                          <InputMask
                            className="custom-input"
                            value={cpf}
                            mask="999.999.999-99"
                            onChange={e => setCpf(e.target.value)}
                          />
                        </div>
                        <div>
                          <label>Data de Nascimento</label>
                          <InputMask
                            className="custom-input"
                            value={birthDate}
                            mask="99/99/9999"
                            onChange={e => setBirthDate(e.target.value)}
                          />
                        </div>
                        <div>
                          <label>Sexo</label>
                          <select
                            className="custom-input"
                            value={genre}
                            onChange={e => setGenre(e.target.value)}
                          >
                            <option value="MALE">Masculino</option>
                            <option value="FEMALE">Feminino</option>
                            <option value="NOT_BINARY">Não binário</option>
                          </select>
                        </div>
                      </>
                    )}

                    {Number(step) === 1 && (
                      <Button
                        disabled={!courseId}
                        onClick={() => {
                          if (courseId) {
                            history.push({
                              pathname: '/inscricao/etapa/2',
                              search: location.search
                            })
                          }
                        }}
                      >
                        Prosseguir
                      </Button>
                    )}

                    {Number(step) === 3 && (
                      <>
                        {loading ? (
                          <SyncLoader
                            color="rgba(0, 0, 0, 0.4)"
                            loading={loading}
                            size={10}
                          />
                        ) : (
                          <Button
                            disabled={!cpf || !birthDate || !genre}
                            onClick={async () => {
                              if (!validateBr.cpf(cpf)) {
                                return toast({
                                  title: 'Atenção!',
                                  description: 'Informe um CPF válido',
                                  status: 'warning',
                                  duration: 5000,
                                  isClosable: true
                                })
                              }

                              const d = new Date(
                                birthDate.split('/').reverse().join('-')
                              )
                              const currentDate = new Date()

                              if (
                                !(d instanceof Date && !isNaN(d as any)) ||
                                new Date(d) > currentDate
                              ) {
                                return toast({
                                  title: 'Atenção!',
                                  description:
                                    'Informe uma data de nascimento válida',
                                  status: 'warning',
                                  duration: 5000,
                                  isClosable: true
                                })
                              }

                              const cpfExists = await hasCpf()

                              if (!cpfExists) {
                                return toast({
                                  title: 'Atenção!',
                                  description: 'Este CPF já está cadastrado',
                                  status: 'warning',
                                  duration: 5000,
                                  isClosable: true
                                })
                              }

                              // const isConfirmed =
                              //   await SmsService.checkIfMobilePhoneIsConfirmed()

                              // if (!isConfirmed) {
                              //   handleOpenSmsConfirmationModal(
                              //     userData.profile.phone
                              //   )
                              // } else {
                              //   toggleEmailConfirmationModal()
                              // }

                              toggleEmailConfirmationModal()
                            }}
                          >
                            Prosseguir
                          </Button>
                        )}
                      </>
                    )}
                  </Form>
                </div>
              </Grid>
            </Container>
          )}
        </Overlay>
      </Loading>

      <Modal
        isOpen={openEmailConfirmationModal}
        onClose={toggleEmailConfirmationModal}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent background="white">
          <ModalHeader>
            Confirme o seu e-mail
            <Divider size={2} orientation="horizontal" />
            <Text textStyle="caption">
              <b>O e-mail é nossa principal forma de contato com você.</b>
              <br />
              <br />
              Confira se está correto, caso contrário, altere.
            </Text>
          </ModalHeader>
          <ModalBody mb={4}>
            <InputEmail
              initialValue={userData.email}
              onChange={value =>
                handleEmailChange(formatEmail(value as string))
              }
              onError={message => handleErrorChange('email', message as string)}
            />

            <Button
              onClick={validatedEmail}
              disabled={error?.email || emailLoading}
            >
              Prosseguir
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={openSmsConfirmationModal}
        onClose={toggleSmsConfirmationModal}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent background="white">
          <ModalHeader>
            Confirme o seu celular
            <Divider size={2} orientation="horizontal" />
            <Text textStyle="caption">
              Enviamos um SMS para o seu celular com o código de verificação
            </Text>
          </ModalHeader>
          <ModalBody mt={2} mb={4}>
            <SmsConfirmation
              phone={userData?.profile.phone as string}
              onSuccess={onSmsConfirmationSuccess}
              onChangePhoneSuccess={newPhoneNumber =>
                onChangePhoneSuccess(newPhoneNumber)
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  )
}

export default Subscription
