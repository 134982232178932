import { motion } from 'framer-motion'
import styled from 'styled-components'

const transition = { ease: [0.43, 0.13, 0.23, 0.96] }
const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition }
  }
}

export const Overlay = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants
})`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(18, 34, 50, 0.85);

  z-index: 9999;
`

export const Container = styled(motion.div).attrs({
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    visible: { y: 0, opacity: 1 },
    hidden: { y: -20, opacity: 0 }
  },
  transition: {
    y: { type: 'spring', stiffness: 100 }
  }
})`
  position: relative;

  width: 100%;
  max-width: 320px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;

  label {
    font-size: 14px;
  }

  button {
    cursor: pointer;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 26px;
  font-weight: 700;
  color: #293e52;

  margin-bottom: 5px;

  svg {
    margin-right: 10px;
    font-size: 28px;
  }
`

export const Message = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #293e52;
  margin-bottom: 10px;
`
