import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  Container,
  Overlay,
  Modal,
  Title,
  Button
} from '@/styles/components/SearchFilters'
import { FiX as CancelIcon } from 'react-icons/fi'

import SuggestionInput from './SuggestionInput'

import useForm from '@/contexts/hooks/use-form'
import { Flex, IconButton, Button as ButtonChakra, Box } from '@chakra-ui/react'

const SearchCityBoxFilter = ({ onReset }) => {
  const {
    city,
    setCity,
    courses,
    setSearchCourse,
    setSearchCity,
    searchCity,
    cities,
    courseId
  } = useForm()

  const [showModal, setShowModal] = useState(false)
  const [temporarySelection, setTemporarySelection] = useState(null)

  useEffect(() => {
    return () => {
      setTemporarySelection(null)
    }
  }, [])

  return (
    <Container>
      <Box>
        {city && city !== 'null' ? (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gridGap="12px"
          >
            {city}

            <IconButton
              aria-label="cancel clear city filter"
              variant="ghost"
              onClick={e => {
                e.preventDefault()

                setCity(null)
              }}
              icon={<CancelIcon color="tomato" style={{ fontSize: 18 }} />}
            />
          </Flex>
        ) : (
          <ButtonChakra
            fontWeight={500}
            color={'#23A0E9'}
            variant="unstyled"
            onClick={() => {
              setShowModal(true)
            }}
          >
            Selecionar cidade
          </ButtonChakra>
        )}
      </Box>

      <AnimatePresence>
        {showModal && (
          <Overlay>
            <Modal>
              <Title>
                {courseId
                  ? `Filtre por uma cidade onde deseja cursar ${
                      courses.find(el => el.id === Number(courseId)).name
                    }`
                  : 'Você precisa selecionar um curso antes de filtrar a cidade'}
              </Title>

              {courseId && (
                <SuggestionInput
                  label="Digite a cidade:"
                  data={cities
                    .filter(el =>
                      el.city
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                        .includes(
                          searchCity
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                        )
                    )
                    .sort((a, b) => {
                      if (a.name < b.name) return -1
                      if (a.name > b.name) return 1
                      return 0
                    })}
                  onSelect={option => {
                    setTemporarySelection(option.city)
                  }}
                  readyToSearch={setSearchCity}
                  nameToPrint="city"
                  reset={courseId === null}
                  showOnFocus={true}
                />
              )}
              <div style={{ marginTop: 25 }}>
                <Button
                  onClick={() => {
                    setCity(temporarySelection)

                    setSearchCity('')
                    setShowModal(false)

                    if (onReset) {
                      onReset()
                    }
                  }}
                >
                  Pronto
                </Button>
                <Button
                  close
                  onClick={() => {
                    setShowModal(false)
                    setSearchCourse('')
                  }}
                >
                  Fechar
                </Button>
              </div>
            </Modal>
          </Overlay>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default SearchCityBoxFilter
