import { Container, ChakraProvider } from '@chakra-ui/react'

import Routes from '@/routes'
import { ScrollToTop } from './components/Elements'
import theme from '@/styles/theme'

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Container padding="unset" paddingInlineEnd="unset" maxWidth="none">
        <ScrollToTop />

        <Routes />
      </Container>
    </ChakraProvider>
  )
}

export default App
