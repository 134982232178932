import {
  Bg,
  Container,
  Grid,
  Box,
  Render,
  RenderImage
} from '@/styles/sections/Hero'

import HeroContent from '@/components/Elements/heroContent.component'

function Hero() {
  return (
    <Bg>
      <Container>
        <Grid>
          <Box>
            <h1>
              Ganhe até <b>100%</b> de desconto na <b>faculdade</b>!
            </h1>
            <h2>
              Faça uma prova online e gratuita, se ficar entre as melhores
              notas, a bolsa de estudos é sua.
              <br />
            </h2>

            <HeroContent />
            {/* <h5
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '12px'
              }}
            >
              {readyToSubmit === true
                ? 'Ao me inscrever, concordo com'
                : 'Leia '}{' '}
              o{' '}
              <a
                href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
                rel="noreferrer"
                target="_blank"
                style={{ color: 'var(--tertiary)' }}
                download
              >
                regulamento
              </a>{' '}
              do Vale Bolsa Beduka
            </h5> */}
          </Box>
          <Render>
            <RenderImage />
          </Render>
        </Grid>
      </Container>
    </Bg>
  )
}

export default Hero
