import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import SyncLoader from 'react-spinners/SyncLoader'
import InputMask from 'react-input-mask'
import {
  Box,
  ScaleFade,
  Spinner,
  Center,
  useToast,
  Text,
  Link
} from '@chakra-ui/react'
import { Textbox } from 'react-inputs-validation'

import { Container } from '@/styles/components/EmailForm'

import {
  ButtonHighlightAction,
  Loading,
  Divider,
  InputEmail
} from '@/components/Elements'
import AlreadyModal from '@/components/Elements/AlreadyModal'
import useForm from '@/contexts/hooks/use-form'
import { useGlobalStateStore } from '@/store/GlobalState'

import { dateFormatter } from '@/utils/dateUtils'
import { ValueOf } from '@/protocols/CommonProtocol'
import AuthService from '@/services/AuthService'
import colors from '@/styles/colors'
import ApiService from '@/services/ApiService'

import { isValidPhoneNumber } from '@/utils/phone.util'
import { objIsNullish } from '@/utils/object.util'
import { formatEmail } from '@/utils/email.util'

interface IFormData {
  name: string
  email: string
  phone: string
}

const HeroContent = () => {
  const history = useHistory()
  const location = useLocation()
  const toast = useToast()

  const { loading: formLoading, connectionError } = useForm() as {
    loading: boolean
    connectionError: unknown
  }
  const { campaign: campaignData } = useGlobalStateStore()

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    email: '',
    phone: ''
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [alreadyExists, setAlreadyExists] = useState<unknown>(null)

  const [error, setError] = useState<{ [error: string]: string } | null>(null)

  const handleFormDataChange = (
    type: keyof IFormData,
    value: ValueOf<IFormData>
  ) => {
    setFormData(currentState => ({
      ...currentState,
      [type]: value
    }))
  }
  const handlePhoneChange = (phone: string) => {
    const isValid = isValidPhoneNumber(phone)

    if (!isValid) {
      setError(currentState => ({
        ...currentState,
        phone: 'Digite um celular válido'
      }))
    } else {
      setError(currentState => {
        const newState = currentState
        newState?.phone && delete newState.phone

        return newState
      })
    }

    handleFormDataChange('phone', phone)
  }
  const checkForm = () => {
    if (!formData.name) {
      toast({
        title: 'Oops..',
        description: 'Insira seu nome',
        status: 'info',
        duration: 5000,
        isClosable: true
      })

      return false
    }

    if (!formData.email) {
      toast({
        title: 'Oops..',
        description: 'Informe seu e-mail',
        status: 'info',
        duration: 5000,
        isClosable: true
      })

      return false
    }

    if (!formData.phone) {
      toast({
        title: 'Oops..',
        description: 'Informe seu celular',
        status: 'info',
        duration: 5000,
        isClosable: true
      })

      return false
    }

    if (formData.phone.includes('_')) {
      toast({
        title: 'Oops..',
        description: 'Insira um celular válido',
        status: 'info',
        duration: 5000,
        isClosable: true
      })

      return false
    }
    if (!objIsNullish(error || {})) {
      toast({
        title: 'Oops..',
        description: 'Verifique seus dados',
        status: 'info',
        duration: 5000,
        isClosable: true
      })

      return false
    }

    return true
  }

  const disclosureSubmit = async () => {
    setLoading(true)

    const isValid = checkForm()

    if (isValid) {
      try {
        await ApiService.post('integration-crm/user-list', formData)

        toast({
          title: 'Muito bem!',
          description:
            'Será avisado por e-mail assim que as inscrições iniciarem.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })

        setFormData({
          email: '',
          name: '',
          phone: ''
        })
      } catch (err) {
        console.log(err)

        toast({
          title: 'Oops..',
          description:
            'Falha ao registrar usuário. Tente novamente mais tarde.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        })
      }
    }

    setLoading(false)
  }

  const subscriptionSubmit = async () => {
    setLoading(true)

    const isValid = checkForm()

    if (isValid) {
      try {
        const { data } = await ApiService.post('user', formData)

        AuthService.login(data.session.token)

        history.push({
          pathname: '/inscricao/etapa/1',
          search: location.search
        })
      } catch (err: any) {
        if (err.response.data) {
          const { errors } = err.response.data
          const { message } = errors[0]

          toast({
            title: 'Oops..',
            description: message,
            status: 'warning',
            duration: 5000,
            isClosable: true
          })
        } else {
          toast({
            title: 'Epa..',
            description: 'Erro desconhecido... tente novamente mais tarde',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        }
      }
    }

    setLoading(false)
  }

  const handleErrorChange = (
    type: 'name' | 'email' | 'phone',
    message: string
  ) => {
    setError(currentState => ({
      ...currentState,
      [type]: message
    }))
  }

  const DisclosureContent = () => {
    const subscriptionDate = dateFormatter(
      campaignData.subscription_initial_date
    )
    return (
      <Container>
        <h2>
          <b>
            As inscrições serão abertas no dia {subscriptionDate?.day} de{' '}
            {subscriptionDate?.month?.long}
          </b>
        </h2>

        <div className="name">
          <Textbox
            value={formData.name}
            onChange={value => handleFormDataChange('name', value)}
            disabled={loading}
            attributesInput={{
              id: 'name-form',
              placeholder: 'Nome completo'
            }}
            validationOption={{
              name: 'Seu nome',
              check: true,
              required: true
            }}
            validationCallback={() => handleErrorChange('name', 'Erro no nome')}
          />
        </div>
        <div className="grid">
          <InputEmail
            isLoading={loading}
            initialValue={formData.email}
            onChange={value =>
              handleFormDataChange('email', formatEmail(value as string))
            }
            onError={message => handleErrorChange('email', message as string)}
          />

          {error?.email && (
            <Box m="5px 10px" w="100%">
              <Text fontSize="14px" fontWeight="500" color="red.400">
                {error.email}
              </Text>
            </Box>
          )}

          <Divider size={2} orientation="horizontal" />

          <InputMask
            placeholder="Celular"
            value={formData.phone}
            mask="(99) 99999-9999"
            readOnly={loading}
            autoComplete="off"
            onChange={({ target }) => handlePhoneChange(target.value)}
          />

          {error?.phone && (
            <Box m="5px 10px 0px" w="100%">
              <Text fontSize="14px" fontWeight="500" color="red.400">
                {error.phone}
              </Text>
            </Box>
          )}
        </div>

        <div className="subscription-button-container">
          <motion.button
            className={`${loading ? 'loading' : ''}`}
            disabled={
              loading || Boolean(error?.phone || error?.name || error?.email)
            }
            onClick={disclosureSubmit}
          >
            {loading ? (
              <SyncLoader
                color="rgba(0, 0, 0, 0.4)"
                loading={loading}
                size={10}
              />
            ) : (
              'DESEJO SER AVISADO'
            )}
          </motion.button>
        </div>
      </Container>
    )
  }

  const SubscriptionContent = () => {
    return (
      <Container>
        <div className="name">
          <Textbox
            value={formData.name}
            onChange={value => handleFormDataChange('name', value)}
            disabled={loading}
            attributesInput={{
              id: 'name-form',
              placeholder: 'Nome completo'
            }}
            validationOption={{
              name: 'Seu nome',
              check: true,
              required: true
            }}
            validationCallback={() => handleErrorChange('name', 'Erro no nome')}
          />
        </div>
        <div className="grid">
          <InputEmail
            initialValue={formData.email}
            onChange={value =>
              handleFormDataChange('email', formatEmail(value as string))
            }
            isLoading={loading}
            onError={message => handleErrorChange('email', message as string)}
          />

          <Divider size={2} orientation="horizontal" />

          <InputMask
            placeholder="Celular"
            value={formData.phone}
            mask="(99) 99999-9999"
            readOnly={loading}
            autoComplete="off"
            onChange={({ target }) => handlePhoneChange(target.value)}
          />
          {error?.phone && (
            <Box m="5px 10px 0px" w="100%">
              <Text fontSize="14px" fontWeight="500" color="red.400">
                {error.phone}
              </Text>
            </Box>
          )}

          {!error?.phone && formData.phone && (
            <Box m="5px 10px 0px" w="100%">
              <Text fontSize="14px" fontWeight="500" color="gray.500">
                Será enviado um SMS para validar o celular
              </Text>
            </Box>
          )}
        </div>

        <div className="subscription-button-container">
          <motion.button
            className={`${loading ? 'loading' : ''}`}
            disabled={
              loading || Boolean(error?.phone || error?.name || error?.email)
            }
            onClick={subscriptionSubmit}
          >
            {loading ? (
              <SyncLoader
                color="rgba(0, 0, 0, 0.4)"
                loading={loading}
                size={10}
              />
            ) : (
              'Faça sua inscrição agora'
            )}
          </motion.button>
        </div>
      </Container>
    )
  }

  const ExamWarningContent = () => (
    <Box borderRadius="5px" bg="blue.100" w="fit-content" p={5}>
      <Text color="#000" fontWeight="bold" textStyle="h4">
        Fim do período de inscrição
      </Text>

      <Divider size={2} orientation="horizontal" />

      <Text
        color="#444242"
        fontWeight={600}
        dangerouslySetInnerHTML={{
          __html: `<center>Fique atento na data do exame <br/><br/> ${campaignData.exam_date_instructions}</center>`
        }}
      ></Text>
    </Box>
  )

  const ExamContent = () => (
    <>
      <Box borderRadius="5px" bg="#dbf2ff" w="fit-content" p={5}>
        <Text color={colors.palette.primary} fontWeight="bold" textStyle="h4">
          Tutorial de acesso a prova
        </Text>

        <Divider size={2} orientation="horizontal" />
        <Link
          color={colors.palette.pink}
          fontWeight={500}
          href="https://bit.ly/3udhfU6"
          isExternal
        >
          Clique aqui para acessar o tutorial
        </Link>

        <Divider size={3} orientation="horizontal" />

        <Text color={colors.palette.primary} fontWeight="bold" textStyle="h4">
          Prova de conhecimento disponível
        </Text>
        <Divider size={2} orientation="horizontal" />
        <Link
          color={colors.palette.pink}
          fontWeight={500}
          href="http://bit.ly/37IHjuz"
          isExternal
        >
          Clique aqui para iniciar
        </Link>
      </Box>
    </>
  )

  const ReleaseWarningContent = () => {
    const resultReleaseDate = dateFormatter(
      campaignData.result_release_initial_date
    )
    return (
      <Box borderRadius="5px" bg="#dbf2ff" w="fit-content" p={5}>
        <Text color="#000" fontWeight="bold" textStyle="h4">
          Estamos analisando as provas
        </Text>

        <Divider size={2} orientation="horizontal" />

        <Text color="#000" fontWeight={600}>
          O resultado estará disponível no dia {resultReleaseDate?.day} de{' '}
          {resultReleaseDate?.month?.long}
        </Text>
      </Box>
    )
  }

  const ExamResultContent = () => (
    <Box borderRadius="5px" bg="#dbf2ff" w="fit-content" p={5}>
      <Text color="#000" fontWeight="bold" textStyle="h4">
        Resultado disponível
      </Text>

      <Divider size={2} orientation="horizontal" />

      <Text color="#000">Clique abaixo e confira sua colocação</Text>

      <Divider size={2} orientation="horizontal" />

      <ButtonHighlightAction
        onClick={() => document.getElementById('my-subscription-btn')?.click()}
      >
        Minha inscrição
      </ButtonHighlightAction>
    </Box>
  )
  const FinishedContent = () => (
    <Box borderRadius="5px" bg="#dbf2ff" w="100%" p={5}>
      <Text color="#000" fontWeight="bold" textStyle="h4">
        Campanha finalizada
      </Text>
    </Box>
  )

  const defaultContent = () => (
    <Box borderRadius="5px" bg="#dbf2ff" w="100%" p={5}>
      <Text color="#000" fontWeight="bold" textStyle="h4">
        Tente novamente
      </Text>
    </Box>
  )

  const contentOptions = () => {
    const options = {
      disclosure: DisclosureContent(),
      subscription: SubscriptionContent(),
      exam_warning: ExamWarningContent(),
      exam: ExamContent(),
      release_warning: ReleaseWarningContent(),
      result_release: ExamResultContent(),
      finished: FinishedContent(),
      default: defaultContent()
    }

    return options[campaignData.campaignStatus] || options.default
  }

  return (
    <>
      <ScaleFade in={true}>
        {connectionError && (
          <Box borderRadius="5px" bg="blue.100" w="100%" p={5}>
            <>
              Não foi possível carregar as informações para a inscrição tente
              novamente mais tarde.
            </>
          </Box>
        )}

        <Loading
          loading={formLoading}
          customLoadingElement={
            <Box borderRadius="5px" bg="blue.100" w="100%" p={5}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          }
        >
          {contentOptions()}
        </Loading>
      </ScaleFade>
      <AlreadyModal
        show={!!alreadyExists}
        name={alreadyExists}
        onClose={() => {
          setAlreadyExists(null)
          setLoading(false)
        }}
      />
    </>
  )
}

export default HeroContent
