import styled from 'styled-components'
import media from 'styled-media-query'

export const Content = styled.div`
  padding: 40px 0;

  img {
    width: 140px;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    margin: 10px 0 10px 0;
    color: var(--title-text);
  }

  p {
    font-weight: 300;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;

    div {
      float: right;

      a {
        font-size: 12px;
        text-decoration: none;
        color: var(--primary);
      }
    }
  }

  ${media.lessThan('medium')`
    padding: 40px 20px;

    img {
      width: 110px;
    }

    h5 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
      margin-top: 15px;

      div {
        float: none;
        clear: both;
        margin-top: 20px;

        a {
          font-size: 14px;
        }
      }
    }
  `}
`
