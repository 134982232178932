import { useState } from 'react'
import { Box, Flex, Text, Link as LinkChakra, useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { MdMood as SmileIcon, MdMoodBad as BadIcon } from 'react-icons/md'

import { ButtonHighlightAction, Divider } from '@/components/Elements'
import colors from '@/styles/colors'
import { useGlobalStateStore } from '@/store/GlobalState'
import Messages from '@/utils/errorMessages/messages'
import { formattedDateToNumericPT } from '@/utils/dateUtils'
import ApiService from '@/services/ApiService'
import sanitizationUtils from '@/utils/sanitization.utils'
const OrderStatus = () => {
  const [loadingGenerateEnrollmentLetter, setLoadingGenerateEnrollmentLetter] =
    useState<boolean>(false)

  const toast = useToast()
  const { order: orderData } = useGlobalStateStore()

  const handleGenerateEnrollmentLetter = async () => {
    setLoadingGenerateEnrollmentLetter(true)

    try {
      const { data } = await ApiService.put(
        '/enrollment-letter/retrieve-or-create'
      )

      window.open(data.enrollment_letter_url)
    } catch (error) {
      toast({
        title: 'Tente novamente',
        description: 'Tivemos um problema ao gerar a carta de pré matrícula.',
        status: 'error'
      })
    }

    setLoadingGenerateEnrollmentLetter(false)
  }

  const getTitle = () => {
    const titleOptions = {
      PAID: 'Sucesso! Seu pedido foi pago.',
      WAITING: 'Pronto, agora falta pouco!',
      NOT_PAID: 'Seu pagamento não foi aprovado...',
      CREATED: 'Pagamento pendente...',
      REVERTED: 'Pagamento estornado.'
    }

    return titleOptions[orderData?.status] || titleOptions.CREATED
  }

  const getResult = () => {
    const descriptionOptions = {
      paid: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <SmileIcon fontSize="52px" color={colors.palette.confirm} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.confirm} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={4} orientation="horizontal" />

          <Box width="100%">
            <Text
              textStyle="body1"
              color={colors.palette.primary}
              sx={{
                b: {
                  fontWeight: 500
                }
              }}
            >
              <b>
                Seu pagamento foi aprovado, clique no botão abaixo para gerar a
                carta de pré-matrícula, ou acesse o painel de controle para mais
                informações sobre a bolsa.
              </b>
              <br />
              <br />
              Observação: É necessário apresentar a carta de pré-matrícula na
              instituição ou anexar no processo de matricula on-line para
              garantir sua bolsa.
            </Text>
            <Divider size={4} orientation="horizontal" />

            <Flex
              textAlign="start"
              flexDirection="column"
              justifyContent="center"
            >
              <ButtonHighlightAction
                maxWidth="60%"
                whiteSpace="pre-wrap"
                onClick={handleGenerateEnrollmentLetter}
                variant="solid"
                disabled={loadingGenerateEnrollmentLetter}
                isLoading={loadingGenerateEnrollmentLetter}
              >
                Gerar carta de pré-matrícula
              </ButtonHighlightAction>
            </Flex>
          </Box>
        </>
      ),
      creditCardWaiting: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <SmileIcon fontSize="52px" color={colors.palette.warning} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.warning} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={4} orientation="horizontal" />

          <Box width="100%">
            <Text
              textStyle="body1"
              color={colors.palette.primary}
              sx={{
                b: {
                  fontWeight: 500
                }
              }}
            >
              <b>
                Estamos verificando o pagamento.
                <br /> Logo após a verificação você receberá um e-mail com a
                carta carta de pré-matrícula. Você também poderá baixa-lá pelo
                painel.
              </b>
              <br />
              <br />
              Depois é só imprimir a sua carta de pré-matrícula e entregar na
              faculdade, ou anexar no processo de matricula on-line para
              garantir sua bolsa.
            </Text>
          </Box>
        </>
      ),
      boletoWaiting: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <SmileIcon fontSize="52px" color={colors.palette.warning} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.warning} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={4} orientation="horizontal" />
          <Box width="100%">
            <Text textStyle="body1" color={colors.palette.primary}>
              A ordem do pedido foi gerada, mas, o pagamento ainda não foi
              aprovado. Gere o boleto clicando no botão abaixo, e realize o
              pagamento para disponibilizarmos sua carta de pré-matrícula.
              <br />
              <br />
              <b>
                Atenção: Caso o boleto já tenha pago, favor aguardar. O prazo
                padrão para compensação é de até 4 dias úteis.
              </b>
            </Text>
            <Divider size={3} orientation="horizontal" />
            <LinkChakra
              href={orderData?.paymentTransaction?.payment_data?.boleto?.link}
              target="_blank"
              isExternal
            >
              <ButtonHighlightAction isFullWidth maxWidth="60%" as="a">
                Gerar boleto
              </ButtonHighlightAction>
            </LinkChakra>
          </Box>
        </>
      ),
      creditCardNotPaid: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <BadIcon fontSize="52px" color={colors.palette.error} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.error} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>

          {orderData.extra_data?.cancellationDetails?.code && (
            <>
              <Divider size={3} orientation="horizontal" />

              <Text
                textStyle="body1"
                color={colors.palette.primary}
                fontWeight={500}
              >
                {Messages(orderData.extra_data.cancellationDetails.code)}
              </Text>
            </>
          )}

          <Divider size={3} orientation="horizontal" />

          <Box width="100%">
            <Text
              textStyle="body1"
              color={colors.palette.primary}
              fontWeight={600}
            >
              Será necessário executar um novo pagamento.
            </Text>
            <Divider size={3} orientation="horizontal" />

            <Link to={'/pagamento'}>
              <ButtonHighlightAction maxWidth="60%" as="a">
                Realizar pagamento
              </ButtonHighlightAction>
            </Link>
          </Box>
        </>
      ),
      boletoNotPaid: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <BadIcon fontSize="52px" color={colors.palette.error} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.error} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={3} orientation="horizontal" />

          <Text
            textStyle="body1"
            color={colors.palette.primary}
            fontWeight={500}
          >
            O boleto não foi compensado.
          </Text>

          <Divider size={3} orientation="horizontal" />

          <Box width="100%">
            <Text
              textStyle="body1"
              color={colors.palette.primary}
              fontWeight={600}
            >
              Será necessário executar um novo pagamento.
            </Text>
            <Divider size={3} orientation="horizontal" />

            <Link to={'/pagamento'}>
              <ButtonHighlightAction isFullWidth maxWidth="60%" as="a">
                Realizar pagamento
              </ButtonHighlightAction>
            </Link>
          </Box>
        </>
      ),
      created: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <SmileIcon fontSize="52px" color={colors.palette.warning} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.warning} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={4} orientation="horizontal" />

          <Box width="100%">
            <Text
              textStyle="body1"
              color={colors.palette.primary}
              sx={{
                b: {
                  color: colors.palette.lilac
                },
                strong: {
                  fontWeight: 500
                }
              }}
            >
              Para concluir o processo e garantir a sua{' '}
              <b>
                bolsa de {orderData.examResult?.scholarship} até o final do
                curso
              </b>
              , você precisa pagar a taxa de Pré-Matrícula do Beduka.
              <br />
              <br />
              <strong>
                A taxa é única. Paga apenas uma vez e sem renovações semestrais!
              </strong>
            </Text>
            <Divider size={3} orientation="horizontal" />

            <Flex
              textAlign="start"
              flexDirection="column"
              justifyContent="center"
            >
              <Link to={'/pagamento'}>
                <ButtonHighlightAction isFullWidth maxWidth="70%" as="a">
                  Realizar pagamento
                </ButtonHighlightAction>
              </Link>
            </Flex>
          </Box>
        </>
      ),
      reverted: (
        <>
          <Box width="100%">
            <Flex alignItems="center">
              <Box>
                <SmileIcon fontSize="52px" color={colors.palette.pink} />
              </Box>

              <Divider size={1.5} orientation="vertical" />

              <Box>
                <Text color={colors.palette.pink} textStyle="h5">
                  {getTitle()}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Divider size={4} orientation="horizontal" />

          <Box width="100%">
            <Text textStyle="body1" color={colors.palette.primary} sx={{}}>
              <b>
                O valor de{' '}
                {sanitizationUtils.formatCurrencyToBRL(orderData.total_price)}{' '}
                foi foi estornado no dia{' '}
                {formattedDateToNumericPT(orderData.updated_at)}.
              </b>
              <br />
              <br />A carta de pré matrícula foi desativada. Caso queira
              ativa-la novamente, clique abaixo para a escolher um novo meio de
              pagamento.
            </Text>
            <Divider size={3} orientation="horizontal" />

            <Flex
              textAlign="start"
              flexDirection="column"
              justifyContent="center"
            >
              <Link to={'/pagamento'}>
                <ButtonHighlightAction isFullWidth maxWidth="60%" as="a">
                  Realizar pagamento
                </ButtonHighlightAction>
              </Link>
            </Flex>
          </Box>
        </>
      )
    }

    if (orderData?.status === 'PAID') {
      return descriptionOptions.paid
    }

    if (orderData?.status === 'WAITING') {
      if (orderData?.paymentTransaction?.payment_method === 'CREDIT_CARD') {
        return descriptionOptions.creditCardWaiting
      }

      if (orderData?.paymentTransaction?.payment_method === 'BOLETO') {
        return descriptionOptions.boletoWaiting
      }
    }

    if (orderData?.status === 'NOT_PAID') {
      if (orderData?.paymentTransaction?.payment_method === 'CREDIT_CARD') {
        return descriptionOptions.creditCardNotPaid
      }

      if (orderData?.paymentTransaction?.payment_method === 'BOLETO') {
        return descriptionOptions.boletoNotPaid
      }
    }

    if (orderData?.status === 'CREATED') {
      return descriptionOptions.created
    }

    if (orderData?.status === 'REVERTED') {
      return descriptionOptions.reverted
    }
  }

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      margin="0 auto"
    >
      {getResult()}
    </Flex>
  )
}

export default OrderStatus
