import StorageService from '@/services/StorageService'
import ErrorHandlerService from '@/services/ErrorHandlerService'
import UserService from '@/services/UserService'

class AuthService {
  private get authTokenKey(): string {
    return 'x-vb-user-auth-token'
  }

  get authToken(): string | null {
    const authToken = StorageService.localGet<string>(this.authTokenKey)

    return authToken
  }

  login(authToken: string, callbackHref?: string): void {
    StorageService.localSet(this.authTokenKey, authToken)

    if (callbackHref) {
      window.location.replace(callbackHref)
    }
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const isLoggedIn = await UserService.isLoggedIn()

      !isLoggedIn && this.logout()

      return isLoggedIn
    } catch (error) {
      ErrorHandlerService.handle(error)
      return false
    }
  }

  logout(callbackHref?: string) {
    StorageService.localDelete(this.authTokenKey)

    if (callbackHref) {
      window.location.replace(callbackHref)
    }
  }
}

export default new AuthService()
