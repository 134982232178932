import { useState, useEffect } from 'react'
import {
  Button,
  Flex,
  Text,
  HStack,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Box
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'
import PacmanLoader from 'react-spinners/PacmanLoader'
import ReactPaginate from 'react-paginate'
import dayjs from 'dayjs'
import useForm from '@/contexts/hooks/use-form'
import { CgArrowsExchange } from 'react-icons/cg'

import SearchFilters from '@/components/Elements/SearchFilters'
import ScholarshipCard from '@/components/Elements/ScholarshipCard'
import { Divider, SearchCityBoxFilter } from '@/components/Elements'

import ApiService from '@/services/ApiService'

import { IMetaResponse } from '@/protocols/CommonProtocol'
import { IScholarshipData } from '@/protocols/User.protocol'

import { Row } from '@/styles/pages/Subscription'
import colors from '@/styles/colors'

type IModalityFilter =
  | 'ead_modality'
  | 'semiPresential_modality'
  | 'presential_modality'
  | 'all_modality'

type IShiftFilter =
  | 'morning_shift'
  | 'afternoon_shift'
  | 'night_shift'
  | 'ead_shift'

const ChangeCourse = (props: {
  data: IScholarshipData
  dataLoading: boolean
  onUpdate: () => void
  needLoading: () => void
}) => {
  const { data, dataLoading, onUpdate, needLoading } = props

  const [page, setPage] = useState<number>(1)
  const [scholarships, setScholarships] = useState<{
    meta?: IMetaResponse | null
    data: IScholarshipData[]
  }>({
    data: []
  })
  const [modalityFilter, setModalityFilter] = useState<IModalityFilter | null>(
    null
  )
  const [shiftFilter, setShiftFilter] = useState<IShiftFilter | null>(null)
  const [loading, setLoading] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  })
  const toast = useToast()
  const { city, courseId, setCourseId, setCity }: any = useForm()

  const onModalityFilterChange = (data: IModalityFilter) => {
    setModalityFilter(data)
  }

  const onShiftFilterChange = (data: IShiftFilter) => {
    setShiftFilter(data)
  }
  const getScholarships = async (page: number) => {
    setLoading(true)
    setScholarships({
      meta: null,
      data: []
    })

    const params = {
      courseId: courseId ? (courseId !== '' ? courseId : undefined) : undefined,
      city: city,
      page: page || 1,
      modality: modalityFilter,
      shift: shiftFilter
    }

    const result = await ApiService.get('campaign/scholarships', {
      params
    }).catch(console.log)

    if (result) {
      setScholarships(result.data)
    }

    setLoading(false)
  }

  const change = async (scholarshipId: number) => {
    needLoading()

    try {
      await ApiService.patch('subscription/update', { scholarshipId })
      await onUpdate()
    } catch {
      toast({
        title: 'Oopss...',
        description:
          'Ocorreu um erro ao tentar mudar seu curso, tente novamente mais tarde',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  useEffect(() => {
    if (isOpen) {
      getScholarships(page)
    }
  }, [page, courseId, city, modalityFilter, shiftFilter, isOpen])

  useEffect(() => {
    if (!dataLoading) {
      if (data?.course?.id) setCourseId(data?.course.id)

      setCity(data?.camp.city)
    }
  }, [data, dataLoading])
  return (
    <>
      <Flex
        flexDir={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="flex-start"
        mt={8}
      >
        <Button
          leftIcon={<CgArrowsExchange />}
          size="md"
          cursor="pointer"
          mr={[0, 0, 5]}
          mb={['16px', '16px', 0]}
          bg={colors.palette.primary}
          color="white"
          onClick={onOpen}
          _hover={{
            bg: 'rgba(0, 0, 0, 0.4)'
          }}
          _active={{
            bg: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          Trocar curso
        </Button>
        <Text
          color={colors.palette.primary}
          fontSize="14px"
          fontWeight="medium"
          textAlign={['center', 'center', 'left']}
        >
          Você poderá trocar até o dia{' '}
          {dayjs(data?.campaign.enrollment_change_limit)
            .subtract(1, 'day')
            .format('DD/MM/YYYY')}
        </Text>
      </Flex>

      <Modal
        size={isMobileDevice ? 'full' : '6xl'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#fff">
          <ModalHeader>Trocar curso</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="12">
            <Divider size={2} orientation="horizontal" />
            <Flex flexWrap="wrap">
              <Box width={['100%', '100%', '56%']} mb={['12px', '12px', 0]}>
                <SearchFilters onReset={() => setPage(1)} />
              </Box>
              <Box width={['100%', '100%', '44%']}>
                {' '}
                <HStack
                  spacing={[0, 4, 4]}
                  flexWrap={['wrap', 'nowrap', 'nowrap']}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Box
                    mt={['12px !important', '4px', '4px']}
                    width={['100%', '30%', 'auto']}
                  >
                    <Text fontSize="12px" color="gray.600">
                      Modalidade
                    </Text>
                    <Select
                      onChange={({ target }) =>
                        onModalityFilterChange(target.value as IModalityFilter)
                      }
                      value={modalityFilter || ''}
                    >
                      <option disabled selected value="">
                        Modalidade
                      </option>
                      <option value="all_modality">Todas</option>
                      <option value="presential_modality">Presencial</option>
                      <option value="semiPresential_modality">
                        Semipresencial
                      </option>
                      <option value="ead_modality">Ead</option>
                    </Select>
                  </Box>

                  <Box
                    mt={['12px !important', '4px', '4px']}
                    width={['100%', '30%', 'auto']}
                  >
                    <Text fontSize="12px" color="gray.600">
                      Turno
                    </Text>
                    <Select
                      onChange={({ target }) =>
                        onShiftFilterChange(target.value as IShiftFilter)
                      }
                      value={shiftFilter || ''}
                    >
                      <option disabled selected value="">
                        Turno
                      </option>
                      <option value="all_shift">Todos</option>
                      <option value="morning_shift">Manhã</option>
                      <option value="afternoon_shift">Tarde</option>
                      <option value="night_shift">Noite</option>
                      <option value="ead_shift">Ead</option>
                    </Select>
                  </Box>

                  <Box
                    mt={['12px !important', '4px', '4px']}
                    width={['100%', '40%', 'auto']}
                  >
                    <Text fontSize="12px" color="gray.600">
                      Cidade
                    </Text>
                    <Box paddingInlineStart="1rem">
                      <SearchCityBoxFilter onReset={() => setPage(1)} />
                    </Box>
                  </Box>
                </HStack>
              </Box>
            </Flex>

            {loading && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{
                  delay: 0.8
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 60
                }}
              >
                <PacmanLoader color="var(--primary)" size={30} />
                <h4 style={{ marginTop: 40, textAlign: 'center' }}>
                  Estamos buscando as bolsas
                  <br />
                  disponíveis, por favor, aguarde...
                </h4>
              </motion.div>
            )}
            <Row>
              {!loading &&
                (scholarships.data.length ? (
                  scholarships.data?.map((el, idx) => (
                    <ScholarshipCard
                      key={idx}
                      data={el}
                      type="change"
                      onSelect={() => {
                        onClose()
                        needLoading()

                        change(el.id)
                      }}
                    />
                  ))
                ) : (
                  <Box width="100%" mt="32px">
                    <Text
                      fontWeight={600}
                      color={colors.palette.primary}
                      textAlign="center"
                    >
                      Não encontramos resultados para essa busca
                    </Text>
                  </Box>
                ))}
            </Row>
            {!isMobileDevice && (
              <>
                <br />
                <br />
              </>
            )}

            {!loading && scholarships && (
              <ReactPaginate
                containerClassName={'pagination'}
                // subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                pageCount={scholarships.meta?.last_page as number}
                forcePage={page - 1}
                pageRangeDisplayed={isMobileDevice ? 2 : 5}
                marginPagesDisplayed={1}
                previousLabel={isMobileDevice ? '<-' : 'Página anterior'}
                nextLabel={isMobileDevice ? '->' : 'Próxima página'}
                onPageChange={({ selected }) => {
                  window.scrollTo(0, 0)
                  setPage(() => Number(selected) + 1)
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ChangeCourse
