import styled, { createGlobalStyle } from 'styled-components'
import media from 'styled-media-query'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    outline: none;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    max-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    background: #ffffff !important;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  article > * + * {
    margin-top: 1em;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &:root {
    --button-action: #EE3DB6;
    --primary: #9B36DD;
    --secondary: #EA6B34;
    --tertiary: #23A0E9;
    --title-text: #0B1880;
    --muted-text: #85868A;
    --dark-color: rgb(35, 39, 42);
  }

  .pagination {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 15px;

    margin: 0;
    padding: 0;

    margin-top: 30px;

    li {
      a {
        padding: 10px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: all ease .3s;

        &:hover {
          background: rgba(35, 160, 233, 0.3);
        }
      }

      &.active {
        a {
          background-color: rgb(35, 160, 233);
          color: #fff;
        }
      }

      &.disabled {
        a {
          background: rgb(0 0 0 / 5%);
          color: #989898;
          cursor: not-allowed
        }
      }
    }


  }

  .custom-scrollbar::-webkit-scrollbar {
  width: 9px !important;
  }

.custom-scrollbar::-webkit-scrollbar-thumb {
background-color: #b9bfc6;
border-radius: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
border-radius: 7px;
background: #ffffff;
margin: 8px 0;
}


  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    background: #EE3DB6;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 12px;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.carousel-btn-next {
      right: -45px;
    }

    &.carousel-btn-back {
      left: -45px;
    }

    ${media.lessThan('medium')`
      /* top: 343px;
      transform: none!important;

       &.carousel-btn-next {
        right: 15px;
      }

      &.carousel-btn-back {
        left: 180px;
      } */
      display: none;
    `}
  }

  .carousel__dot-group {
    position: absolute;

    top: 330px;
    left: 50%;
    transform: translateX(-50%);

    padding: 10px 0px;

    button {
      width: 15px;
      height: 10px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 6px;
      transition: all ease .3s;

      &.carousel__dot--selected {
        width: 35px;
        background: rgb(35, 160, 233);
      }
    }
  }
`

export const Container = styled.div`
  position: relative;

  width: 100%;
  max-width: 1210px;

  margin: 0 auto !important;
`
