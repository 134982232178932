import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button, Input, useToast } from '@chakra-ui/react'
import InputMask from 'react-input-mask'

import {
  Overlay,
  Container,
  Title,
  Message
} from '@/styles/components/TemporaryModal'

import AuthService from '@/services/AuthService'
import ApiService from '@/services/ApiService'
import { ButtonHighlightAction } from '.'

function TemporaryModal({ show, onClose }) {
  const toast = useToast()

  const [mode, setMode] = useState('LOGIN')

  const [cpf, setCPF] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  async function login() {
    setLoading(true)

    try {
      const { data } = await ApiService.post('user/session', { cpf, password })

      AuthService.login(data.token, '/minha-conta')
    } catch (err) {
      toast({
        title: 'Oops... ocorreu um problema',
        description: err.response.data
          ? err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  async function recover() {
    setLoading(true)

    try {
      await ApiService.post('user/recover', { cpf })

      toast({
        title: 'Pronto!',
        description: 'Enviamos uma nova senha para o seu e-mail cadastrado',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch {
      toast({
        title: 'Oops... ocorreu um problema',
        description: 'Este CPF não está cadastrado',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  return (
    <AnimatePresence>
      {show && (
        <Overlay>
          <Container>
            <Title>
              {mode === 'LOGIN' ? 'Faça login' : 'Recupere sua senha'}
            </Title>
            <Message>
              {mode === 'LOGIN'
                ? 'Insira seu CPF e senha enviado no e-mail de confirmação da sua inscrição'
                : 'Para recuperar sua senha, informe seu CPF utilizado na inscrição abaixo.'}
            </Message>
            <label>CPF</label>
            <Input
              size="md"
              as={InputMask}
              placeholder="Informe seu CPF"
              mask="999.999.999-99"
              value={cpf}
              onChange={e => setCPF(e.target.value)}
              style={{ marginBottom: 15 }}
              background="gray.100"
              _placeholder={{ color: 'gray.500' }}
            />
            {mode === 'LOGIN' && (
              <>
                <label>Senha</label>
                <Input
                  size="md"
                  value={password}
                  placeholder="Informe sua senha"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  style={{ marginBottom: 5 }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      login()
                    }
                  }}
                  background="gray.100"
                  _placeholder={{ color: 'gray.500' }}
                />
                <br />
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()

                    setMode('RECOVER')
                  }}
                  style={{
                    textDecoration: 'none',
                    fontSize: 14,
                    color: 'var(--tertiary)'
                  }}
                >
                  Esqueci minha senha
                </a>
              </>
            )}
            <br />
            <br />
            <ButtonHighlightAction
              size="sm"
              mr={2}
              isLoading={loading}
              onClick={() => {
                if (mode === 'LOGIN') {
                  login()
                } else {
                  recover()
                }
              }}
            >
              {mode === 'LOGIN' ? 'Entrar na conta' : 'Recuperar senha'}
            </ButtonHighlightAction>
            {mode === 'LOGIN' ? (
              <Button size="md" onClick={onClose}>
                Cancelar
              </Button>
            ) : (
              <Button
                size="md"
                onClick={() => {
                  setMode('LOGIN')
                }}
              >
                Voltar
              </Button>
            )}
          </Container>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default TemporaryModal
