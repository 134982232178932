import ApiService from '@/services/ApiService'
import ErrorHandlerService from '@/services/ErrorHandlerService'

import { getStatusCode } from '@/utils/responseUtil'
import { IUserData } from '@/protocols/User.protocol'
import { PaymentOrder } from '@/protocols/PaymentOrder.protocol'

type IGetInfo = {
  userData: IUserData
  orderData: PaymentOrder | null
}

class UserService {
  async getInfo(): Promise<IGetInfo | null> {
    try {
      const { data } = await ApiService.get('user')

      return data
    } catch (error) {
      ErrorHandlerService.handle(error)
      return null
    }
  }

  async isLoggedIn(): Promise<boolean> {
    let statusCode: number

    try {
      const response = await ApiService.get('/user')

      if (!response.data.userData?.profile?.cpf) {
        return false
      }

      statusCode = response.status
    } catch (error) {
      ErrorHandlerService.handle(error)

      statusCode = getStatusCode(error as never) || 500

      return false
    }

    /**
     * User is not logged in if the status code
     * returned by that request is 401 (Unauthorized).
     */
    if (statusCode === 401) {
      return false
    }

    return true
  }
}

export default new UserService()
