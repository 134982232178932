import { Route, Switch } from 'react-router-dom'
import { Container } from '@chakra-ui/layout'

import SubscriptionPage from '@/pages/Subscription'
import ConfirmationPage from '@/pages/Confirmation'
import SuccessPage from '@/pages/Success'
import MyAccountPage from '@/pages/MyAccount'

import ResultPage from '@/pages/Result'
import PaymentPage from '@/pages/Payment'

const Routes = [
  <Route
    key="subscription"
    exact
    path="/inscricao/etapa/:step"
    component={SubscriptionPage}
  />,
  <Route
    key="confirmation"
    exact
    path="/inscricao/confirmacao"
    component={ConfirmationPage}
  />,
  <Route
    key="success"
    exact
    path="/inscricao/concluido"
    component={SuccessPage}
  />,
  <Route
    key="my-account"
    exact
    path="/minha-conta"
    component={MyAccountPage}
  />,
  <Route key="result" exact path="/resultado" component={ResultPage} />,
  <Route key="payment" exact path="/pagamento" component={PaymentPage} />
]

const PrivateRoutes = () => {
  return (
    <Container width="100vw" maxWidth="none" padding="0px">
      <Switch>{Routes}</Switch>
    </Container>
  )
}

export default PrivateRoutes
