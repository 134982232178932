import styled from 'styled-components'
import { motion } from 'framer-motion'
import { AiOutlineWhatsApp } from 'react-icons/ai'

const buttonMotion = {
  rest: {
    color: 'grey',
    x: 0,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn'
    }
  }
}
const Container = styled(motion.div)`
  z-index: 999;
  position: fixed;

  bottom: 20px;
  right: 2%;

  max-width: 200px;
  cursor: pointer;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: #25d366;
    cursor: pointer;

    svg {
      color: #fff;
      font-size: 40px;
    }
  }
`
const WhatsappButton = () => {
  return (
    <Container
      initial="rest"
      animate="rest"
      onClick={() => {
        window.open(
          'https://api.whatsapp.com/send?phone=5581989583016',
          '_blank'
        )
      }}
    >
      <motion.button variants={buttonMotion}>
        <AiOutlineWhatsApp />
      </motion.button>
    </Container>
  )
}
export default WhatsappButton
