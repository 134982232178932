import React, { useState } from 'react'

import {
  Input,
  InputProps,
  FormControl,
  FormHelperText
} from '@chakra-ui/react'

import { emailIsValid, formatEmail } from '@/utils/email.util'

type InputType = InputProps & {
  initialValue?: string
  isLoading?: boolean
  onChange: (email: string | null) => void
  onError?: (msg: string | null) => void
}

const InputEmail: React.FC<InputType> = props => {
  const { initialValue, isLoading, onError, onChange } = props

  const [email, setEmail] = useState<string>(initialValue || '')
  const [error, setError] = useState<string | null>(null)

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail)

    const containSpecialChars = /^[-.@_a-z0-9]+$/gi
    let msgError = null

    if (!newEmail) {
      msgError = 'Informe seu e-mail'
    } else if (!containSpecialChars.test(String(newEmail).normalize('NFD'))) {
      msgError = 'E-mail não pode conter caractéres especiais ou espaços.'
    } else if (!emailIsValid(newEmail)) {
      msgError = 'Informe um e-mail válido.'
    }

    if (msgError) {
      setError(msgError)
      onError && onError(msgError)

      return
    }

    setError(null)
    onError && onError(null)
    onChange(newEmail)
  }

  return (
    <FormControl padding={0}>
      <Input
        {...props}
        isDisabled={isLoading}
        placeholder="E-mail"
        type="email"
        value={email}
        borderColor="gray.300"
        _placeholder={{ color: 'gray.600' }}
        onChange={({ target }) => handleEmailChange(formatEmail(target.value))}
      />

      {error && (
        <FormHelperText
          margin="5px 10px"
          fontSize="14px"
          fontWeight="500"
          color="red.400"
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default InputEmail
