export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  if (!phoneNumber) return false

  const phoneRegexDDD =
    /^((1[1-9])|(2[12478])|(3[1234578])|(4[1-9])|(5[1345])|(6[1-9])|(7[134579])|(8[1-9])|(9[1-9]))9[0-9]{8}$/
  const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '')

  const phoneNumberWithoutDDD = formattedPhoneNumber.slice(2)
  const invalidPhones = [
    '000000000',
    '111111111',
    '222222222',
    '333333333',
    '444444444',
    '555555555',
    '666666666',
    '777777777',
    '888888888',
    '999999999'
  ]
  const invalid = invalidPhones.filter(invalidPhone =>
    formattedPhoneNumber.endsWith(invalidPhone)
  )

  if (invalid.length > 0 || phoneNumberWithoutDDD[0] !== '9') {
    return false
  } else if (!phoneRegexDDD.test(formattedPhoneNumber)) {
    return false
  }

  return true
}
