import { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { BiBook } from 'react-icons/bi'

import {
  Container,
  Overlay,
  Modal,
  Title,
  Card,
  Label,
  Value,
  Button
} from '@/styles/components/SearchFilters'

import SuggestionInput from './SuggestionInput'

import useForm from '@/contexts/hooks/use-form'

const SearchCourseFilter = ({ onReset }) => {
  const {
    city,
    setCity,
    courses,
    setSearchCourse,
    searchCourse,
    setSearchCity,
    setCourseId,
    setCities,
    courseId
  } = useForm()

  const [showModal, setShowModal] = useState(false)
  const [temporarySelection, setTemporarySelection] = useState(null)

  useEffect(() => {
    return () => {
      setTemporarySelection(null)
    }
  }, [])

  return (
    <Container>
      <Card>
        <BiBook />
        <div>
          <Label>Curso</Label>
          <Value>
            {courseId && courseId !== 'null' ? (
              <>
                {courses.find(el => el.id === Number(courseId))
                  ? courses.find(el => el.id === Number(courseId)).name
                  : '...'}
              </>
            ) : (
              <a
                href="#"
                className="btn"
                onClick={e => {
                  e.preventDefault()

                  setShowModal(true)
                }}
              >
                Selecionar curso
              </a>
            )}
          </Value>
        </div>
        {courseId && (
          <button
            className="bnt-open-modal"
            onClick={() => {
              setShowModal(true)
            }}
          >
            Trocar curso
          </button>
        )}
      </Card>
      <AnimatePresence>
        {showModal && (
          <Overlay>
            <Modal>
              <Title>
                {city
                  ? `Filtre por um curso na cidade de ${city}`
                  : 'Filtre por um curso'}
              </Title>
              <SuggestionInput
                label="Digite o curso:"
                data={courses
                  .filter(el =>
                    el.name
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .includes(
                        searchCourse
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      )
                  )
                  .sort((a, b) => {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                    return 0
                  })}
                onSelect={option => {
                  setTemporarySelection(option.id)

                  setCities(option.cities)
                  setCity(null)
                }}
                readyToSearch={setSearchCourse}
                nameToPrint="name"
                reset={courseId === null}
                showOnFocus={true}
              />

              <div style={{ marginTop: 25 }}>
                <Button
                  onClick={() => {
                    setCourseId(temporarySelection)

                    setSearchCourse('')
                    setSearchCity('')
                    setShowModal(false)

                    if (onReset) {
                      onReset()
                    }
                  }}
                >
                  Pronto
                </Button>
                <Button
                  close
                  onClick={() => {
                    setShowModal(false)
                    setSearchCourse('')
                  }}
                >
                  Fechar
                </Button>
              </div>
            </Modal>
          </Overlay>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default SearchCourseFilter
