import React, { useState, useEffect } from 'react'
import { Link as RLink, useParams, useHistory } from 'react-router-dom'
import { Link, scroller } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  useToast,
  useDisclosure
} from '@chakra-ui/react'

import {
  Content,
  Container,
  Logo,
  Navbar,
  CollapseNavbar,
  CollapseBackground
} from '@/styles/components/Menu'

import TemporaryModal from './TemporaryModal'

import AuthService from '@/services/AuthService'
import { useGlobalStateStore } from '@/store/GlobalState'
import ApiService from '@/services/ApiService'
import useDidMount from '@/contexts/hooks/useDidMount'
import useQuery from '@/contexts/hooks/useQuery'

function Menu({ inProcess, scrollTop }) {
  const [scrollPosition, setScrollPosition] = useState(scrollTop || 0)
  const [modalOpen, setModalOpen] = useState(false)
  const [code, setCode] = useState()
  const [data, setData] = useState()
  const [loading, setLoading] = useState()

  const { isOpen: isOpenModal, onClose: onCloseModal } = useDisclosure()
  const token = AuthService.authToken
  const { step } = useParams()

  const history = useHistory()
  const query = useQuery()
  const toast = useToast()
  const { campaign } = useGlobalStateStore()

  const myInscriptionView = ![
    'disclosure',
    'finished',
    'release_warning'
  ].includes(campaign.campaignStatus)
  const signUpView = ['subscription'].includes(campaign.campaignStatus)

  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 816px)'
  })

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  async function submit() {
    setLoading(true)

    try {
      const { data: result } = await ApiService.get(
        `subscription/${code}/verify`
      )

      setData(result)
    } catch (err) {
      toast({
        title: 'Oops... ',
        description: err.response.data
          ? err.response.data.errors[0].message.includes('encontrada')
            ? 'Resultado não encontrado'
            : err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'warning',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    setScrollPosition(scrollTop)
  }, [scrollTop])

  useDidMount(() => {
    if (query?.get('abrir-login')) {
      setModalOpen(true)
    }
  })

  return (
    <Content
      style={{
        top: scrollPosition > 20 ? 0 : isMobileDevice ? 15 : 30,
        padding:
          scrollPosition > 20
            ? isMobileDevice
              ? '15px 2px'
              : '10px 15px'
            : '0 12px',
        backgroundColor: scrollPosition > 20 ? '#fff' : 'transparent'
      }}
    >
      <Container>
        <div>
          <RLink to="/">
            <Logo mobile={isMobileDevice} />
          </RLink>
        </div>

        <Navbar
          isMobile={isMobileDevice}
          style={{ border: scrollPosition > 20 ? 'none' : 'solid 1px #ebebed' }}
        >
          {inProcess ? (
            <ul>
              <li>
                <RLink to="/">Página Inicial</RLink>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="subscription"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Inscrição
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="howwork"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                >
                  Como Funciona
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                >
                  Depoimentos
                </Link>
              </li>
            </ul>
          )}
          {inProcess ? (
            <div>
              {Number(step) > 1 && (
                <button
                  onClick={() => {
                    history.push(`/inscricao/etapa/${Number(step) - 1}`)
                  }}
                >
                  Voltar
                </button>
              )}
            </div>
          ) : myInscriptionView ? (
            <div>
              <button
                id="my-subscription-btn"
                onClick={() => {
                  if (token) {
                    history.push('/minha-conta')
                  } else {
                    setModalOpen(true)
                  }
                }}
              >
                Minha inscrição
              </button>
              {signUpView && (
                <button
                  onClick={() => {
                    scroller.scrollTo('subscription', {
                      spy: true,
                      smooth: true
                    })
                  }}
                >
                  Inscreva-se
                </button>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </Navbar>
        {isMobileDevice && (
          <CollapseNavbar>
            <CollapseBackground>
              {inProcess && Number(step) > 1 && (
                <motion.button
                  className="back-button"
                  onClick={() => {
                    history.push(`/inscricao/etapa/${Number(step) - 1}`)
                  }}
                >
                  Voltar
                </motion.button>
              )}
              {inProcess && Number(step) === 1 && (
                <motion.button
                  className="back-button"
                  onClick={() => {
                    history.push('/')
                  }}
                >
                  Página Inicial
                </motion.button>
              )}
              {!inProcess && myInscriptionView && (
                <motion.button
                  id="my-subscription-btn"
                  onClick={() => {
                    if (token) {
                      history.push('/minha-conta')
                    } else {
                      setModalOpen(true)
                    }
                  }}
                >
                  Minha inscrição
                </motion.button>
              )}
            </CollapseBackground>
          </CollapseNavbar>
        )}
      </Container>
      <TemporaryModal show={modalOpen} onClose={() => setModalOpen(false)} />
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verificar vale bolsa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!data ? (
              <>
                <Text>Informe abaixo o código do vale bolsa</Text>
                <Input value={code} onChange={e => setCode(e.target.value)} />
              </>
            ) : (
              <>
                <Text>Encontramos a carta matrícula do candidato.</Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {!data ? (
              <Button
                colorScheme="blue"
                isLoading={loading}
                disabled={loading}
                onClick={submit}
              >
                Validar
              </Button>
            ) : (
              <>
                <Button
                  cursor="pointer"
                  onClick={() => {
                    setData(null)
                    setCode('')
                  }}
                >
                  Voltar
                </Button>
                <Button
                  ml={3}
                  colorScheme="blue"
                  cursor="pointer"
                  onClick={() => {
                    window.open(data.coupon_url, '_blank')
                  }}
                >
                  Visualizar vale bolsa
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Content>
  )
}

export default Menu
