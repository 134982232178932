import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Text
} from '@chakra-ui/react'
import { Divider } from '@/components/Elements'
import colors from '@/styles/colors'

interface IBoxNumber {
  value: string
  label: string
}

const BoxNumber = (props: IBoxNumber) => {
  const { value, label } = props
  return (
    <>
      <Box width="auto">
        <CircularProgress
          color={colors.palette.primary}
          size="84px"
          max={60}
          value={+value}
          margin="0 auto"
        >
          <CircularProgressLabel fontWeight={400}>
            {value}
          </CircularProgressLabel>
        </CircularProgress>
      </Box>

      <Divider size={0.5} orientation="horizontal" />

      <Box width="100%">
        <Text fontWeight={500} align="center">
          {label}
          {+value > 1 ? 's' : ''}
        </Text>
      </Box>
    </>
  )
}

export default BoxNumber
