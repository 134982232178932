import { IsDevEnv } from '@/config/environment'

class ErrorHandler {
  static handle(error: any): void {
    if (IsDevEnv) {
      console.error('[ERROR]: ', error)
    }
  }
}

export default ErrorHandler
