import {
  Box,
  Heading,
  Grid,
  GridItem,
  Center,
  Image,
  Text,
  Link
} from '@chakra-ui/react'
import { Element } from 'react-scroll'

import colors from '@/styles/colors'

function HowWork() {
  return (
    <Box as={Element} name="howwork" px={['20px', '20px', '0']} bg="white">
      <Box w="100%" maxW="1117px" m="0 auto">
        <Heading
          textAlign="center"
          color="#9B36DD"
          fontWeight={'bold'}
          fontSize={['32px', '32px', '36px']}
          mb={4}
        >
          Como funciona o Vale Bolsa Beduka?
        </Heading>

        <Box
          mb={['40px', '40px', '90px']}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Text
            w={['100%', '100%', '75%']}
            m="0 auto"
            textAlign="center"
            color="gray.500"
            lineHeight="1.6"
            fontSize={['14px', '14px', '18px']}
          >
            Você se inscreve gratuitamente no curso e cidade que deseja, faz a
            Prova de Conhecimento do Beduka e, se for classificado, pode receber
            uma bolsa de até 100% até o final do curso.
          </Text>

          <Link
            color={colors.palette.secondary}
            isExternal
            href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
            textAlign="center"
          >
            Leia o regulamento
          </Link>
        </Box>

        <Grid
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(5, 1fr)'
          ]}
          gap="28px"
        >
          <GridItem>
            <Center>
              <Image
                src="/icons/pencil.png"
                alt="..."
                w={['50px', '50px', '90px']}
                mb={4}
              />
            </Center>
            <Text
              textAlign="center"
              color="#0B1880"
              fontWeight={500}
              fontSize={['16px', '16px', '18px']}
              lineHeight="1.2"
            >
              Apenas uma prova
            </Text>
          </GridItem>

          <GridItem>
            <Center>
              <Image
                src="/icons/notebook.png"
                alt="..."
                w={['50px', '50px', '90px']}
                mb={4}
              />
            </Center>
            <Text
              textAlign="center"
              color="#0B1880"
              fontWeight={500}
              fontSize={['16px', '16px', '18px']}
              lineHeight="1.2"
            >
              100% On-line
            </Text>
          </GridItem>

          <GridItem>
            <Center>
              <Image
                src="/icons/percent.png"
                alt="..."
                w={['50px', '50px', '90px']}
                mb={4}
              />
            </Center>
            <Text
              textAlign="center"
              color="#0B1880"
              fontWeight={500}
              fontSize={['16px', '16px', '18px']}
              lineHeight="1.2"
            >
              Bolsa de até 100%
            </Text>
          </GridItem>

          <GridItem>
            <Center>
              <Image
                src="/icons/purse.png"
                alt="..."
                w={['50px', '50px', '90px']}
                mb={4}
              />
            </Center>
            <Text
              textAlign="center"
              color="#0B1880"
              fontWeight={500}
              fontSize={['16px', '16px', '18px']}
              lineHeight="1.2"
            >
              Inscrição Gratuita
            </Text>
          </GridItem>
          <GridItem colSpan={[2, 1, 1]}>
            <Center>
              <Image
                src="/icons/pin.png"
                alt="..."
                w={['50px', '50px', '90px']}
                mb={4}
              />
            </Center>
            <Text
              textAlign="center"
              color="#0B1880"
              fontWeight={500}
              fontSize={['16px', '16px', '18px']}
              lineHeight="1.2"
            >
              Mais de 45 cidades
            </Text>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}

export default HowWork
