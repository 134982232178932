import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: inline-grid;
  grid-template-columns: 100%;

  width: 100%;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `}
`

export const Card = styled.div`
  position: relative;

  padding: 20px;
  border: solid 1px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 25px;

  svg {
    font-size: 52px;
    color: #cccccc;
  }

  button.bnt-open-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    color: var(--tertiary);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #95d5f9;
    }
  }

  ${media.lessThan('medium')`
    margin-bottom: 15px;

    svg {
      font-size: 46px;
      margin-right: 10px;
    }
  `}
`

export const Label = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #a6a5a5;

  ${media.lessThan('medium')`
    font-size: 10px;
    line-height: 14px;
  `}
`

export const Value = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;

  a.btn {
    display: block;
    background: var(--primary);
    border-radius: 30px;
    padding: 5px 20px;
    margin-top: 5px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
  }

  ${media.lessThan('medium')`
    font-size: 18px;
    line-height: 20px;
  `}
`
export const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(18, 34, 50, 0.85);

  z-index: 9999;
`

export const Modal = styled.div`
  position: relative;

  width: 100%;
  max-width: 520px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 18px;
  font-weight: 600;
  color: #293e52;

  margin-bottom: 20px;

  svg {
    margin-right: 10px;
    font-size: 28px;
  }
`
export const Button = styled.button`
  position: relative;

  display: inline-block !important;

  color: ${({ close }) => (close ? '#3b5bfd' : '#fff')} !important;
  background: ${({ close }) =>
    close ? 'rgba(59, 91, 253, 0.1)' : 'var(--button-action)'} !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 15px !important;
  margin-right: 10px !important;
  border-radius: 3px !important;
  width: fit-content !important;
  cursor: pointer;
  transition: all ease 0.3s;

  z-index: 4;

  &:hover {
    opacity: 0.8;
  }
`
