export default {
  field_cannot_be_empty: 'O campo não pode ser vazio.',
  invalid_date: 'Data inválida.',
  invalid_format_email: 'Email é inválido.',
  invalid_cep: 'CEP é inválido.',
  invalid_cpf: 'CPF é inválido.',
  landline_phone_number_is_invalid: 'Telefone é inválido.',
  mobile_phone_number_is_invalid: 'Celular é inválido.',
  fill_in_one_of_the_fields: 'Preencha um dos campos para continuar.',
  birth_date_is_invalid: 'Data de nascimento inválida',
  birth_date_less_than_12_years_old: 'Obrigatório ter idade superior a 12 anos',
  invalid_sms_confirmation_code: 'Código de SMS inválido.',
  sms_sent_in_less_than_30_seconds: 'Sms enviado a menos de 30 segundos.',

  number_of_characters_less_than_6: 'Número de caracteres é menor que 6'
}
