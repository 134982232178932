import { IsDevEnv } from './environment'

const prodPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnQN5fldE95dx/7UujKu/
XJ/oMvWmPfJgDIe2XmIQAL4Wj6Y40mcAI0j2HCg6bF3/2XgW/CYamllVPg8Kbj7J
lBQvlUWPuW0VvOeqvK6BCzwnwKXWmIqIren2PFlGoh+K1XSwxmzZRuqgbiskysca
HR1vL4MfpvlDRPEodIeo5Me6nxr8dW7BUoOaqdK60j20E/NcPYayQQb9rqPZGP/m
+i19PGKhFF5rEJP7be80h/7mMulUyjqlxFwjjVfTUBoM43o0hkasjVlq4l9b4k+0
j/cCaZoaYVggrA0tVufWZkLXB2PtCBvqiK4Zj8QT5Jd4652S3M93LLojOJfLKj2D
ZQIDAQAB
-----END PUBLIC KEY-----`

const betaPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjNrRcFEoGaceMYEE54Yb
OVurcuOwykZnY+DSu3kHGkrnMvYf7vJaUA42mNWYmNfMFJDyU6haGQxiq1pbWOMC
yZNZu9x8YU5BxTl0Dfy1XyNZPNh+1Juoy5vQch1F0sudseMrQNAOt5Y/i29+B/41
R+/GvtUx4CngdAjEbUnpiw2cpbRbWRHLytercj+iu4PRhx7/1SN8Qg6leE3PNrMZ
e7MXWuAsihFLM3sbqGGLOzdRvMAMo98mHYvPkQJHy6rqYwBoF2nvt9BYF+wAAUn/
Vwtuvdx7ZwvAb4tms15e3NHFP/u55+BbNPCdj7+nGmt7cVdB44GGRfXiBJdQdkZ6
mQIDAQAB
-----END PUBLIC KEY-----`

// FIXME - Prod public env apply in beta temporarily
export const MoipConfig = {
  publicKey: IsDevEnv ? betaPublicKey : prodPublicKey
}
