/* eslint-disable no-control-regex */
export const emailRegExp =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

export const formatEmail = (rawEmail: string | null): string => {
  if (!rawEmail) return ''

  let formattedEmail: string = rawEmail

  if (rawEmail) {
    formattedEmail = rawEmail?.toLowerCase()?.replace(/\s/g, '')
  }

  return formattedEmail
}

const emailDomainIsValid = (email: string): boolean => {
  const [, partialDomain] = email.split('@')
  let [domainName, topLevelDomain] = partialDomain.replace(/\./, '&').split('&')
  topLevelDomain = `.${topLevelDomain}`

  const popularDomainsOptions = {
    gmail: topLevelDomain === '.com',
    hotmail: topLevelDomain === '.com' || topLevelDomain === '.com.br',
    outlook: topLevelDomain === '.com' || topLevelDomain === '.com.br',
    yahoo: topLevelDomain === '.com' || topLevelDomain === '.com.br',
    icloud: topLevelDomain === '.com',
    live: topLevelDomain === '.com',
    bol: topLevelDomain === '.com' || topLevelDomain === '.com.br'
  } as {
    [key: string]: boolean
  }

  const popularDomainsName = Object.keys(popularDomainsOptions)

  if (!popularDomainsName.includes(domainName)) return true

  return popularDomainsOptions[domainName]
}

export const emailIsValid = (email: string): boolean => {
  const formattedEmail = String(email).toLowerCase()

  let isValid = emailRegExp.test(formattedEmail)

  if (isValid) {
    isValid = emailDomainIsValid(email)
  }

  return isValid
}
