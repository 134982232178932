export default {
  invalid_credit_card: 'Revise as informações do cartão.',
  invalid_credit_card_cvc: 'CVC inválido.',
  invalid_credit_card_number: 'Número inválido.',
  invalid_credit_card_expiry_date: 'Expiração inválida.',
  invalid_credit_card_holder_name: 'Nome do titular inválido',
  invalid_data:
    'Dados informados inválidos. Você digitou algo errado durante o preenchimento dos dados do seu Cartão. Certifique-se de que está usando o Cartão correto e faça uma nova tentativa.',
  failure_to_communicate_with_the_issuing_bank:
    'Houve uma falha de comunicação com o Banco Emissor do seu Cartão, tente novamente',
  issuing_bank_policy:
    ' O pagamento não foi autorizado pelo Banco Emissor do seu Cartão. Entre em contato com o Banco para entender o motivo e refazer o pagamento.',
  expired_card:
    'A validade do seu Cartão expirou. Escolha outra forma de pagamento para concluir o pagamento.',
  unauthorized_transaction:
    'O pagamento não foi autorizado. O mais provavel é falta de limite do cartão. Entre em contato com o Banco Emissor do seu Cartão.',
  duplicate_transaction:
    'Esse pagamento já foi realizado. Caso não encontre nenhuma referência ao pagamento anterior, por favor entre em contato com o nosso Atendimento.',
  wirecard_policy:
    'O pagamento não foi autorizado. Para mais informações, entre em contato com o nosso suporte.',
  requested_by_buyer: 'requested_by_buyer',
  requested_by_seller: 'requested_by_seller',
  unprocessed_transaction:
    'O pagamento não pode ser processado. Por favor, tente novamente. Caso o erro persista, entre em contato com o nosso atendimento',
  unknown:
    'Houve uma falha de comunicação com o Banco Emissor do seu Cartão, tente novamente.',
  issuing_bank_security_policy:
    'Pagamento não autorizado para este Cartão. Entre em contato com o Banco Emissor para mais esclarecimentos.',
  invalid_value:
    'Pagamento não autorizado. Entre em contato com o Atendimento e informe o ocorrido.',
  wirecard_security_policy:
    'Pagamento não autorizado. Indentificamos uma quebra nas políticas de segurança. Entre em contato com o Banco Emissor do seu Cartão para maiores informações',
  securityCode_invalid_parameter_format:
    'Pagamento não autorizado pela adquirente, por gentileza verifique o código de segurança do cartão de crédito.'
}
