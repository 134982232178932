/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useState } from 'react'
import { IUserData } from '@/protocols/User.protocol'
import { ICampaignData } from '@/protocols/Campaign.protocol'
import { PaymentOrder } from '@/protocols/PaymentOrder.protocol'

export interface GlobalStateContextData {
  user: IUserData
  setUserData: (data: IUserData) => void

  order: PaymentOrder
  setOrderData: (data: PaymentOrder) => void

  campaign: ICampaignData
  setCampaignData: (data: ICampaignData) => void
}

const GlobalStateStore = createContext<GlobalStateContextData>(
  {} as GlobalStateContextData
)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = props => {
  const { children } = props

  const [user, setUser] = useState<IUserData>({} as IUserData)
  const [campaign, setCampaign] = useState<ICampaignData>({} as ICampaignData)
  const [order, setOrder] = useState<PaymentOrder>({} as PaymentOrder)

  const setUserData = (data: IUserData) => {
    setUser(lastState => ({
      ...lastState,
      ...data
    }))
  }

  const setOrderData = (data: PaymentOrder) => {
    setOrder(lastState => ({
      ...lastState,
      ...data
    }))
  }

  const setCampaignData = (data: ICampaignData) => {
    setCampaign(lastState => ({
      ...lastState,
      ...data
    }))
  }

  return (
    <GlobalStateStore.Provider
      value={{
        user,
        setUserData,

        order,
        setOrderData,

        campaign,
        setCampaignData
      }}
      // @ts-ignore
      displayName="GlobalState"
    >
      {children}
    </GlobalStateStore.Provider>
  )
}

export default GlobalStateProvider
