import CodeMessages from '@/utils/errorMessages/codeMessages'

const Messages = (codeMessage: string): string => {
  const messageResponse =
    (CodeMessages as { [key: string]: string })[codeMessage] ||
    'Algo deu errado...'

  return messageResponse
}

export default Messages
