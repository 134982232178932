import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import { Element } from 'react-scroll'

import SealComponent from '@/components/Elements/Seal'

import skyBackground from '@/assets/images/sky_background.png'
// import girlSrc from '@/assets/images/girl-shape.png'
import quotesSrc from '@/assets/icons/aspas.svg'

export const Bg = styled(motion.div).attrs({
  layoutId: 'bg-overlay'
})`
  background-image: url(${skyBackground});
  background-size: cover;
  background-position: top;
  background-blend-mode: hard-light;
  background-color: white;
`

export const Container = styled(Element).attrs({
  name: 'subscription'
})`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 32px;

  height: 95vh;

  ${media.lessThan('medium')`
    height: auto;
    padding: 0 12px;

  `}
`

export const Grid = styled.div`
  position: relative;

  display: inline-grid;
  grid-template-columns: 50% 45%;
  grid-gap: 5%;

  align-items: center;

  width: 100%;
  max-width: 1117px;

  height: 100%;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    margin-top: 60px;

    padding: 20px;
  `}
`

export const Box = styled.div`
  h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.02em;
    color: var(--title-text);

    b {
      font-weight: 700;
      color: var(--primary);
    }
  }

  h2 {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    font-weight: 600;
    color: var(--title-text);

    margin: 10px 0 20px 0;

    b {
      font-weight: 700;
      color: #000;

      &.blue {
        color: var(--tertiary) !important;
      }

      &.orange {
        color: var(--primary) !important;
      }
    }

    mark {
      background: #bee3f8;
      padding: 4px 10px;
    }
  }

  h5 {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0 0 0;
  }

  ${media.lessThan('medium')`
  text-align: center;

    h1 {
      font-size: 28px;
      line-height: 1.2;
      margin-top: 20px;
    }

    h2 {
      font-size: 18px;
      line-height: 1.3;
      margin: 10px 0 20px 0;
    }
  `}
`

export const Render = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Text = styled.div`
  position: absolute;
  top: 260px;
  left: 30px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--tertiary);

  b {
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Seal = styled(SealComponent)`
  position: absolute;
  top: 170px;
  right: 0px;
`

export const Quotes = styled.div`
  background-image: url(${quotesSrc});
  background-size: contain;
  background-position: center;

  width: 19.8px;
  height: 13.14px;

  margin-bottom: 3px;
`

export const RenderImage = styled.img.attrs({
  src: 'assets/images/hero-girl.png',
  alt: 'O que você quer ser agora que cresceu? - Beduka',
  draggable: false
})`
  position: absolute;
  width: 360px;
  max-width: 100%;
  top: 50%;
  transform: translateY(-44%);
  right: 0;
`
