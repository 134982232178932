import { Box, Flex, Heading, Button, Text, Link } from '@chakra-ui/react'
import { ImWhatsapp } from 'react-icons/im'

import { Container } from '@/styles/global'
import { Content } from '@/styles/components/Footer'

import logoSrc from '@/assets/vale-bolsa-logo.svg'
import { Divider } from './'

const Footer = () => {
  return (
    <Content>
      <Flex
        pb={['40px', '40px', '80px']}
        flexDir={['column']}
        justifyContent="center"
        alignItems={['center']}
      >
        <Box mb={[5]} mr={[0, 0, 4]}>
          <Heading
            textAlign="center"
            size="lg"
            color="#1C609A"
            fontWeight={400}
          >
            <b>Ficou com alguma dúvida? </b>
          </Heading>

          <Text
            fontSize="17px !important"
            mt="12px !important"
            textAlign="center"
            color="#1C609A !important"
            fontWeight="500 !important"
          >
            <Link
              textDecoration="underline"
              isExternal
              href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
              textAlign="center"
            >
              Leia o regulamento
            </Link>{' '}
            ou fale com a gente!
          </Text>
        </Box>

        <Button
          size="lg"
          bg="#25D366"
          color="white"
          cursor="pointer"
          leftIcon={<ImWhatsapp />}
          _active={{ bg: '#25D366', opacity: 0.8 }}
          _hover={{ bg: '#25D366', opacity: 0.8 }}
          onClick={() => {
            window.open(
              'https://api.whatsapp.com/send?phone=5581989583016',
              '_blank'
            )
          }}
        >
          Entrar em contato
        </Button>
      </Flex>
      <Container padding="16px">
        <img src={logoSrc} alt="Beduka" draggable={false} />

        <Divider size={2} orientation="horizontal" />

        <Text color="#10209c !important">
          Nós te ajudamos a entrar na faculdade!
        </Text>
        <p>
          Copyright &copy; {new Date().getFullYear()} BEDUKA • Todos os Direitos
          Reservados • CNPJ 28.173.645/0001-45
          <div>
            <a href="https://beduka.com/" target="_blank" rel="noreferrer">
              Acesse Nosso Site Principal
            </a>
          </div>
        </p>
      </Container>
    </Content>
  )
}

export default Footer
