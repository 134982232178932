import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
  CircularProgress,
  Flex,
  Tooltip,
  Box
} from '@chakra-ui/react'
import colors from '@/styles/colors'
import { MdHelpOutline as HelpIcon } from 'react-icons/md'

type StyledInputProps = InputProps & {
  label?: string
  isRequired?: boolean
  tooltiplabel?: string

  helper?: string
  mask?: string

  loading?: boolean
}

const StyledInput: React.FC<StyledInputProps> = props => {
  return (
    <FormControl
      aria-required={false}
      isRequired={props.isRequired}
      my="6px"
      color={colors.grayScale[4]}
    >
      <Flex alignItems="center">
        {props.label && (
          <FormLabel
            fontSize="calc(0.85rem * 1)"
            fontWeight={500}
            color="inherit"
          >
            {props.label}
          </FormLabel>
        )}
        {props.tooltiplabel && (
          <Tooltip hasArrow closeOnClick={false} label={props.tooltiplabel}>
            <Box as="span">
              <HelpIcon
                color="inherit"
                style={{
                  marginBottom: '0.4rem'
                }}
              />
            </Box>
          </Tooltip>
        )}
      </Flex>

      <InputGroup>
        <Input
          {...props}
          isInvalid={props.isInvalid}
          errorBorderColor="crimson"
          borderRadius={11}
          backgroundColor={colors.grayScale[11]}
          border={'none'}
          boxShadow={'0px 0px 4px #00000029'}
          fontSize={16}
          width={'100%'}
          padding={'4px 12px'}
          _focus={{
            boxShadow: `${colors.palette.primary}B3 0 0 0 0.1rem`,
            borderColor: colors.palette.primary
          }}
        />

        {props?.loading && (
          <InputRightElement>
            <CircularProgress
              isIndeterminate
              color="palette.primary"
              size={'16px'}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {props.helper && (
        <FormHelperText color="crimson">{props.helper}</FormHelperText>
      )}
    </FormControl>
  )
}

export default StyledInput
