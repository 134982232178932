import {
  Container,
  Logo,
  Title,
  Name,
  Address,
  Features,
  Flex,
  Label,
  Value,
  Button
} from '@/styles/components/ScholarshipCard'

function ScholarshipCard({ data, onSelect, type }) {
  return (
    <Container>
      <Logo src={data.institution.logo} />
      <Title>{data.institution.acronym || data.institution.name}</Title>
      <Address>
        {data.camp.address}, {data.camp.city} - {data.camp.state}
      </Address>
      <Name>{data.course.name}</Name>
      <Features>
        <li>
          <b>Modalidade</b>: {data.modality}
        </li>
        <li>
          <b>Turno</b>: {data.shift}
        </li>
      </Features>
      <Flex>
        <div>
          <Label>Mensalidade</Label>
          <Value>
            R$
            {Number(data.price).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Value>
        </div>
        <div>
          <Label>Bolsas de até</Label>
          <Value>{data.discount}%</Value>
        </div>
      </Flex>
      <Button onClick={onSelect}>
        {type === 'change' ? 'Trocar curso' : 'Concorrer bolsa'}
      </Button>
    </Container>
  )
}

export default ScholarshipCard
