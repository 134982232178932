import { useState } from 'react'

import { Switch, Route, useHistory } from 'react-router-dom'

import { Box, Text } from '@chakra-ui/layout'

import PrivateRoutes from '@/routes/private'
import PublicRoutes from '@/routes/public'

import { Loading } from '@/components/Elements'

import AuthService from '@/services/AuthService'
import { useGlobalStateStore } from '@/store/GlobalState'
import UserService from '@/services/UserService'
import useDidMount from '@/contexts/hooks/useDidMount'
import CampaignService from '@/services/CampaignService'
import { IsBetaEnv } from '@/config/environment'
const PrivateComponent = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const globalStateStore = useGlobalStateStore()

  const getInitialData = async () => {
    const isLoggedIn = await AuthService.isLoggedIn()

    if (!isLoggedIn) {
      history.push('/')
      return
    }

    try {
      const userInfo = await UserService.getInfo()
      const orderData = userInfo?.orderData
      const userData = userInfo?.userData

      if (userData) globalStateStore.setUserData(userData)

      if (orderData) globalStateStore.setOrderData(orderData)

      if (globalStateStore.campaign.campaignStatus === 'result_release') {
        history.push('/resultado')
      }
    } catch (error) {
      history.push('/')
    }

    setLoading(false)
  }

  useDidMount(() => {
    getInitialData()
  })

  return (
    <Loading loading={loading}>
      <PrivateRoutes />
    </Loading>
  )
}

const Routes = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const globalStateStore = useGlobalStateStore()

  const getInitialData = async () => {
    setLoading(true)

    const campaignData = await CampaignService.getCampaignData()

    globalStateStore.setCampaignData(campaignData)

    setLoading(false)
  }

  useDidMount(() => {
    getInitialData()
  })

  return (
    <Loading loading={loading}>
      <Box position="relative">
        <Switch>
          {PublicRoutes}
          <Route path="/" component={PrivateComponent} />
          <Route path="*" component={() => <h1>Page Not Found</h1>} />
        </Switch>

        {IsBetaEnv && (
          <Box
            position="fixed"
            padding="4px 12px"
            bottom="0"
            backgroundColor="#ffaf1bf5"
            zIndex="999"
            left="50%"
            transform="translate(-50%, 0)"
            width="max-content"
            transition="all 0.3s"
            _hover={{
              opacity: 0
            }}
          >
            <Text
              textAlign="center"
              color="white"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Ambiente BETA
            </Text>
            <Text textAlign="center" color="white">
              Esté é um cenário apenas para teste!
            </Text>
          </Box>
        )}
      </Box>
    </Loading>
  )
}

export default Routes
