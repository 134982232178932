import { Box, Flex, Grid, Image } from '@chakra-ui/react'

function Institutions() {
  return (
    <Box
      as="section"
      p={['60px 20px 20px', '60px 20px 20px', '40px 0']}
      mt={['0', '0', '-120px']}
    >
      <Flex
        pos="relative"
        w="100%"
        maxW="1117px"
        m="0 auto"
        alignItems="center"
        flexWrap={['wrap', 'wrap', 'nowrap']}
      >
        <Box order={[1, 1, 0]} width={['100%', '100%', '30%']} pos="relative">
          <Image
            mt={['32px', '32px', 0]}
            pos={['relative', 'relative', 'absolute']}
            bottom={[0, 0, '40%']}
            left="30px"
            src="/vectors/waves.svg"
            alt="..."
            w="280px"
          />
        </Box>

        <Box order={[0, 0, 1]} width={['100%', '100%', '70%']}>
          <Box
            borderRadius="3xl"
            bg="white"
            boxShadow={['none', 'none', '2xl']}
            p={[3, 3, 12]}
          >
            <Grid
              templateColumns={[
                'repeat(2, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)'
              ]}
              gap={['35px', '35px', '46px']}
              alignItems="center"
            >
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/univeritas.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/uninassau.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/ung.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/unifacimed.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/uanama.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
              <Image
                src="https://prd-beduka-campaign-assets.s3.amazonaws.com/uninorte.png"
                maxW="100%"
                opacity={[0.6, 0.6, 0.2]}
                transition="all ease .3s"
                _hover={{ opacity: 0.95 }}
              />
            </Grid>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default Institutions
