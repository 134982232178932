/* eslint-disable indent */
import ApiService from '@/services/ApiService'
import React, { createContext, useState, useEffect, useCallback } from 'react'

import useDebounce from './hooks/use-debounce'

export const FormContext = createContext({})

function FormProvider({ children }) {
  const [readyToSubmit, setReadyToSubmit] = useState()
  const [loading, setLoading] = useState(true)
  const [campaignExpired, setCampaignExpired] = useState(false)
  const [connectionError, setConnectionError] = useState(false)

  const [city, setCity] = useState(sessionStorage.getItem('city') || '')
  const [courseId, setCourseId] = useState(
    sessionStorage.getItem('courseId') || ''
  )

  const [cities, setCities] = useState(
    sessionStorage.getItem('cities')
      ? JSON.parse(sessionStorage.getItem('cities'))
      : []
  )
  const [courses, setCourses] = useState(
    sessionStorage.getItem('courses')
      ? JSON.parse(sessionStorage.getItem('courses'))
      : null
  )

  const [scholarship, setScholarship] = useState(null)

  const [firstName, setFirstName] = useState(
    sessionStorage.getItem('firstName') || ''
  )
  const [lastName, setLastName] = useState(
    sessionStorage.getItem('lastName') || ''
  )
  const [phone, setPhone] = useState(sessionStorage.getItem('phone') || '')
  const [cpf, setCpf] = useState(sessionStorage.getItem('cpf') || '')
  const [birthDate, setBirthDate] = useState(
    sessionStorage.getItem('birthDate') || ''
  )
  const [genre, setGenre] = useState(sessionStorage.getItem('city') || 'MALE')

  const [searchCity, setSearchCity] = useState('')
  const [searchCourse, setSearchCourse] = useState('')

  const [success, setSuccess] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  const debouncedCity = useDebounce(searchCity, 300)
  const debouncedCourse = useDebounce(searchCourse, 300)

  async function getCourses() {
    setLoading(true)

    const result = await ApiService.get('campaign/list-courses').catch(e => {
      // Se retornar 401 é pq a campanha expirou
      setCampaignExpired(e?.response?.status === 401)

      if (e.message === 'Network Error') {
        setConnectionError(true)
      }
      setReadyToSubmit(false)
    })

    if (result) {
      setCourses(result.data)
      setReadyToSubmit(true)
    }

    setDataLoaded(true)
    setLoading(false)
  }

  function reset() {
    setScholarship(null)

    setCity('')

    setFirstName('')
    setLastName('')
    setPhone('')
    setCpf('')
    setBirthDate('')
    setGenre('MALE')

    setSearchCity('')
    setSearchCourse('')

    setSuccess(false)
  }

  useEffect(() => {
    getCourses()
  }, [])

  useEffect(() => {
    sessionStorage.setItem('firstName', firstName)
    sessionStorage.setItem('lastName', lastName)
    sessionStorage.setItem('phone', phone)
    sessionStorage.setItem('cpf', cpf)
    sessionStorage.setItem('birthDate', birthDate)
    sessionStorage.setItem('genre', genre)
    sessionStorage.setItem('city', city)
    sessionStorage.setItem('courseId', courseId)
    sessionStorage.setItem('cities', JSON.stringify(cities))
    sessionStorage.setItem('courses', JSON.stringify(courses))
    sessionStorage.setItem('courses', JSON.stringify(courses))
  }, [
    firstName,
    lastName,
    phone,
    cpf,
    birthDate,
    courses,
    genre,
    city,
    cities,
    courses,
    courseId,
    campaignExpired,
    connectionError
  ])

  const syncUserSearch = useCallback(
    async (ignoreSelected = false, searchedCourse) => {
      if (!courses) return
      const course = courses.find(c => c.id === parseInt(courseId))
      await ApiService.put('user/search', {
        city: city !== 'null' ? city : debouncedCity,
        courseId: ignoreSelected ? null : courseId,
        courseName: ignoreSelected
          ? searchedCourse
          : course
          ? course.name
          : searchedCourse
      }).catch(() => {
        console.log('[Search Sync]', 'Não foi possível sincronizar')
      })
    },
    [city, courseId, courses, courseId]
  )

  useEffect(() => {
    if (debouncedCourse !== '' || courseId) {
      const courseList = courses?.filter(course =>
        course.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            debouncedCourse
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      )

      if (courseList?.length > 0 && courseId) {
        syncUserSearch()
      } else {
        syncUserSearch(true, searchCourse)
      }
    }
  }, [courseId, city, debouncedCourse, debouncedCity, syncUserSearch])

  return (
    <FormContext.Provider
      value={{
        cities,
        setCities,
        courses,
        setCourses,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phone,
        setPhone,
        cpf,
        setCpf,
        birthDate,
        setBirthDate,
        genre,
        setGenre,
        city,
        setCity,
        courseId,
        setCourseId,
        scholarship,
        setScholarship,
        success,
        setSuccess,
        searchCity,
        setSearchCity,
        searchCourse,
        setSearchCourse,
        reset,
        readyToSubmit,
        loading,
        dataLoaded,
        campaignExpired,
        connectionError
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export default FormProvider
