import styled from 'styled-components'
import media from 'styled-media-query'

import colors from '@/styles/colors'

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  div {
    width: 100%;
    max-width: 500px;

    &.grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }

    .react-inputs-validation__msg_identifier {
      text-align: left;
      color: crimson;
      font-size: 12px;
      margin: 5px 10px;
    }

    input {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      height: 46px;
      padding: 0 15px;

      width: 100%;

      transition: all ease 0.3s;
      border-radius: 6px;

      &:disabled {
        background: #f8f8f8;
        color: #85868a;
      }

      &:focus,
      &:hover {
        border-color: #000;
      }

      ::placeholder {
        color: ${colors.grayScale[5]};
      }
    }

    &.name {
      padding-bottom: 16px;

      input {
        max-width: 100%;
      }
    }
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: var(--button-action);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 15px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;

    margin-top: 10px;

    width: 100%;
    max-width: 500px;

    height: 46px;

    transition: all ease 0.3s;

    img {
      max-width: 12px;
      margin-right: 8px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      cursor: not-allowed;
      background: #ebebed;
      color: #8c8c8c;
      padding: 0 35px;
    }
  }

  .subscription-button-container {
    margin-top: 16px;

    display: flex;
    justify-content: center;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    div {
      max-width: 100%;

      input {
        background: #ffffff;
        border-radius: 6px 6px 0px 0px;
        border: solid 1px var(0, 0, 0, .05);

        &:nth-child(1) {
          border-radius: 0 0 0px 6px;
        }

        &:nth-child(2) {
          border-radius: 0 0 6px 0px;
        }

        max-width: 100%;
      }


      &.name {

        input {
          max-width: 100%;
          border-radius: 6px !important;
        }
      }
    }

    button {
      width: 100%;
      border-radius: 6px 6px;
    }
  `}
`
