import { motion } from 'framer-motion'

function Seal() {
  const icon = {
    hidden: {
      pathLength: 0,
      fill: 'rgba(255, 255, 255, 1)'
    },
    visible: {
      pathLength: 1
    },
    transition: {
      type: 'spring',
      stiffness: 140,
      damping: 100
    }
  }

  return (
    <motion.svg
      layoutId="logo-brand"
      style={{
        position: 'absolute',
        top: 170,
        right: 0
      }}
      width="138"
      height="113"
      viewBox="0 0 138 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      layout
    >
      <motion.path
        d="M48.806 14.6565C48.9901 10.686 47.2016 6.67213 43.5856 4.15807C38.6561 0.735496 31.8265 1.45654 27.7161 5.81168C24.7012 9.00833 23.7851 13.3346 24.8224 17.2139C25.7918 20.8383 25.2198 24.6791 23.2664 27.8902C23.2422 27.9334 23.218 27.9719 23.1937 28.0152C21.1192 31.4762 17.5711 33.8316 13.5722 34.5046C7.68294 35.49 2.5256 39.6625 0.669146 45.8299C-1.6914 53.6845 2.37533 62.1592 10.0047 65.3078C16.8198 68.1199 24.357 66.0048 28.7728 60.7412C30.6729 58.4771 33.7072 57.4916 36.6154 58.0829C38.3943 58.4434 40.2605 58.5732 42.1799 58.4338C52.4122 57.7031 60.5699 49.3582 61.0062 39.1914C61.3164 31.9905 57.8168 25.578 52.3444 21.7757C50.0032 20.1509 48.6751 17.4831 48.806 14.6565Z"
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
      />
      <motion.path
        d="M134.949 57.2753C133.325 54.3239 130.926 51.8146 128.018 50.0264L128.372 49.464C131.386 51.3147 133.868 53.9105 135.545 56.9677L134.949 57.2753Z"
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#FFA944' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
      />
      <motion.path
        d="M122.657 85.473L122.497 84.8241C127.533 83.6897 131.789 80.6853 134.484 76.3686C137.179 72.0471 137.993 66.9325 136.772 61.9572L137.431 61.8082C140.043 72.4317 133.413 83.0503 122.657 85.473Z"
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#FFA944' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M123.592 49.6466C115.939 42.7246 104.087 42.6669 96.3659 49.5168C93.3898 52.1607 91.3928 55.4439 90.3749 58.9482C89.4539 62.1112 86.6281 64.3897 83.3223 64.6829C79.4689 65.0242 75.6929 66.274 72.3533 68.3987C69.4547 70.2398 65.6933 70.1244 62.9886 68.019C60.1579 65.8125 56.571 64.5195 52.6788 64.5868C43.6583 64.7358 36.4118 72.2972 36.693 81.2478C36.9644 89.9292 44.143 96.8897 52.9599 96.8897C53.9245 96.8897 54.8648 96.808 55.7858 96.6446C59.1594 96.0581 62.533 97.6925 64.2101 100.658C65.6642 103.24 67.6273 105.595 70.0751 107.58C80.2929 115.858 95.4935 114.503 104.063 104.547C107.5 100.553 109.395 95.7793 109.822 90.9291C110.108 87.6651 112.395 84.8819 115.57 83.9782C118.769 83.0648 121.784 81.3584 124.305 78.8587C132.462 70.7493 132.23 57.458 123.592 49.6466Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#1C609A' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M134.649 32.3222C132.763 30.2119 130.33 28.9237 127.771 28.4526C125.143 27.9671 122.875 26.3183 121.784 23.8955C115.444 9.81107 101.223 0 84.6941 0C70.9186 0 58.7523 6.81631 51.4089 17.2379C48.5006 21.3671 22.9611 27.7171 16.9022 33.822C4.31423 46.4932 4.38694 66.7162 17.0573 79.3057C24.3522 86.5547 34.2501 89.6311 43.7601 88.5448C49.378 87.9006 55.0006 90.0926 58.5633 94.4526C65.4122 102.826 76.4395 104.605 87.9853 103.927C122.657 101.884 119.85 61.616 122.361 55.4294C123.059 53.7133 124.668 52.5548 126.52 52.401C129.52 52.1558 132.453 50.8147 134.639 48.3631C138.706 43.8301 138.706 36.86 134.649 32.3222Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#FFA944' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M20.2806 24.3233C20.2806 27.3469 17.8086 29.7985 14.7598 29.7985C11.7109 29.7985 9.23889 27.3469 9.23889 24.3233C9.23889 21.2997 11.7109 18.8482 14.7598 18.8482C17.8086 18.8433 20.2806 21.2949 20.2806 24.3233Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M73.0803 82.6899H71.9122V80.8585H73.0803C74.0061 80.8585 74.1079 81.5026 74.1079 81.7766C74.1079 82.0506 74.0061 82.6899 73.0803 82.6899ZM73.1385 85.8241H71.9122V83.9013H73.1482C73.9528 83.9013 74.1661 84.5118 74.1661 84.8723C74.1661 85.3049 73.9867 85.8241 73.1385 85.8241ZM74.3842 83.262C74.9077 83.0312 75.3924 82.488 75.3924 81.6756C75.3924 81.0652 75.1646 79.6471 73.0803 79.6471H70.6955C70.6568 79.6471 70.6277 79.6807 70.6277 79.7192V86.9633C70.6277 87.0018 70.6568 87.0354 70.6955 87.0354H73.09C74.2339 87.0354 75.4554 86.4778 75.4554 84.9156C75.4554 83.9782 74.8641 83.4254 74.3842 83.262Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M77.0841 83.983C77.1132 83.3389 77.5349 82.9399 78.199 82.9399C78.8291 82.9399 79.2265 83.3389 79.2556 83.983H77.0841ZM80.3995 84.9059C80.448 84.5791 80.448 84.3531 80.448 84.1897C80.448 82.9303 79.4689 81.9448 78.2232 81.9448C76.7884 81.9448 75.8578 82.9687 75.8578 84.555C75.8578 86.1654 76.7739 87.1652 78.2523 87.1652C79.5561 87.1652 80.2832 86.3192 80.4044 85.5308C80.4092 85.5116 80.3996 85.4876 80.3899 85.4732C80.3753 85.4587 80.3559 85.4491 80.3365 85.4491H79.2411C79.212 85.4491 79.1878 85.4683 79.1732 85.4972C79.0617 85.7856 78.8679 86.0836 78.2523 86.0836C77.53 86.0836 77.1568 85.7327 77.0841 84.978H80.3268C80.3656 84.9684 80.3947 84.9396 80.3995 84.9059Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M84.762 84.5502C84.762 85.3818 84.1997 86.0115 83.4532 86.0115C82.7068 86.0115 82.1687 85.3963 82.1687 84.5502C82.1687 83.7042 82.7116 83.0937 83.4532 83.0937C84.1997 83.0937 84.762 83.7186 84.762 84.5502ZM85.9204 79.6471H84.8347C84.7959 79.6471 84.7668 79.6807 84.7668 79.7192V82.5697C84.4469 82.19 83.9283 81.9448 83.3999 81.9448C82.0233 81.9448 80.9473 83.0889 80.9473 84.555C80.9473 86.0164 82.0233 87.1652 83.3999 87.1652C83.9283 87.1652 84.4469 86.9201 84.7668 86.5403V86.9681C84.7668 87.0066 84.7959 87.0403 84.8347 87.0403H85.9204C85.9592 87.0403 85.9883 87.0066 85.9883 86.9681V79.724C85.9883 79.6759 85.9592 79.6471 85.9204 79.6471Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M91.1456 82.0698H90.0599C90.0211 82.0698 89.992 82.1035 89.992 82.1419V84.6368C89.992 85.5068 89.5994 86.0212 88.9451 86.0212C88.3149 86.0212 88.0096 85.5693 88.0096 84.6368V82.1419C88.0096 82.1035 87.9805 82.0698 87.9417 82.0698H86.8559C86.8172 82.0698 86.7881 82.1035 86.7881 82.1419V85.0069C86.7881 85.7183 86.8705 86.1173 87.0983 86.4778C87.3988 86.9297 87.8884 87.1604 88.5524 87.1604C89.1777 87.1604 89.6818 86.9393 89.9969 86.5259V86.9585C89.9969 86.997 90.026 87.0306 90.0647 87.0306H91.1505C91.1893 87.0306 91.2184 86.997 91.2184 86.9585V82.1371C91.2184 82.0987 91.1844 82.0698 91.1456 82.0698Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M94.3108 84.5502L96.2109 82.1851C96.2302 82.1611 96.2302 82.1322 96.2205 82.1082C96.2108 82.0842 96.1818 82.0649 96.1575 82.0649H94.8003C94.781 82.0649 94.7616 82.0746 94.747 82.089L93.1669 84.0647V79.7143C93.1669 79.6759 93.1378 79.6422 93.0942 79.6422H92.0084C91.9696 79.6422 91.9406 79.6759 91.9406 79.7143V86.9585C91.9406 86.9969 91.9696 87.0306 92.0084 87.0306H93.0942C93.1329 87.0306 93.1669 86.9969 93.1669 86.9585V85.0357L94.747 87.0017C94.7616 87.0161 94.781 87.0258 94.8003 87.0258H96.1575C96.1866 87.0258 96.2108 87.0113 96.2205 86.9825C96.2302 86.9585 96.2302 86.9248 96.2109 86.9056L94.3108 84.5502Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M99.9819 84.5501C99.9819 85.3817 99.4197 86.0067 98.6732 86.0067C97.9267 86.0067 97.3887 85.3914 97.3887 84.5501C97.3887 83.7041 97.9316 83.0936 98.6732 83.0936C99.4245 83.0936 99.9819 83.7185 99.9819 84.5501ZM101.14 82.0697H100.055C100.016 82.0697 99.9868 82.1034 99.9868 82.1418V82.5697C99.6669 82.1899 99.1482 81.9399 98.6199 81.9399C97.2433 81.9399 96.1672 83.084 96.1672 84.5501C96.1672 86.0115 97.2433 87.1603 98.6199 87.1603C99.1482 87.1603 99.6669 86.9152 99.9868 86.5354V86.9633C99.9868 87.0017 100.016 87.0354 100.055 87.0354H101.14C101.179 87.0354 101.208 87.0017 101.208 86.9633V82.1418C101.213 82.0986 101.179 82.0697 101.14 82.0697Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M58.5148 89.5399C58.253 89.5399 58.0155 89.362 57.9428 89.088C57.8556 88.7611 58.0397 88.4198 58.3548 88.3285C59.4163 88.0209 60.1531 87.0018 60.1531 85.8577C60.1531 85.0309 59.7653 84.2522 59.1158 83.7715C58.8492 83.5744 58.7862 83.1898 58.9801 82.911C59.1691 82.6322 59.5424 82.5697 59.8089 82.7668C60.7687 83.4783 61.3455 84.6367 61.3455 85.8577C61.3455 87.5498 60.25 89.0544 58.6796 89.5158C58.6214 89.5303 58.5681 89.5399 58.5148 89.5399Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M65.6206 83.8868C65.4364 83.8868 65.2522 83.7955 65.1359 83.6272C64.9469 83.3484 65.005 82.9639 65.2765 82.7668C65.9454 82.2717 66.3331 81.4641 66.3138 80.6084C66.2798 79.2336 65.1408 78.1136 63.7739 78.1136H60.3809C60.9819 78.801 61.3454 79.7047 61.3454 80.6757C61.3454 81.9015 60.7735 83.0552 59.8089 83.7666C59.5423 83.9637 59.1691 83.9012 58.98 83.6224C58.791 83.3436 58.8492 82.9591 59.1206 82.762C59.7701 82.2813 60.1579 81.4977 60.1579 80.6757C60.1579 79.5317 59.4163 78.5174 58.3596 78.205C58.1027 78.128 57.9282 77.8877 57.9282 77.6089V77.4887C57.9282 77.1474 58.1948 76.8734 58.5244 76.8734H63.7787C65.7806 76.8734 67.4528 78.5318 67.5062 80.5652C67.5401 81.8294 66.9633 83.0264 65.9745 83.7618C65.8581 83.8483 65.737 83.8868 65.6206 83.8868Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#F8F8F8' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M63.7739 89.6552H58.5197C58.1901 89.6552 57.9235 89.3812 57.9235 89.0399V88.9198C57.9235 88.5785 58.1901 88.3045 58.5197 88.3045C58.6505 88.3045 58.7717 88.3477 58.8686 88.4246H63.7739C65.136 88.4246 66.2751 87.3046 66.3138 85.9298C66.3332 85.1367 66.0036 84.3868 65.422 83.8868H59.4697C59.1401 83.8868 58.8735 83.6128 58.8735 83.2715C58.8735 82.9302 59.1401 82.6562 59.4697 82.6562H65.6255C65.7516 82.6562 65.8727 82.6947 65.9697 82.7716C66.9634 83.5071 67.5353 84.704 67.5062 85.9683C67.4432 88.0016 65.7758 89.6552 63.7739 89.6552Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M44.4048 51.6079C44.206 52.1174 43.8377 52.3674 43.3432 52.3674H39.2135C38.7191 52.3674 38.3556 52.1126 38.152 51.6079L28.7437 27.2701C28.6904 27.1066 28.6516 26.9816 28.6516 26.8903C28.6516 26.6163 28.8503 26.4913 29.2381 26.4913L34.8317 26.5298C35.36 26.5298 35.709 26.7846 35.8738 27.3085L41.2638 42.8928L46.6005 27.3085C46.7847 26.7846 47.1289 26.5298 47.6427 26.5298H53.3089C53.6919 26.5298 53.8954 26.6548 53.8954 26.9288C53.8954 27.0201 53.8567 27.1451 53.8033 27.3085L44.4048 51.6079Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M76.294 51.5886C76.294 52.1126 76.0371 52.3673 75.5088 52.3673H71.5051C70.8653 52.3673 70.5938 51.7136 70.206 50.7185L69.9685 50.1032C68.3399 51.7905 66.2411 52.7327 63.9193 52.7327C61.9272 52.7327 60.0804 52.2424 58.3645 51.281C56.6293 50.3196 55.2575 48.9784 54.2154 47.2575C53.1733 45.5366 52.6643 43.5609 52.6643 41.3497C52.6643 39.2635 53.1587 37.3647 54.1621 35.6054C55.1509 33.8652 56.5032 32.4904 58.2191 31.4569C59.935 30.4426 61.8351 29.9331 63.9193 29.9331C66.5513 29.9331 68.6889 30.9858 70.2981 32.8702L70.9913 30.9474C71.2094 30.3129 71.3209 30.1686 71.7765 30.1686H75.5039C76.0323 30.1686 76.2892 30.4234 76.2892 30.9474V51.5886H76.294ZM64.2344 35.8265C61.4909 35.7736 58.9365 38.1627 58.9898 41.3161C58.9365 44.3781 61.2922 46.8633 64.2344 46.8056C67.1766 46.8585 69.6438 44.2147 69.5905 41.3161C69.6438 38.4174 67.1766 35.7688 64.2344 35.8265Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M80.4431 24.5348C80.4431 24.0109 80.7921 23.7177 81.3011 23.7177H86.2694C86.7638 23.7177 87.1079 24.0445 87.1079 24.5348V51.5549C87.1079 52.0452 86.7977 52.3721 86.2694 52.3721H81.3011C80.7339 52.3721 80.4431 52.0981 80.4431 51.5549V24.5348Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M107.781 45.0847C107.98 45.0847 108.164 45.176 108.295 45.3395L110.544 48.4207C110.728 48.6563 110.82 48.8534 110.82 49.0553C110.82 49.2524 110.709 49.435 110.51 49.5985C109.138 50.7954 107.786 51.6078 106.433 52.0644C105.081 52.5163 103.637 52.7326 102.1 52.7326C100.108 52.7326 98.2273 52.2231 96.4532 51.1944C94.6792 50.1609 93.2735 48.7861 92.212 47.0459C91.1504 45.3058 90.6221 43.4022 90.6221 41.3545C90.5106 35.1727 95.3917 29.8465 101.916 29.9715C103.452 29.9715 105.023 30.3705 106.632 31.1685C108.222 31.9856 109.574 33.2355 110.67 34.9179C111.765 36.6052 112.313 38.7058 112.313 41.2055C112.26 43.109 111.727 43.3638 109.7 43.3974H97.0542C97.6747 45.7336 99.7056 47.2382 102.061 47.2382C103.835 47.2382 105.023 46.8777 105.735 46.4403C106.084 46.2432 106.467 45.95 106.884 45.6087C107.083 45.4452 107.233 45.3202 107.325 45.2481C107.451 45.1376 107.616 45.0847 107.781 45.0847ZM97.3984 38.4318H106.259C105.309 36.384 103.738 35.2256 101.707 35.2256C99.8123 35.2256 98.203 36.5475 97.3984 38.4318Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M33.2903 56.3139C33.2903 55.9438 33.4842 55.7659 33.8768 55.7659H42.781C45.854 55.7034 48.5975 58.1934 48.5248 61.0248C48.5248 62.2457 48.1031 63.3129 47.3227 64.1397C48.6217 65.2213 49.4215 66.7932 49.4215 68.4083C49.4894 71.2781 46.6393 73.9604 43.5662 73.8835H33.8768C33.4842 73.8835 33.2903 73.7056 33.2903 73.3499V56.3139ZM38.4767 62.9091H41.3268C42.5434 62.9091 43.3965 62.1592 43.3965 61.1449C43.3965 60.1643 42.558 59.3808 41.3704 59.3808H38.4767V62.9091ZM38.4767 70.2686H42.0684C43.159 70.2686 44.1382 69.3168 44.1382 68.2353C44.1382 67.2546 43.2317 66.1875 42.0684 66.1875H38.4767V70.2686Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M52.3298 62.1447C53.1247 60.9237 54.1911 59.9575 55.5434 59.2365C56.9006 58.525 58.3693 58.1693 59.9737 58.1693C61.583 58.1693 63.0613 58.525 64.4185 59.2365C65.7757 59.9623 66.8373 60.9285 67.6322 62.1447C68.4271 63.3753 68.8197 64.7116 68.8197 66.1489C68.8197 67.5958 68.4271 68.9322 67.6322 70.1531C66.8373 71.3837 65.7612 72.3499 64.404 73.0614C63.0468 73.7872 61.5684 74.1429 59.9737 74.1429C58.3645 74.1429 56.9006 73.7872 55.5434 73.0614C54.1862 72.3499 53.1247 71.3837 52.3298 70.1531C51.5349 68.9322 51.1423 67.6006 51.1423 66.1489C51.1374 64.702 51.53 63.3657 52.3298 62.1447ZM59.9737 70.0234C62.2228 70.0618 63.8999 68.1823 63.8757 66.1489C63.9193 64.0915 62.2519 62.2505 59.9883 62.2841C57.7247 62.2456 56.0185 64.0867 56.0621 66.1489C56.0185 68.2063 57.7101 70.0618 59.9737 70.0234Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M71.4226 54.3718C71.4226 54.0017 71.6892 53.7998 72.0818 53.7998H75.882C76.26 53.7998 76.5266 54.0305 76.5266 54.3718V73.3162C76.5266 73.6575 76.2891 73.8882 75.882 73.8882H72.0818C71.6504 73.8882 71.4226 73.6959 71.4226 73.3162V54.3718Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M79.1344 71.004C79.1344 70.8261 79.2313 70.6483 79.4155 70.4848L81.3011 68.8072C81.4125 68.7062 81.5386 68.6534 81.6646 68.6534C81.8197 68.6534 81.9845 68.7303 82.1832 68.8841C83.3417 69.8119 84.4469 70.2685 85.4696 70.2685C86.7977 70.2685 87.2873 69.8599 87.2873 69.1869C87.2049 68.4515 86.9528 68.3121 84.6456 67.6872C81.1944 66.7738 79.6821 65.2116 79.6821 62.4523C79.6821 59.9239 82.0572 58.0924 86.0852 58.0924C87.9319 58.0924 89.3134 58.6116 90.1228 59.174C90.53 59.4528 91.005 59.8325 91.5624 60.3036C91.7709 60.4671 91.8678 60.6209 91.8678 60.7603C91.8678 60.8997 91.7563 61.0631 91.5479 61.2314L89.4927 62.8561C89.3231 62.9811 89.1873 63.0484 89.0613 63.0484C88.9353 63.0484 88.7947 62.9859 88.6154 62.8465C87.9998 62.3899 87.7332 62.188 87.1758 62.0101C86.8947 61.9188 86.5747 61.8851 86.2112 61.8851C85.203 61.8851 84.6456 62.2168 84.6456 62.635C84.728 63.2599 84.9655 63.3224 86.6329 63.7791C87.2921 63.9425 87.7914 64.0819 88.1404 64.1973C89.7738 64.6924 90.8935 65.3654 91.5091 66.2162C92.1102 67.0815 92.4155 68.0717 92.4155 69.1773C92.4834 72.2538 89.2407 74.2487 85.8719 74.2103C83.3126 74.2103 81.1169 73.5229 79.4543 71.6049C79.2459 71.3837 79.1344 71.1915 79.1344 71.004Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#00088F' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M112.851 73.3402C112.851 73.7103 112.657 73.8882 112.25 73.8882H109.187C108.697 73.8882 108.489 73.4315 108.193 72.7297L108.014 72.2971C106.768 73.4796 105.163 74.1381 103.385 74.1381C101.863 74.1381 100.447 73.7968 99.1337 73.1239C97.8055 72.4509 96.7586 71.5087 95.9588 70.3022C95.1639 69.0956 94.7712 67.7112 94.7712 66.1585C94.7712 64.6972 95.1493 63.3609 95.92 62.1303C96.6762 60.9093 97.7086 59.9431 99.0222 59.2221C100.336 58.5106 101.79 58.1549 103.385 58.1549C105.396 58.1549 107.034 58.8904 108.266 60.2123L108.799 58.8663C108.968 58.4193 109.051 58.3183 109.4 58.3183H112.25C112.657 58.3183 112.851 58.4962 112.851 58.8663V73.3402ZM103.627 62.2889C101.528 62.2505 99.5747 63.9281 99.6135 66.1393C99.5699 68.288 101.373 70.0282 103.627 69.9897C105.876 70.0282 107.766 68.1727 107.723 66.1393C107.762 64.106 105.876 62.2505 103.627 62.2889Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M43.7795 50.983C43.5808 51.4925 43.2124 51.7425 42.718 51.7425H38.5883C38.0938 51.7425 37.7303 51.4877 37.5267 50.983L28.1185 26.6452C28.0651 26.4817 28.0264 26.3568 28.0264 26.2654C28.0264 25.9914 28.2251 25.8665 28.6129 25.8665L34.2065 25.9049C34.7348 25.9049 35.0838 26.1597 35.2486 26.6836L40.6386 42.2679L45.9753 26.6836C46.1595 26.1597 46.5036 25.9049 47.0174 25.9049H52.6837C53.0666 25.9049 53.2702 26.0299 53.2702 26.3039C53.2702 26.3952 53.2314 26.5202 53.1781 26.6836L43.7795 50.983Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M75.6688 50.9685C75.6688 51.4924 75.4119 51.7472 74.8836 51.7472H70.8798C70.24 51.7472 69.9637 51.0935 69.5808 50.0984L69.3433 49.4831C67.7147 51.1704 65.6159 52.1125 63.2941 52.1125C61.3019 52.1125 59.4552 51.6222 57.7393 50.6608C56.004 49.6994 54.6323 48.3583 53.5901 46.6374C52.548 44.9165 52.0391 42.9408 52.0391 40.7296C52.0391 38.6434 52.5335 36.7446 53.5368 34.9852C54.5256 33.2451 55.878 31.8703 57.5939 30.8368C59.3098 29.8225 61.2098 29.313 63.2941 29.313C65.9261 29.313 68.0637 30.3657 69.6729 32.2501L70.366 30.3273C70.5842 29.6927 70.6957 29.5485 71.1513 29.5485H74.8787C75.4071 29.5485 75.664 29.8033 75.664 30.3273V50.9685H75.6688ZM63.6092 35.2016C60.8657 35.1487 58.3112 37.5377 58.3646 40.6911C58.3112 43.7532 60.6669 46.2384 63.6092 46.1807C66.5514 46.2336 69.0185 43.5898 68.9652 40.6911C69.0185 37.7925 66.5514 35.1439 63.6092 35.2016Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M79.8179 23.91C79.8179 23.386 80.1669 23.0928 80.6758 23.0928H85.6441C86.1385 23.0928 86.4827 23.4196 86.4827 23.91V50.9301C86.4827 51.4204 86.1725 51.7472 85.6441 51.7472H80.6758C80.1087 51.7472 79.8179 51.4732 79.8179 50.9301V23.91Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M107.156 44.4598C107.354 44.4598 107.539 44.5512 107.67 44.7146L109.919 47.7959C110.103 48.0314 110.195 48.2285 110.195 48.4304C110.195 48.6275 110.083 48.8101 109.885 48.9736C108.513 50.1705 107.161 50.9829 105.808 51.4396C104.456 51.8914 103.011 52.1077 101.475 52.1077C99.4827 52.1077 97.602 51.5982 95.828 50.5695C94.0539 49.536 92.6482 48.1612 91.5867 46.4211C90.5252 44.6809 89.9969 42.7774 89.9969 40.7296C89.8854 34.5478 94.7664 29.2217 101.291 29.3466C102.827 29.3466 104.398 29.7456 106.007 30.5436C107.597 31.3608 108.949 32.6106 110.045 34.293C111.14 35.9755 111.688 38.0809 111.688 40.5806C111.634 42.4841 111.101 42.7389 109.075 42.7726H96.429C97.0494 45.1088 99.0804 46.6134 101.436 46.6134C103.21 46.6134 104.398 46.2528 105.11 45.8154C105.459 45.6183 105.842 45.3251 106.259 44.9838C106.458 44.8203 106.608 44.6954 106.7 44.6233C106.826 44.5127 106.991 44.4598 107.156 44.4598ZM96.7732 37.8069H105.634C104.684 35.7592 103.113 34.6007 101.082 34.6007C99.187 34.6007 97.5778 35.9226 96.7732 37.8069Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M32.665 55.689C32.665 55.3189 32.8589 55.141 33.2515 55.141H42.1557C45.2288 55.0786 47.9723 57.5686 47.8996 60.3999C47.8996 61.6209 47.4779 62.688 46.6975 63.5148C47.9965 64.5964 48.7963 66.1683 48.7963 67.7834C48.8641 70.6532 46.014 73.3355 42.9409 73.2586H33.2515C32.8589 73.2586 32.665 73.0807 32.665 72.725V55.689ZM37.8515 62.2842H40.7016C41.9182 62.2842 42.7713 61.5343 42.7713 60.5201C42.7713 59.5394 41.9327 58.7559 40.7452 58.7559H37.8515V62.2842ZM37.8515 69.6437H41.4432C42.5338 69.6437 43.5129 68.692 43.5129 67.6104C43.5129 66.6298 42.6065 65.5626 41.4432 65.5626H37.8515V69.6437Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M51.7046 61.5198C52.4995 60.2988 53.5659 59.3326 54.9182 58.6116C56.2754 57.9002 57.7441 57.5444 59.3485 57.5444C60.9577 57.5444 62.4361 57.9002 63.7933 58.6116C65.1505 59.3374 66.212 60.3037 67.0069 61.5198C67.8019 62.7504 68.1945 64.0868 68.1945 65.524C68.1945 66.9709 67.8019 68.3073 67.0069 69.5283C66.212 70.7589 65.136 71.7251 63.7788 72.4365C62.4216 73.1624 60.9432 73.5181 59.3485 73.5181C57.7392 73.5181 56.2754 73.1624 54.9182 72.4365C53.561 71.7251 52.4995 70.7589 51.7046 69.5283C50.9096 68.3073 50.517 66.9758 50.517 65.524C50.5122 64.0771 50.9048 62.7408 51.7046 61.5198ZM59.3485 69.3985C61.5975 69.4369 63.2747 67.5574 63.2504 65.524C63.294 63.4667 61.6266 61.6256 59.363 61.6592C57.0994 61.6208 55.3932 63.4618 55.4368 65.524C55.3932 67.5814 57.0849 69.4369 59.3485 69.3985Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M70.7974 53.747C70.7974 53.3768 71.064 53.1749 71.4566 53.1749H75.2567C75.6348 53.1749 75.9014 53.4057 75.9014 53.747V72.6913C75.9014 73.0326 75.6639 73.2633 75.2567 73.2633H71.4566C71.0252 73.2633 70.7974 73.0711 70.7974 72.6913V53.747Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M78.5092 70.3791C78.5092 70.2013 78.6061 70.0234 78.7903 69.86L80.6758 68.1823C80.7873 68.0814 80.9133 68.0285 81.0394 68.0285C81.1945 68.0285 81.3593 68.1054 81.558 68.2592C82.7165 69.187 83.8216 69.6436 84.8443 69.6436C86.1725 69.6436 86.662 69.235 86.662 68.5621C86.5796 67.8266 86.3276 67.6872 84.0203 67.0623C80.5692 66.149 79.0569 64.5867 79.0569 61.8275C79.0569 59.299 81.432 57.4675 85.4599 57.4675C87.3067 57.4675 88.6881 57.9867 89.4976 58.5491C89.9047 58.8279 90.3798 59.2077 90.9372 59.6787C91.1456 59.8422 91.2426 59.996 91.2426 60.1354C91.2426 60.2748 91.1311 60.4382 90.9226 60.6065L88.8675 62.2313C88.6978 62.3562 88.5621 62.4235 88.4361 62.4235C88.31 62.4235 88.1695 62.361 87.9901 62.2216C87.3745 61.765 87.108 61.5631 86.5505 61.3852C86.2694 61.2939 85.9495 61.2602 85.586 61.2602C84.5778 61.2602 84.0203 61.5919 84.0203 62.0101C84.1027 62.635 84.3402 62.6975 86.0077 63.1542C86.6669 63.3176 87.1661 63.457 87.5151 63.5724C89.1486 64.0675 90.2683 64.7405 90.8839 65.5913C91.4849 66.4566 91.7903 67.4468 91.7903 68.5525C91.8581 71.6289 88.6154 73.6238 85.2467 73.5854C82.6874 73.5854 80.4916 72.898 78.8291 70.98C78.6255 70.7589 78.5092 70.5714 78.5092 70.3791Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#C0DFED' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M112.226 72.7153C112.226 73.0855 112.032 73.2633 111.625 73.2633H108.561C108.072 73.2633 107.863 72.8066 107.568 72.1048L107.388 71.6722C106.143 72.8547 104.538 73.5133 102.759 73.5133C101.237 73.5133 99.822 73.172 98.5084 72.499C97.1803 71.826 96.1333 70.8838 95.3335 69.6773C94.5386 68.4707 94.146 67.0863 94.146 65.5337C94.146 64.0723 94.5241 62.736 95.2948 61.5054C96.0509 60.2844 97.0834 59.3182 98.3969 58.5972C99.7105 57.8857 101.165 57.53 102.759 57.53C104.771 57.53 106.409 58.2655 107.64 59.5874L108.174 58.2415C108.343 57.7944 108.426 57.6935 108.775 57.6935H111.625C112.032 57.6935 112.226 57.8713 112.226 58.2415V72.7153ZM103.002 61.664C100.903 61.6256 98.9495 63.3032 98.9883 65.5144C98.9446 67.6632 100.748 69.4033 103.002 69.3648C105.251 69.4033 107.141 67.5478 107.098 65.5144C107.141 63.4811 105.251 61.6256 103.002 61.664Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M43.0427 50.3629C42.844 50.8724 42.4756 51.1224 41.9812 51.1224H37.8563C37.3619 51.1224 36.9983 50.8676 36.7948 50.3629L27.3816 26.0251C27.3283 25.8616 27.2896 25.7367 27.2896 25.6453C27.2896 25.3713 27.4883 25.2463 27.8761 25.2463L33.4696 25.2848C33.998 25.2848 34.347 25.5396 34.5118 26.0635L39.9018 41.6478L45.2385 26.0635C45.4226 25.5396 45.7668 25.2848 46.2806 25.2848H51.9469C52.3298 25.2848 52.5334 25.4098 52.5334 25.6838C52.5334 25.7751 52.4946 25.9001 52.4413 26.0635L43.0427 50.3629Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M74.932 50.3484C74.932 50.8723 74.6751 51.1271 74.1468 51.1271H70.143C69.5032 51.1271 69.2318 50.4733 68.844 49.4783L68.6065 48.863C66.9779 50.5503 64.879 51.4924 62.5573 51.4924C60.5651 51.4924 58.7184 51.0021 57.0025 50.0407C55.2672 49.0793 53.8955 47.7382 52.8533 46.0173C51.8112 44.2964 51.3022 42.3207 51.3022 40.1095C51.3022 38.0232 51.7967 36.1245 52.8 34.3651C53.7888 32.625 55.1412 31.2502 56.8571 30.2167C58.5729 29.2024 60.473 28.6929 62.5573 28.6929C65.1893 28.6929 67.3268 29.7456 68.9361 31.6299L69.6292 29.7071C69.8474 29.0726 69.9588 28.9284 70.4145 28.9284H74.1419C74.6702 28.9284 74.9271 29.1832 74.9271 29.7071V50.3484H74.932ZM62.8723 34.5814C60.1289 34.5286 57.5744 36.9176 57.6277 40.071C57.5744 43.1331 59.9301 45.6183 62.8723 45.5606C65.8145 45.6135 68.2817 42.9696 68.2284 40.071C68.2817 37.1724 65.8145 34.5238 62.8723 34.5814Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M79.0811 23.2898C79.0811 22.7659 79.43 22.4727 79.939 22.4727H84.9073C85.4017 22.4727 85.7458 22.7995 85.7458 23.2898V50.3099C85.7458 50.8003 85.4356 51.1271 84.9073 51.1271H79.939C79.3719 51.1271 79.0811 50.8531 79.0811 50.3099V23.2898Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M106.419 43.8397C106.618 43.8397 106.802 43.931 106.933 44.0945L109.182 47.1757C109.366 47.4113 109.458 47.6084 109.458 47.8103C109.458 48.0074 109.347 48.19 109.148 48.3535C107.776 49.5504 106.424 50.3628 105.071 50.8194C103.719 51.2713 102.275 51.4876 100.738 51.4876C98.7459 51.4876 96.8652 50.9781 95.0911 49.9494C93.3171 48.9159 91.9114 47.5411 90.8499 45.8009C89.7884 44.0608 89.2601 42.1573 89.2601 40.1095C89.1486 33.9277 94.0296 28.6015 100.554 28.7265C102.09 28.7265 103.661 29.1255 105.27 29.9235C106.86 30.7407 108.212 31.9905 109.308 33.6729C110.403 35.3602 110.951 37.4608 110.951 39.9605C110.898 41.864 110.364 42.1188 108.338 42.1524H95.6922C96.3126 44.4886 98.3436 45.9932 100.699 45.9932C102.473 45.9932 103.661 45.6327 104.373 45.1953C104.722 44.9982 105.105 44.705 105.522 44.3637C105.721 44.2002 105.871 44.0752 105.963 44.0031C106.089 43.8926 106.254 43.8397 106.419 43.8397ZM96.0363 37.1916H104.897C103.947 35.1439 102.376 33.9854 100.345 33.9854C98.4502 33.9806 96.841 35.3025 96.0363 37.1916Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M31.9282 55.0688C31.9282 54.6987 32.1221 54.5208 32.5147 54.5208H41.4189C44.492 54.4583 47.2354 56.9483 47.1627 59.7797C47.1627 61.0006 46.741 62.0678 45.9607 62.8946C47.2597 63.9762 48.0595 65.548 48.0595 67.1632C48.1273 70.033 45.2772 72.7153 42.2041 72.6383H32.5147C32.1221 72.6383 31.9282 72.4605 31.9282 72.1048V55.0688ZM37.1146 61.664H39.9648C41.1814 61.664 42.0345 60.9141 42.0345 59.8998C42.0345 58.9192 41.1959 58.1357 40.0084 58.1357H37.1146V61.664ZM37.1146 69.0235H40.7064C41.797 69.0235 42.7761 68.0717 42.7761 66.9901C42.7761 66.0095 41.8697 64.9424 40.7064 64.9424H37.1146V69.0235Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M50.9677 60.9046C51.7626 59.6836 52.829 58.7174 54.1813 57.9964C55.5385 57.2849 57.0072 56.9292 58.6116 56.9292C60.2209 56.9292 61.6992 57.2849 63.0564 57.9964C64.4136 58.7222 65.4751 59.6884 66.2701 60.9046C67.065 62.1352 67.4576 63.4715 67.4576 64.9088C67.4576 66.3557 67.065 67.692 66.2701 68.913C65.4751 70.1436 64.3991 71.1098 63.0419 71.8213C61.6847 72.5471 60.2063 72.9028 58.6116 72.9028C57.0024 72.9028 55.5385 72.5471 54.1813 71.8213C52.8241 71.1098 51.7626 70.1436 50.9677 68.913C50.1728 67.692 49.7802 66.3605 49.7802 64.9088C49.7802 63.4571 50.1679 62.1207 50.9677 60.9046ZM58.6116 68.7784C60.8607 68.8169 62.5378 66.9374 62.5136 64.904C62.5572 62.8466 60.8898 61.0055 58.6262 61.0392C56.3625 61.0007 54.6564 62.8418 54.7 64.904C54.6564 66.9614 56.348 68.8169 58.6116 68.7784Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M70.0605 53.1268C70.0605 52.7567 70.3271 52.5548 70.7198 52.5548H74.5199C74.898 52.5548 75.1646 52.7855 75.1646 53.1268V72.0712C75.1646 72.4125 74.9271 72.6432 74.5199 72.6432H70.7198C70.2884 72.6432 70.0605 72.4509 70.0605 72.0712V53.1268Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M77.7772 69.759C77.7772 69.5811 77.8742 69.4033 78.0583 69.2398L79.9439 67.5622C80.0554 67.4612 80.1814 67.4084 80.3074 67.4084C80.4625 67.4084 80.6273 67.4853 80.8261 67.6391C81.9845 68.5669 83.0897 69.0235 84.1124 69.0235C85.4405 69.0235 85.9301 68.6149 85.9301 67.9419C85.8477 67.2065 85.5956 67.0671 83.2884 66.4422C79.8372 65.5288 78.3249 63.9666 78.3249 61.2074C78.3249 58.6789 80.7 56.8474 84.728 56.8474C86.5747 56.8474 87.9562 57.3666 88.7656 57.929C89.1728 58.2078 89.6478 58.5875 90.2052 59.0586C90.4137 59.2221 90.5106 59.3759 90.5106 59.5153C90.5106 59.6547 90.3991 59.8181 90.1907 59.9864L88.1355 61.6111C87.9659 61.7361 87.8302 61.8034 87.7041 61.8034C87.5781 61.8034 87.4375 61.7409 87.2582 61.6015C86.6426 61.1449 86.376 60.943 85.8186 60.7651C85.5375 60.6738 85.2175 60.6401 84.854 60.6401C83.8458 60.6401 83.2884 60.9718 83.2884 61.39C83.3708 62.0149 83.6083 62.0774 85.2757 62.5341C85.9349 62.6975 86.4342 62.8369 86.7832 62.9523C88.4166 63.4474 89.5363 64.1204 90.1519 64.9712C90.753 65.8365 91.0583 66.8267 91.0583 67.9323C91.1262 71.0088 87.8835 73.0037 84.5147 72.9652C81.9554 72.9652 79.7597 72.2778 78.0971 70.3599C77.8887 70.1387 77.7772 69.9513 77.7772 69.759Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: 'white' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M111.489 72.0952C111.489 72.4653 111.295 72.6432 110.888 72.6432H107.825C107.335 72.6432 107.127 72.1865 106.831 71.4847L106.652 71.0521C105.406 72.2346 103.801 72.8932 102.023 72.8932C100.501 72.8932 99.0852 72.5519 97.7716 71.8789C96.4435 71.2059 95.3965 70.2637 94.5967 69.0572C93.8018 67.8506 93.4092 66.4662 93.4092 64.9135C93.4092 63.4522 93.7873 62.1159 94.5579 60.8853C95.3141 59.6643 96.3465 58.6981 97.6601 57.9771C98.9737 57.2656 100.428 56.9099 102.023 56.9099C104.034 56.9099 105.672 57.6454 106.904 58.9673L107.437 57.6213C107.606 57.1743 107.689 57.0734 108.038 57.0734H110.888C111.295 57.0734 111.489 57.2512 111.489 57.6213V72.0952ZM102.265 61.0439C100.166 61.0055 98.2127 62.6831 98.2515 64.8943C98.2078 67.043 100.011 68.7832 102.265 68.7447C104.514 68.7832 106.404 66.9277 106.361 64.8943C106.404 62.861 104.514 61.0055 102.265 61.0439Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#FFA944' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M126.282 10.3494C127.422 10.9743 128.115 12.3347 127.93 13.6182C127.737 14.9497 126.617 16.0938 125.284 16.3245C122.279 16.8436 120.287 13.099 122.405 10.931C123.35 9.96484 124.935 9.69084 126.152 10.2821C126.195 10.3013 126.239 10.3254 126.282 10.3494Z"
      />
      <motion.path
        layout
        variants={{ ...icon, visible: { ...icon.visible, fill: '#FFA944' } }}
        initial="hidden"
        animate="visible"
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
        d="M23.1793 76.9887C21.2646 75.7245 19.6602 73.9988 18.5842 71.9799C16.8295 68.6823 16.5145 64.6588 17.7359 61.1353C17.9734 60.4431 18.3903 59.6788 19.1174 59.5682C19.8881 59.4528 20.5618 60.2027 20.6878 60.967C20.8187 61.7313 20.5715 62.5005 20.4115 63.2552C19.4664 67.6872 21.6669 72.6 25.6125 74.8737C26.359 75.3063 27.1975 75.6764 27.6725 76.3927C28.1282 77.0705 28.0409 78.4404 27.1006 78.6808C26.2281 78.9019 24.6625 77.8732 23.9451 77.4646C23.6834 77.3108 23.4265 77.1522 23.1793 76.9887Z"
      />
    </motion.svg>
  )
}

export default Seal
