import { Box, Flex, Avatar, Heading, Badge } from '@chakra-ui/react'

function Ranking({ winner, position, data, points, displayPoints }) {
  return (
    <Box pos="relative" mt={4} mb={8}>
      <Box
        pos="absolute"
        w="5px"
        h="calc(100% + 40px)"
        zIndex="3"
        bg="gray.100"
        left="22px"
      >
        <Box pos="absolute" bg="white" w="100%" height="9px" bottom="18px" />
        <Box pos="absolute" bg="white" w="100%" height="5px" bottom="5px" />
      </Box>
      {data?.map((rank, idx) => {
        const colorOpacity = 100 * idx

        return (
          <Flex
            key={`rank-${idx}`}
            pos="relative"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            zIndex="6"
            mb={4}
            p={winner && Number(rank.position) === Number(position) && 3}
            w={
              winner && Number(rank.position) === Number(position)
                ? 'calc(100% + 25px)'
                : '100%'
            }
            bg={
              winner &&
              Number(rank.position) === Number(position) &&
              'orange.100'
            }
            ml={
              winner && Number(rank.position) === Number(position) && '-12.5px'
            }
          >
            <Avatar
              name={`${rank.position} º`}
              mr={3}
              bg={
                winner && Number(rank.position) === Number(position)
                  ? 'green.400'
                  : `green.${500 - colorOpacity}`
              }
              fontFamily="Lato"
              color="white"
            />
            <Heading
              size="md"
              mr={['auto', 'auto', '100px']}
              w={['90px', '90px', '160px']}
              isTruncated
            >
              {winner && Number(rank.position) === Number(position)
                ? 'Você'
                : rank.user.first_name}
            </Heading>
            {displayPoints === 1 && (
              <Badge
                d={['none', 'none', 'block']}
                fontSize="16px"
                p="5px 10px"
                fontFamily="Lato"
                fontWeight="400"
                bg="white"
                mr="auto"
              >
                {rank.points} pontos
              </Badge>
            )}
            <Badge
              w="160px"
              textAlign="center"
              fontSize={['14px', '14px', '18px']}
              p={['3px', '3px', '5px 10px']}
              fontFamily="Lato"
              mr={!displayPoints && 'auto'}
              bg={
                winner && Number(rank.position) === Number(position)
                  ? 'orange.300'
                  : 'orange.200'
              }
            >
              Bolsa de {rank.scholarship}
            </Badge>
          </Flex>
        )
      })}
      {!winner && (
        <>
          <Box
            pos="absolute"
            w="100%"
            h="220px"
            bottom="90px"
            zIndex="99"
            bgGradient="linear(to-b, transparent, white)"
          />
          <Box h="60px" />
          <Flex
            pos="relative"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            zIndex="6"
          >
            {displayPoints === 1 && (
              <Avatar
                name={`${`${position}`.charAt(0)} ${`${position}`.charAt(1)}`}
                fontFamily="Lato"
                mr={3}
                bg="red.300"
                color="white"
              />
            )}
            {displayPoints === 0 && (
              <Avatar
                name={' '}
                fontFamily="Lato"
                mr={3}
                bg="red.300"
                color="white"
              />
            )}
            <Heading size="md" mr="100px" w="160px" isTruncated>
              Você
            </Heading>
            <Badge
              fontSize="16px"
              p="5px 10px"
              fontFamily="Lato"
              fontWeight="400"
              bg="white"
            >
              {points} pontos
            </Badge>
            <Badge
              w="160px"
              textAlign="center"
              fontSize="14px"
              p="5px 10px"
              fontFamily="Lato"
              mr={!displayPoints && 'auto'}
              bg={'orange.200'}
            >
              Não classificado
            </Badge>
          </Flex>
        </>
      )}
    </Box>
  )
}

export default Ranking
