import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

import bgSrc from '@/assets/images/bg.svg'

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
const buttonVariants = {
  initial: { y: -15, opacity: 0, transition: { delay: 1.0 } },
  enter: { y: 0, opacity: 1, transition: { ...transition, delay: 0.5 } },
  exit: {
    y: 15,
    opacity: 0,
    transition,
    duration: 0.5
  }
}

export const Page = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgSrc});
  background-size: cover;

  min-height: 100vh;

  input,
  select {
    width: 100%;
    border-radius: 4px;

    background: rgba(0, 0, 0, 0.05);
  }

  .custom-input {
    padding: 10px;
    margin-bottom: 10px;
  }
`

export const Overlay = styled.div`
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 135px 0 35px 0;

  ${media.lessThan('medium')`
    padding: 25px;
  `}
`

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: 320px calc(100% - 360px);
  grid-gap: 40px;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: stretch;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 320px;
  max-height: 780px;

  padding: 20px;
  background: #e8f5f787;
  border-radius: 10px;

  ${media.lessThan('medium')`
    margin-top: 20px;
    width: 100%;
  `}
`

export const CardImage = styled.div`
  max-width: 100%;
  margin-top: 30px;
`

export const CardStep = styled(motion.div)`
  background-color: var(--primary);
  color: #fff;

  width: 50px;
  height: 50px;
  border-radius: 50px;

  font-size: 30px;
  font-weight: 300;

  line-height: 45px;
  text-align: center;
`

export const CardTitle = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: var(--title-text);
  margin: 10px 0 20px 0;

  ${media.lessThan('medium')`
    font-size: 18px;
    line-height: 1.2;
  `}
`

export const CardDescription = styled.div`
  width: 100%;
  font-size: 16px;
  text-align: justify;
  color: var(--title-text);

  ${media.lessThan('medium')`
    font-size: 14px;
    line-height: 1.2;
  `}
`

export const Form = styled.div``

export const Heading = styled.h1`
  font-weight: 300;
  font-size: 38px;
  line-height: 1.1;
  color: var(--title-text);
  margin-bottom: 30px;

  ${media.lessThan('medium')`
    font-size: 28px;
    line-height: 1.2;
  `}
`

export const Button = styled(motion.button).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants: buttonVariants
})`
  background-color: var(--button-action);
  padding: 10px 25px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  transition: all ease 0.3s;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #c8c8c8;
    color: #000;
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 2%;

  width: 100%;

  margin: 25px 0;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  `}
`
