import { MoipCreditCard, MoipValidator } from 'moip-sdk-js'
import JSEncrypt from 'node-jsencrypt'

import {
  CreditCardDataValidation,
  GenerateCreditCardHashResponse,
  ICreditCardData
} from '@/protocols/Moip.protocol'
import { MoipConfig } from '@/config/Moip.config'

class MoipService {
  async isValidNumber(creditCardNumber: string | null): Promise<boolean> {
    if (!creditCardNumber) return false

    return MoipValidator.isValidNumber(creditCardNumber)
  }

  async isValidCVC(
    creditCardNumber: string | null,
    cvc: string | null
  ): Promise<boolean> {
    if (!creditCardNumber || !cvc) return false

    return MoipValidator.isSecurityCodeValid(creditCardNumber, cvc)
  }

  async isExpiryDateValid(
    month: string | null,
    year: string | null
  ): Promise<boolean> {
    if (!month || !year) return false

    return MoipValidator.isExpiryDateValid(month, year)
  }

  async creditCardDataIsValid(
    creditCardData: ICreditCardData
  ): Promise<CreditCardDataValidation> {
    const dataValid = {
      isValid: true,
      fieldError: []
    } as CreditCardDataValidation

    if (!creditCardData?.holderName) {
      dataValid.isValid = false
      dataValid.fieldError.push({
        holderName: 'invalid_credit_card_holder_name'
      })
    }

    const validNumber = await this.isValidNumber(creditCardData?.cardNumber)

    if (!validNumber) {
      dataValid.isValid = false
      dataValid.fieldError.push({
        cardNumber: 'invalid_credit_card_number'
      })
    }

    const validCVC = await this.isValidCVC(
      creditCardData?.cardNumber,
      creditCardData?.cvc
    )

    if (!validCVC) {
      dataValid.isValid = false
      dataValid.fieldError.push({
        cvc: 'invalid_credit_card_cvc'
      })
    }

    const validExpiryDateValid =
      creditCardData?.expiryDate &&
      (await this.isExpiryDateValid(
        creditCardData.expiryDate.substr(0, 2),
        creditCardData.expiryDate.substr(2, 3)
      ))

    if (!validExpiryDateValid) {
      dataValid.isValid = false
      dataValid.fieldError.push({
        expiryDate: 'invalid_credit_card_expiry_date'
      })
    }

    return dataValid
  }

  async generateCreditCardHash(
    creditCardData: ICreditCardData
  ): Promise<GenerateCreditCardHashResponse> {
    try {
      const dataIsValid = await this.creditCardDataIsValid(creditCardData)

      if (!dataIsValid.isValid) {
        return dataIsValid
      }
      const hash = await MoipCreditCard.setEncrypter(JSEncrypt, 'node')
        .setPubKey(MoipConfig.publicKey)
        .setCreditCard({
          number: creditCardData.cardNumber,
          cvc: creditCardData.cvc,
          expirationMonth: creditCardData.expiryDate?.substr(0, 2),
          expirationYear: creditCardData.expiryDate?.substr(2, 3)
        })
        .hash()
      return {
        isValid: true,
        hash: hash
      }
    } catch (error) {
      return {
        isValid: false,
        fieldError: [{ creditCard: 'invalid_credit_card' }]
      }
    }
  }
}

export default new MoipService()
