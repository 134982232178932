import ApiService from '@/services/ApiService'

class SmsService {
  async validateMobilePhone(data: { confirmation_code?: string | null }) {
    return await ApiService.post('/user/sms-confirmation/validate', {
      confirmation_code: data?.confirmation_code
    })
  }

  async sendSmsConfirmation(data: { phone: string }) {
    return await ApiService.post('/user/sms-confirmation/send-sms', {
      phone: data?.phone
    })
  }

  async checkIfMobilePhoneIsConfirmed(user_id?: number): Promise<boolean> {
    try {
      const { data } = await ApiService.get('/user/sms-confirmation', {
        params: {
          user_id
        }
      })
      if (!data?.is_confirmed) return false

      return true
    } catch (error) {
      return false
    }
  }
}

export default new SmsService()
