import React, { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'

import {
  Container,
  Label,
  Input,
  Dropdown,
  Option
} from '@/styles/components/SuggestionInput'

function sortCities(a, b) {
  // console.log('sort ', a, b)
  if (a.city < b.city) {
    return -1
  }
  if (a.city > b.city) {
    return 1
  }
  return 0
}

function SuggestionInput({
  label,
  data,
  onSelect,
  readyToSearch,
  debounce,
  nameToPrint,
  showOnFocus,
  reset
}) {
  const [value, setValue] = useState('')
  const [selected, setSelected] = useState(false)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (reset) {
      setValue('')
    }
  }, [reset])

  return (
    <Container>
      <Label>{label}</Label>
      {debounce ? (
        <DebounceInput
          value={value}
          debounceTimeout={500}
          minLength={3}
          onChange={e => {
            setValue(e.target.value)
            readyToSearch(e.target.value)

            if (selected) {
              setSelected(false)
            }
          }}
          element={Input}
        />
      ) : (
        <Input
          value={value}
          onChange={e => {
            setValue(e.target.value)
            readyToSearch(e.target.value)

            if (selected) {
              setSelected(false)
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setFocused(false)
            }, 200)
          }}
        />
      )}

      {((value.length > 0 && !selected) || (showOnFocus && focused)) && (
        <Dropdown>
          {data &&
            data.sort(sortCities).map((option, idx) => (
              <Option
                key={idx}
                onClick={() => {
                  onSelect(option)
                  setSelected(true)

                  setValue(option.name || option[nameToPrint])
                }}
              >
                {nameToPrint === 'city'
                  ? `${option.city} - ${option.state}`
                  : option.name || option[nameToPrint]}
              </Option>
            ))}
          {data?.length === 0 && nameToPrint === 'city' ? (
            <Option>
              Infelizmente não encontramos bolsas para esta cidade.
            </Option>
          ) : (
            nameToPrint === 'name' &&
            data.length === 0 && (
              <Option>
                Infelizmente não encontramos bolsas para este curso.
              </Option>
            )
          )}
        </Dropdown>
      )}
    </Container>
  )
}

export default SuggestionInput
