import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { duration: 0.5, ...transition, delay: 0 }
  }
}

const Overlay = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants
})`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(18, 34, 50, 0.85);

  z-index: 999;
`

const Container = styled(motion.div).attrs({
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    visible: { y: 0, opacity: 1 },
    hidden: { y: -20, opacity: 0 }
  },
  transition: {
    y: { type: 'spring', stiffness: 100 }
  }
})`
  position: relative;

  width: 100%;
  max-width: 520px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 22px;
  font-weight: 700;
  color: #293e52;

  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    font-size: 28px;
  }
`

const Message = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: #293e52;
  margin-bottom: 10px;
`

const Button = styled.button`
  position: relative;

  display: inline-block !important;

  color: ${({ close }) => (close ? '#3b5bfd' : '#fff')} !important;
  background: ${({ close }) =>
    close ? 'rgba(59, 91, 253, 0.1)' : 'var(--primary)'} !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  margin-right: 10px !important;
  border-radius: 3px !important;
  width: fit-content !important;
  cursor: pointer;
  transition: all ease 0.3s;

  z-index: 4;

  &:hover {
    opacity: 0.8;
  }
`

function AlreadyModal({ show, name, onClose }) {
  return (
    <AnimatePresence>
      {show && (
        <Overlay>
          <Container>
            <Title>Olá, {name}!</Title>
            <Message>Seu cadastro e inscrição já foi confirmada!</Message>
            {/* <Button onClick={onOpenSubscription}>Acompanhar inscrição</Button> */}
            <Button close onClick={onClose}>
              Fechar
            </Button>
          </Container>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default AlreadyModal
