import { ReactElement } from 'react'
import {
  Box,
  Text,
  List,
  ListItem,
  Skeleton,
  Flex,
  Image
} from '@chakra-ui/react'

import { BsCheck } from 'react-icons/bs'
import { BiTime, BiHomeSmile } from 'react-icons/bi'
import { MdAttachMoney } from 'react-icons/md'
import { AiOutlinePercentage } from 'react-icons/ai'

import colors from '@/styles/colors'
import { IScholarshipData } from '@/protocols/User.protocol'
const SubscriptionResume = (props: {
  data: IScholarshipData
  dataLoading: boolean
}) => {
  const { data, dataLoading } = props

  const InstitutionDetailsItem = (data: {
    icon: ReactElement
    text: string
  }) => (
    <ListItem>
      <Skeleton height="25px" isLoaded={!dataLoading}>
        <Flex
          alignItems="center"
          justifyContent={['flex-start']}
          fontSize={['14px', '14px', '16px']}
          fontWeight="400"
          flexWrap={['nowrap', 'nowrap', 'nowrap']}
        >
          <Box width={['auto']} mr={['8px']}>
            <Flex
              alignItems="center"
              justifyContent="center"
              w="25px"
              h="25px"
              bg="gray.400"
              borderRadius="25px"
              color="white"
              mx={['auto', 'auto', 0]}
              mb={[0]}
            >
              {data?.icon}
            </Flex>
          </Box>

          <Box width={['auto']}>
            <Text textAlign={'start'} color="inherit">
              {data?.text}
            </Text>
          </Box>
        </Flex>
      </Skeleton>
    </ListItem>
  )

  return (
    <Box d="flex" flexDir="row" alignItems="center" justifyContent="center">
      <Box w={['100%', '100%', '550px']} color="#fff">
        <Image
          maxWidth="210px"
          src={data?.institution?.logo}
          alt={data?.institution?.acronym}
        />

        <Text
          fontSize={['22px', '28px', '52px']}
          lineHeight="1.3"
          mr="16px"
          fontWeight="bold"
          mb={['25px', '25px', '30px']}
          color={colors.palette.primary}
        >
          {data?.course?.name}
        </Text>

        <List
          spacing={['12', 3, 3]}
          mb="41px"
          color={colors.palette.primary}
          fontWeight={500}
        >
          {InstitutionDetailsItem({
            icon: <BsCheck size={16} />,
            text: `Modalidade: ${data?.modality}`
          })}

          {InstitutionDetailsItem({
            icon: <BiTime size={16} />,
            text: `Turno: ${data?.shift}`
          })}

          {InstitutionDetailsItem({
            icon: <BiHomeSmile size={14} />,
            text: `Polo: ${data?.camp?.address}, ${data?.camp?.city} - ${data?.camp?.state}`
          })}

          {InstitutionDetailsItem({
            icon: <MdAttachMoney size={16} />,
            text: `Mensalidade: ${'R$'}
            ${
              data
                ? data?.price.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : '0,00'
            }`
          })}

          {InstitutionDetailsItem({
            icon: <AiOutlinePercentage size={16} />,
            text: ' Bolsa: Até 100%'
          })}
        </List>
      </Box>
    </Box>
  )
}

export default SubscriptionResume
