import React, { useState, ReactElement } from 'react'
import {
  Box,
  Container,
  Flex,
  Text,
  Image,
  List,
  ListItem,
  Button
} from '@chakra-ui/react'
import {
  MdAccountBalance as InstitutionIcon,
  MdRoom as PoloIcon,
  MdCastForEducation as ModalityIcon,
  MdQueryBuilder as ShiftIcon,
  MdAccountBalanceWallet as PriceIcon,
  MdSchool as OfferIcon,
  MdAssistant as CourseIcon
} from 'react-icons/md'
import { useHistory, useLocation } from 'react-router-dom'

import SyncLoader from 'react-spinners/SyncLoader'

import successSrc from '@/assets/images/success.png'
import valeBolsaLogo from '@/assets/vale-bolsa-logo.svg'

import useForm from '@/contexts/hooks/use-form'
import ApiService from '@/services/ApiService'

import colors from '@/styles/colors'
import { ButtonHighlightAction, Divider, Portlet } from '@/components/Elements'
import useDidMount from '@/contexts/hooks/useDidMount'
import { useGlobalStateStore } from '@/store/GlobalState'

const Confirmation = () => {
  const history = useHistory()
  const location = useLocation()
  const { user: userData } = useGlobalStateStore()

  const [loading, setLoading] = useState(false)

  const {
    scholarship,
    firstName,
    lastName,
    cpf,
    phone,
    birthDate,
    genre,
    setSuccess
  } = useForm() as any
  async function subscribe() {
    setLoading(true)
    try {
      await ApiService.post('subscription', {
        scholarshipId: scholarship?.id,
        email: userData?.email,
        firstName,
        lastName,
        cpf,
        phone,
        birthDate: birthDate.split('/').reverse().join('-'),
        genre
      })

      setSuccess(true)
      history.push({
        pathname: '/inscricao/concluido',
        search: location.search
      })
    } catch (err) {
      console.log(err)
      alert('Ocorreu um problema')
    }
    setLoading(false)
  }

  const InstitutionDetailsItem = (data: {
    icon: ReactElement
    text: string | ReactElement
  }) => (
    <ListItem>
      <Flex
        alignItems="center"
        justifyContent={['flex-start']}
        fontSize={['14px', '14px', '16px']}
        fontWeight="400"
        flexWrap={['nowrap', 'nowrap', 'nowrap']}
      >
        <Box width={['auto']} mr={['8px']}>
          <Flex
            alignItems="center"
            justifyContent="center"
            w="25px"
            h="25px"
            bg="gray.400"
            borderRadius="25px"
            color="white"
            mx={['auto', 'auto', 0]}
            mb={[0]}
          >
            {data?.icon}
          </Flex>
        </Box>

        <Box width={['auto']}>
          <Text textAlign={'start'} color="inherit">
            {data?.text}
          </Text>
        </Box>
      </Flex>
    </ListItem>
  )

  useDidMount(() => {
    if (!scholarship) {
      history.push('/inscricao/etapa/1')
    }
  })

  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={['100%', '100%', '100vh']}
      width="90vw"
      maxWidth="980px"
      mt={['24px', '24px', 0]}
      padding={0}
    >
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        gridGap={[0, 0, '12px']}
        width="100%"
      >
        <Box
          padding={['12x 8px', '12x 8px', 0]}
          width={['100%', '100%', '40%']}
          position="relative"
        >
          <Flex justifyContent={['center', 'center', 'start']}>
            <Image
              src={valeBolsaLogo}
              height={['54px', '54px', '64px']}
              alt="Vale Bolsa - Logo"
            />
          </Flex>

          <Box
            display={['none', 'none', 'block']}
            position="absolute"
            bottom="0px"
          >
            <Image
              src={successSrc}
              height="60vh"
              maxHeight="420px"
              alt="Foguete decolando"
            />
          </Box>
        </Box>

        <Box width={['100%', '100%', '60%']} mt={['28px', '28px', '8px']}>
          <Portlet background="#F7FEFF">
            <Text textStyle="h4" color={colors.palette.primary}>
              Confirme os dados abaixo
            </Text>

            <Divider size={4} orientation="horizontal" />
            <List
              spacing={['12', 3, 3]}
              mb="41px"
              color={colors.palette.primary}
              fontWeight={500}
            >
              {InstitutionDetailsItem({
                icon: <InstitutionIcon size={16} />,
                text: `Faculdade: ${
                  scholarship?.institution.acronym ||
                  scholarship?.institution.name
                }`
              })}

              {InstitutionDetailsItem({
                icon: <PoloIcon size={16} />,
                text: `Polo: ${scholarship?.camp.address}`
              })}

              {InstitutionDetailsItem({
                icon: <CourseIcon size={16} />,
                text: `Curso: ${scholarship?.course.name}`
              })}

              {InstitutionDetailsItem({
                icon: <ModalityIcon size={16} />,
                text: `Modalidade: ${scholarship?.modality}`
              })}

              {InstitutionDetailsItem({
                icon: <ShiftIcon size={16} />,
                text: `Turno: ${scholarship?.shift}`
              })}

              {InstitutionDetailsItem({
                icon: <PriceIcon size={16} />,
                text: (
                  <>
                    <b>Valor do curso:</b> R$
                    <span style={{ textDecoration: 'line-through' }}>
                      {Number(scholarship?.price).toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                  </>
                )
              })}

              {InstitutionDetailsItem({
                icon: <OfferIcon size={16} />,
                text: (
                  <b>
                    Você está concorrendo a uma bolsa de até{' '}
                    {scholarship?.discount}%
                  </b>
                )
              })}
            </List>

            <Divider size={4} orientation="horizontal" />

            <Button
              colorScheme="blackAlpha"
              onClick={() => {
                history.push({
                  pathname: '/inscricao/etapa/2',
                  search: location.search
                })
              }}
            >
              Trocar bolsa
            </Button>

            <Divider size={4} orientation="horizontal" />

            <Text
              color={colors.palette.primary}
              textStyle="caption"
              fontWeight={500}
            >
              Ao continuar, você permite que o Beduka e parceiros entrem em
              contato.
            </Text>

            <Divider size={4} orientation="horizontal" />

            {loading ? (
              <SyncLoader
                color="rgba(0, 0, 0, 0.4)"
                loading={loading}
                size={10}
              />
            ) : (
              <ButtonHighlightAction onClick={subscribe}>
                Finalizar inscrição
              </ButtonHighlightAction>
            )}
          </Portlet>
        </Box>
      </Flex>
    </Container>
  )
}

export default Confirmation
