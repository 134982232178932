import { Box, Heading, AspectRatio, Center, SlideFade } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'

const About = () => {
  const { ref, inView } = useInView({
    delay: 500
  })
  return (
    <Box
      as="section"
      py={['60px', '60px', '2px']}
      pb={['20px', '20px', '60px']}
      px={['20px', '20px', '0']}
      bg="white"
    >
      <Box w="100%" maxW="1117px" m="0 auto">
        <Heading
          textAlign="center"
          color="#0B1880"
          fontWeight="bold"
          fontSize={['32px', '32px', '36px']}
          mb={['26px', '26px', '48px']}
        >
          O que é o Vale Bolsa Beduka?
        </Heading>
        <Center>
          <Box
            p="16px"
            w="100%"
            maxW="820px"
            bg="#71D1DB"
            borderRadius="3px"
            position="relative"
          >
            <SlideFade in={inView}>
              <AspectRatio ref={ref} ratio={16 / 9}>
                <iframe
                  type="text/html"
                  src="https://www.youtube.com/embed/Cn33n64QO_A?autoplay=1&origin=httsp://vale-bolsa.beduka.com&modesbranding=0&autohide=0&showinfo=0&controls=0&rel=0&amp"
                  frameBorder="0"
                  allowFullScreen
                />
              </AspectRatio>
            </SlideFade>
          </Box>
        </Center>
      </Box>
    </Box>
  )
}

export default About
