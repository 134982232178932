import { useState } from 'react'
import { Box, Flex, useToast } from '@chakra-ui/react'
import SubscriptionResume from '@/components/Elements/SubscriptionResume'

import { useGlobalStateStore } from '@/store/GlobalState'
import ProfileLayout from '@/components/Layouts/Profile'
import { Divider, Loading } from '@/components/Elements'

import ChangeCourse from '@/pages/MyAccount/components/changeCourse'
import OrderStatus from '@/pages/MyAccount/components/orderStatus'
import useDidMount from '@/contexts/hooks/useDidMount'
import UserService from '@/services/UserService'

const MyAccount = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const toast = useToast()
  const { user, order, setUserData, setOrderData } = useGlobalStateStore()
  const subscriptionData = user?.subscriptions[0]?.scholarship

  const getUserData = async () => {
    try {
      const userInfo = await UserService.getInfo()

      if (userInfo?.userData) {
        setUserData(userInfo?.userData)
      }

      if (userInfo?.orderData) {
        setOrderData(userInfo?.orderData)
      }
    } catch (error) {
      toast({
        title: 'Tente novamente...',
        description: 'Erro ao tentar carregar as informações',
        status: 'error'
      })
    }
    setLoading(false)
  }

  const initialize = async () => {
    setLoading(true)
    try {
      await getUserData()
    } catch (error) {
      toast({
        title: 'Tente novamente...',
        description: 'Erro ao tentar carregar as informações...',
        status: 'error'
      })
    }
    setLoading(false)
  }

  useDidMount(() => {
    initialize()
  })

  return (
    <ProfileLayout>
      <Loading loading={loading}>
        <Flex
          direction={['column', 'column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          padding="24px 24px"
          gridGap={'12px'}
        >
          {order?.status ? (
            <Box width={['100%', '100%', '100%', '80%']}>
              <OrderStatus />
            </Box>
          ) : (
            <Box
              mt={['24px', '24px', '24px', '18px']}
              width={['100%', '100%', '100%', 'auto']}
              height={'fit-content'}
            >
              <SubscriptionResume
                data={subscriptionData}
                dataLoading={loading}
              />

              <Divider size={3} orientation="horizontal" />

              {subscriptionData?.campaign.canChangeCourse && (
                <ChangeCourse
                  data={subscriptionData}
                  dataLoading={loading}
                  onUpdate={getUserData}
                  needLoading={() => setLoading(true)}
                />
              )}
            </Box>
          )}
        </Flex>
      </Loading>
    </ProfileLayout>
  )
}

export default MyAccount
