import React, { useState, useEffect } from 'react'
import { Flex } from '@chakra-ui/react'

import { NumberUtils } from '@/utils'
import BoxNumber from './components/boxNumber.component'

type CountdownProps = {
  expirationDate: Date
}

const Countdown: React.FC<CountdownProps> = props => {
  const { expirationDate } = props

  const [, setSeconds] = useState(0)

  const getCountdownData = () => {
    const countdownData = {
      daysCountdown: '0',
      hoursCountdown: '0',
      minutesCountdown: '0',
      secondsCountdown: '0'
    }

    if (expirationDate) {
      const expirationDateTime = new Date(expirationDate).getTime()
      const currentDateTime = new Date().getTime()

      const differenceDate = expirationDateTime - currentDateTime

      if (differenceDate < 0) return countdownData

      const differenceDataInSeconds = Math.floor(differenceDate / 1000)
      const differenceDataInMinutes = Math.floor(differenceDataInSeconds / 60)
      const differenceDataInHours = Math.floor(differenceDataInMinutes / 60)
      const differenceDataInDays = Math.floor(differenceDataInHours / 24)

      const daysCountdown = differenceDataInDays

      const hoursCountdown = differenceDataInHours - daysCountdown * 24

      const minutesCountdown =
        differenceDataInMinutes -
        differenceDataInDays * 24 * 60 -
        hoursCountdown * 60

      const secondsCountdown =
        differenceDataInSeconds -
        daysCountdown * 24 * 60 * 60 -
        hoursCountdown * 60 * 60 -
        minutesCountdown * 60

      return {
        daysCountdown: NumberUtils.zeroPad(daysCountdown),
        hoursCountdown: NumberUtils.zeroPad(hoursCountdown),
        minutesCountdown: NumberUtils.zeroPad(minutesCountdown),
        secondsCountdown: NumberUtils.zeroPad(secondsCountdown)
      }
    }

    return countdownData
  }

  const countdownData = getCountdownData()

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Flex justifyContent="center" gridGap="24px" flexWrap="wrap">
      <Flex
        width={['46%', '22.5%']}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <BoxNumber value={countdownData.daysCountdown} label="Dia" />
      </Flex>

      <Flex
        width={['46%', '22.5%']}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <BoxNumber value={countdownData.hoursCountdown} label="Hora" />
      </Flex>

      <Flex
        width={['46%', '22.5%']}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <BoxNumber value={countdownData.minutesCountdown} label="Minuto" />
      </Flex>

      <Flex
        width={['46%', '22.5%']}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <BoxNumber value={countdownData.secondsCountdown} label="Segundo" />
      </Flex>
    </Flex>
  )
}

export default Countdown
