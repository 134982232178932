import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  position: relative;
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
`

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ebebed;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px 0 15px 0;
  transition: all ease 0.3s;

  &:focus {
    border-color: var(--primary);
  }
`

export const Dropdown = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;

  position: absolute;
  left: 0;
  top: 87px;

  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 999;

  background: #ffffff;

  border: 1px solid #ebebed;
  box-sizing: border-box;
  border-radius: 4px;

  box-shadow: 0 70px 45px -40px rgba(0, 0, 0, 0.1),
    0 10px 30px -5px rgba(0, 0, 0, 0.175);
`

export const Option = styled(motion.li)`
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`
