import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext({})

function AuthProvider({ children }) {
  const [token, setToken] = useState()

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('BedukaToken', token)
    } else {
      const sessionToken = sessionStorage.getItem('BedukaToken')

      if (sessionToken) {
        setToken(sessionToken)
      }
    }
  }, [token])

  function signOut() {
    setToken(null)
    sessionStorage.removeItem('BedukaToken')
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
