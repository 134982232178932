import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  Text,
  Image,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FaWhatsapp } from 'react-icons/fa'
import nl2br from 'react-nl2br'

import Ranking from './Ranking'

import ApiService from '@/services/ApiService'

function ResultModal({
  isOpen,
  onClose,
  result,
  scholarshipData,
  subscriptionId
}) {
  const {
    display_points: displayPoints,
    // notice_message: noticeMessage,
    winner,
    position,
    rank,
    points,
    scholarship
  } = result
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal
  } = useDisclosure()

  const toast = useToast()

  const [scoreLoading, setScoreLoading] = useState(false)
  const [couponLoading, setCouponLoading] = useState(false)

  async function getScore() {
    setScoreLoading(true)

    try {
      const {
        data: { url }
      } = await ApiService.get(`subscription/${subscriptionId}/grade`)

      window.open(url)
    } catch (err) {
      toast({
        title: 'Oops...',
        description: err.response.data
          ? err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }

    setScoreLoading(false)
  }

  async function getCoupon() {
    setCouponLoading(true)

    try {
      const {
        data: { url }
      } = await ApiService.get(`subscription/${subscriptionId}/coupon`)

      window.open(url)
    } catch (err) {
      toast({
        title: 'Oops...',
        description: err.response.data
          ? err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }

    setCouponLoading(false)
  }

  return (
    <>
      <Modal isOpen={isOpenModal} onClose={onCloseModal} size="2xl" p={5}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Próximos passos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {scholarshipData ? (
              scholarshipData.institution.enrollment_instructions ? (
                <p>
                  {nl2br(scholarshipData.institution.enrollment_instructions)}
                </p>
              ) : (
                <p>
                  Instruções não definidas, entre em contato conosco pelo
                  WhatsApp
                </p>
              )
            ) : (
              <p>
                Instruções não definidas, entre em contato conosco pelo WhatsApp
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              cursor="pointer"
              isLoading={scoreLoading}
              disabled={scoreLoading}
              onClick={getScore}
            >
              Histórico de Notas
            </Button>
            <Button
              colorScheme="blue"
              cursor="pointer"
              isLoading={couponLoading}
              disabled={couponLoading}
              onClick={getCoupon}
              ml={4}
            >
              Cupom de Desconto
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" p={5}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resultado do Vale Bolsa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {winner ? (
              <Box p={6} bg="green.100" borderRadius={4} mb="35px">
                <Heading size={['md', 'md', 'lg']} color="green.500" mb={2}>
                  Você foi aprovado!
                </Heading>
                <Text fontSize={['14px', '14px', '18px']}>
                  Parabéns, você acaba de conquistar uma bolsa de{' '}
                  <b>{scholarship}</b> no curso de escolhido.
                </Text>
              </Box>
            ) : (
              <Box p={6} bg="red.100" borderRadius={4} mb="35px">
                <Heading size={['md', 'md', 'lg']} color="red.500" mb={2}>
                  Não foi dessa vez, mas não fique triste.
                </Heading>
                <Text fontSize={['14px', '14px', '18px']}>
                  Infelizmente você não atingiu a nota necessária para
                  conquistar essa bolsa. Mas não fique triste, nós temos uma{' '}
                  <b>ótima oportunidade</b> para você! <br /> <br />
                  Clique no <b>botão do whatsapp</b> que nosso time irá lhe
                  ajudar a entrar na faculdade com uma excelente condição
                </Text>
              </Box>
            )}
            <Image
              src={scholarshipData.institution.logo}
              alt="Vale Bolsa - Universidade"
              maxW="300px"
              mb="35px"
            />
            <Text color="gray.700" fontSize={['14px', '14px', '16px']}>
              <b>Curso:</b> {scholarshipData ? scholarshipData.course.name : ''}
            </Text>
            <Text color="gray.700" fontSize={['14px', '14px', '16px']}>
              <b>Cidade:</b> {scholarshipData.camp.city}
            </Text>
            <Text color="gray.700" fontSize={['14px', '14px', '16px']}>
              <b>Polo:</b>{' '}
              {scholarshipData
                ? `${scholarshipData.camp.address}, ${scholarshipData.camp.city} - ${scholarshipData.camp.state}`
                : ''}
            </Text>
            <Text color="gray.700" fontSize={['14px', '14px', '16px']}>
              <b>Modalidade / Turno:</b>{' '}
              {scholarshipData ? scholarshipData.modality : ''}{' '}
              {scholarshipData ? scholarshipData.shift : ''}
            </Text>
            <br />
            <Heading size="sm" textTransform="uppercase" color="gray.700">
              Classificação
            </Heading>
            <Ranking
              data={rank}
              winner={winner}
              points={points}
              position={position}
              displayPoints={displayPoints}
            />
          </ModalBody>
          <ModalFooter>
            {result.winner ? (
              <Button
                size={['md', 'md', 'lg']}
                p={['10px', '10px', '15px']}
                colorScheme="blue"
                cursor="pointer"
                onClick={onOpenModal}
              >
                Próximos passos
              </Button>
            ) : (
              <>
                <Button
                  size={['md', 'md', 'lg']}
                  p={['10px', '10px', '15px']}
                  bg="gray.200"
                  color="gray.700"
                  mr={3}
                  cursor="pointer"
                  _hover={{ bg: 'gray.300' }}
                  onClick={getScore}
                  isLoading={scoreLoading}
                  disabled={scoreLoading}
                >
                  Histórico de Notas
                </Button>
                <Button
                  size={['md', 'md', 'lg']}
                  p={['10px', '10px', '15px']}
                  bg="#25D366"
                  color="white"
                  mr={3}
                  cursor="pointer"
                  leftIcon={<FaWhatsapp />}
                  _hover={{ bg: 'gray.300' }}
                  onClick={() => {
                    window.open(
                      'https://api.whatsapp.com/send?phone=5581989583016',
                      '_blank'
                    )
                  }}
                >
                  Quero saber da oportunidade
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResultModal
