import { extendTheme, theme } from '@chakra-ui/react'

import colors from '@/styles/colors'

const overrides = {
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Poppins',
        color: 'gray.800',
        bg: 'white',
        lineHeight: 'base'
      }
    })
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  theme: {
    ...theme,
    shadows: {
      ...theme.shadows,
      outline: 'none'
    }
  },
  colors: {
    ...theme.colors,
    primary: {
      50: '#2133c2',
      100: '#10209c',
      500: '#0b1880',
      600: '#050f59'
    },
    secondary: {
      50: '#28f1fa',
      100: '#19dae3',
      500: '#12CFD8',
      600: '#0db2ba'
    },
    highlightAction: {
      50: '#ee99b6',
      100: '#ee71b6',
      500: '#ee3db6',
      600: '#ee0bb6'
    },
    palette: {
      primary: '#0B1880',
      primaryHover: '#384397',
      secondary: '#12CFD8',
      success: '#5fe09a',
      dark: '#2c3440',
      gray: '#b9bfc6',
      pink: '#ee3db6',
      purple: '#9b36dd',
      danger: '#d9534f',
      dangerHover: '#f26f6b',
      lightpurple: '#a39fe6',
      textWhite: '#f3f3f3'
    }
  },
  components: {
    Input: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'palette.primary'
      }
    },

    PinInput: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'palette.primary'
      }
    },
    Button: {
      defaultProps: {
        size: 'md'
      }
    }
  },
  textStyles: {
    h1: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: [
        'calc(.95rem * 1.8)',
        'calc(.95rem * 2)',
        'calc(.95rem * 2.5)',
        'calc(.95rem * 3)'
      ],
      lineHeight: ['30px', '42px'],
      color: colors.grayScale[1]
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: [
        'calc(.95rem * 1.7)',
        'calc(.95rem * 1.8)',
        'calc(.95rem * 1.8)',
        'calc(.95rem * 2.2)'
      ],
      lineHeight: '36px',
      color: colors.grayScale[1]
    },
    h3: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 'calc(.95rem * 1.75)',
      lineHeight: '24px',
      color: colors.grayScale[1]
    },
    h4: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 'calc(.95rem * 1.5)',
      lineHeight: '24px',
      color: colors.grayScale[4]
    },
    h5: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: [
        'calc(.95rem * 1)',
        'calc(.95rem * 1.05)',
        'calc(.95rem * 1.1)',
        'calc(.95rem * 1.3)'
      ],
      lineHeight: '16px',
      color: colors.grayScale[4]
    },
    h6: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 'calc(.95rem * 1.2)',
      lineHeight: '24px',
      color: colors.grayScale[4]
    },
    body1: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: [
        'calc(.95rem * 0.9)',
        'calc(.95rem * 1)',
        'calc(.95rem * 1.10)',
        'calc(.95rem * 1.15)'
      ],
      lineHeight: '24px',
      color: colors.grayScale[4]
    },
    caption: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '16px',
      color: colors.grayScale[3],
      fontSize: ['calc(.95rem * 0.9)', 'calc(.95rem * 1)']
    }
  },
  breakpoints: ['0px', '600px', '960px', '1280px', '1920px']
}

export default extendTheme(overrides)
