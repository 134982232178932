/* eslint-disable indent */
import { useState } from 'react'
import {
  Box,
  Button,
  List,
  ListItem,
  Text,
  Flex,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  Avatar,
  Link as LinkChakra
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { MdCancel as CancelIcon, MdCheck as CheckIcon } from 'react-icons/md'

import { useGlobalStateStore } from '@/store/GlobalState'
import {
  ButtonHighlightAction,
  CountDown,
  Divider,
  Loading,
  OfferCard
} from '@/components/Elements'
import { ResultSkeleton } from '@/components/Skeletons'
import ProfileLayout from '@/components/Layouts/Profile'
import colors from '@/styles/colors'
import ApiService from '@/services/ApiService'
import ErrorHandler from '@/services/ErrorHandlerService'
import useDidMount from '@/contexts/hooks/useDidMount'

import { IResultSubscription } from '@/protocols/Subscription.protocol'
import { IOfferData } from '@/protocols/Offer.protocol'
import { dateFormatter } from '@/utils/dateUtils'

const Result = () => {
  const { user } = useGlobalStateStore()
  const toast = useToast()

  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<IResultSubscription>()
  const [noteHistoryLoading, setNoteHistoryLoading] = useState<boolean>(false)

  const scholarshipSubscription = user?.subscriptions[0].scholarship

  const InstitutionDetailsItem = (data: {
    title: string
    description: string
  }) => (
    <ListItem>
      <Box
        d="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-start"
        fontSize={['14px', '14px', '16px']}
        lineHeight="1"
        fontWeight="400"
      >
        <Text
          fontSize="18px"
          color="gray.500"
          sx={{
            b: {
              color: colors.palette.primary,
              fontWeight: 500
            }
          }}
        >
          <b>{data.title}:</b> {data.description}
        </Text>
      </Box>
    </ListItem>
  )
  const getNoteHistory = async () => {
    setNoteHistoryLoading(true)

    try {
      const {
        data: { url }
      } = await ApiService.get(`subscription/${user.subscriptions[0].id}/grade`)

      window.open(url, '_BLANK')
    } catch (err: any) {
      toast({
        title: 'Oops...',
        description: err.response.data
          ? err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }

    setNoteHistoryLoading(false)
  }

  const getResultData = async () => {
    setLoading(true)

    try {
      const { data: resultData } = await ApiService.get('subscription/result')

      setResult(resultData)
    } catch (error) {
      ErrorHandler.handle(error)
    }

    setLoading(false)
  }

  const ScorePopover = (placement: 'bottom' | 'top' | 'left-end') => {
    const getContent = () => {
      if (result?.failing_status === 'below_minimum') {
        return (
          <Box width="full">
            <Text color={colors.palette.primary}>
              Você não foi classificado pois não atingiu a pontuação mínima de
              300 pontos, conforme regulamento do programa.
            </Text>

            <Divider size={2} orientation="horizontal" />

            <LinkChakra
              color={colors.palette.secondary}
              textDecoration="underline"
              isExternal
              href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
              fontWeight={600}
            >
              Clique aqui para ver regulamento
            </LinkChakra>
          </Box>
        )
      }

      if (result?.failing_status === 'lack_of_quorum') {
        return (
          <Box width="full">
            <Text color={colors.palette.primary}>
              Você não foi classificado por falta de quorum na unidade
              escolhida, conforme regulamento do programa.
            </Text>

            <Divider size={2} orientation="horizontal" />

            <LinkChakra
              color={colors.palette.secondary}
              textDecoration="underline"
              isExternal
              href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
              fontWeight={600}
            >
              Clique aqui para ver regulamento
            </LinkChakra>
          </Box>
        )
      }

      return (
        <Box
          height="100%"
          maxHeight="280px"
          overflowX="hidden"
          overflowY="auto"
          className="custom-scrollbar"
        >
          {result?.rank.map((rank, index) => {
            const colorOpacity = 0.175 * rank.position

            return (
              <Flex
                key={index}
                mb="24px"
                justifyContent={['center', 'center', 'flex-start']}
                alignItems="center"
                flexWrap={['wrap', 'wrap', 'nowrap']}
              >
                <Box width={['100%', '100%', '70%']} mr="16px">
                  <Flex alignItems="center">
                    <Box mr="8px">
                      <Avatar
                        name={`${rank.position} º`}
                        mr={3}
                        background={
                          result?.winner &&
                          Number(rank.position) === Number(result.position)
                            ? 'rgb(159, 37, 240)'
                            : `rgba(159, 37, 240, ${1 - colorOpacity})`
                        }
                        fontFamily="Lato"
                        color="white"
                        fontWeight={600}
                        width="72px"
                        height="48px"
                      />
                    </Box>
                    <Box width="auto">
                      <Text
                        whiteSpace="nowrap"
                        width="140px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        fontSize="calc(0.95rem * 1.45)"
                        textTransform="capitalize"
                        color={colors.palette.primary}
                        fontWeight={
                          Number(rank.position) === Number(result?.position)
                            ? 'bold'
                            : 'normal'
                        }
                      >
                        {result?.winner &&
                        Number(rank.position) === Number(result?.position)
                          ? 'Você'
                          : rank.user.first_name}
                      </Text>
                      <Text color="gray.500">{rank.points} pontos</Text>
                    </Box>
                  </Flex>
                </Box>

                <Box width={['100%', '100%', '80%']} textAlign="right">
                  <Box
                    width="100%"
                    textAlign="center"
                    padding="6px 8px"
                    borderRadius="8px"
                    background="#ECEEF0"
                  >
                    <Text fontWeight={600} color={colors.palette.primary}>
                      Bolsa de {rank.scholarship}
                    </Text>
                  </Box>
                  <Divider size={1} orientation="horizontal" />
                  {result?.winner
                    ? Number(rank.position) === Number(result?.position) && (
                        <Button
                          fontSize="13px"
                          fontWeight={400}
                          textDecoration="underline"
                          color={colors.palette.primary}
                          variant="link"
                          isFullWidth
                          isLoading={noteHistoryLoading}
                          isDisabled={noteHistoryLoading}
                          onClick={getNoteHistory}
                          whiteSpace="pre-wrap"
                        >
                          Ver meu histórico de notas
                        </Button>
                      )
                    : null}
                </Box>
              </Flex>
            )
          })}
        </Box>
      )
    }
    return (
      <Popover matchWidth isLazy placement={placement}>
        <PopoverTrigger>
          <Button
            fontWeight={400}
            textDecoration="underline"
            color={colors.palette.primary}
            variant="link"
          >
            Ver minha classificação
          </Button>
        </PopoverTrigger>

        <PopoverContent width="max-content" borderRadius="34px">
          <PopoverArrow />
          <PopoverCloseButton top="5%" right="5%" />

          <PopoverBody
            borderRadius="34px"
            boxShadow="0px 0px 10px #00000029"
            background="white"
            width="33vw"
            maxWidth="500px"
            minWidth="270px"
          >
            <Box padding="32px 28px">
              <Text
                textStyle="h5"
                fontWeight={'bold'}
                textTransform="uppercase"
                color={colors.palette.primary}
              >
                Classificação
              </Text>

              <Divider size={3} orientation="horizontal" />

              {getContent()}

              <Divider size={2} orientation="horizontal" />

              {!result?.winner && (
                <Box bgGradient="linear(to-b, transparent, white)">
                  <Flex
                    justifyContent={['center', 'center', 'flex-start']}
                    alignItems="center"
                    flexWrap={['wrap', 'wrap', 'nowrap']}
                  >
                    <Box width={['100%', '100%', '70%']} mr="16px">
                      <Flex alignItems="center">
                        <Box mr="8px">
                          <Avatar
                            name={
                              result?.position ? `${result?.position} º` : ''
                            }
                            getInitials={(name: string) => name}
                            fontFamily="Lato"
                            mr={3}
                            bg="#b1aeae"
                            color="white"
                            fontWeight="bold"
                            width="64px"
                            height="48px"
                          />
                        </Box>
                        <Box width="auto">
                          <Text
                            fontSize="calc(0.95rem * 1.45)"
                            textTransform="capitalize"
                            color={colors.palette.primary}
                            fontWeight="bold"
                          >
                            Você
                          </Text>
                        </Box>
                      </Flex>
                    </Box>

                    <Box width={['100%', '100%', '80%']} textAlign="right">
                      <Divider size={1} orientation="horizontal" />

                      <Button
                        fontSize="13px"
                        fontWeight={400}
                        textDecoration="underline"
                        color={colors.palette.primary}
                        variant="link"
                        isFullWidth
                        isLoading={noteHistoryLoading}
                        isDisabled={noteHistoryLoading}
                        onClick={getNoteHistory}
                        whiteSpace="pre-wrap"
                      >
                        Ver meu histórico de notas
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }
  const ResultContent = () => {
    let offerData: IOfferData | null = null
    let percDiscount = 0

    if (result?.bedukaBolsasOffers?.length) {
      offerData = result?.bedukaBolsasOffers[0]

      percDiscount = (
        offerData.new_perc_discount?.length
          ? offerData.new_perc_discount[0].discount
          : offerData.perc_discount
      ) as number
    }

    const disapprovedContent = () => {
      let content = null

      if (offerData) {
        const expireAt =
          result?.extra_data?.offer_for_disapproved?.expire_at || new Date()
        const expireAtFormatter = dateFormatter(expireAt)
        content = (
          <>
            <Box
              margin="0 auto"
              width={['100%', '100%', '95%']}
              textAlign={offerData ? 'inherit' : 'center'}
            >
              <Text
                width={['100%', '100%', '95%']}
                color="palette.primary"
                fontWeight="medium"
                sx={{
                  mark: {
                    background: colors.palette.lilac,
                    padding: '2px 4px',
                    color: '#f1f1f1',
                    fontWeight: 'bold'
                  }
                }}
              >
                Garanta já sua bolsa, porque este presente do Vale Bolsa{' '}
                <mark>
                  estará disponível até {expireAtFormatter?.day} de{' '}
                  {expireAtFormatter?.month.long}
                </mark>
                .
              </Text>

              <Divider size={4} orientation="horizontal" />

              <CountDown expirationDate={expireAt} />
            </Box>

            <Divider size={4} orientation="horizontal" />

            <OfferCard data={offerData} extraData={result?.extra_data} />
          </>
        )
      } else {
        content = (
          <>
            <Box
              margin="0 auto"
              width={['100%', '100%', '95%']}
              textAlign={offerData ? 'inherit' : 'center'}
            >
              <LinkChakra
                isExternal
                color={colors.palette.pink}
                textDecoration="underline"
                textAlign="center"
                href="https://beduka.com/bolsas"
                fontWeight={600}
                margin="0 auto"
              >
                Clique aqui para acessar o nosso catálogo de ofertas
              </LinkChakra>
            </Box>

            <Divider size={4} orientation="horizontal" />
          </>
        )
      }
      return (
        <Flex flexDirection="column">
          <Box margin="0 auto" width={['100%', '100%', '95%']}>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              gridGap="12px"
              alignItems="center"
            >
              <Flex
                width={['100%', '100%', '70%']}
                flexWrap={['wrap', 'wrap', 'nowrap']}
                gridGap="14px"
                justifyContent={['center', 'center', 'space-between']}
                color={colors.palette.primary}
                background="#F7F7FB"
                borderRadius="12px"
                padding="28px 24px"
              >
                <Flex width={['100%', '100%', 'auto']} justifyContent="center">
                  <CancelIcon size="48px" color="inherit" />
                </Flex>

                <Text>
                  Infelizmente você não foi classificado para uma bolsa de{' '}
                  <b>75% ou 100%.</b>
                </Text>
              </Flex>

              <Box width={['100%', '100%', '25%']}>
                <Flex justifyContent="center">{ScorePopover('left-end')}</Flex>
              </Box>
            </Flex>

            <Divider size={3} orientation="horizontal" />

            <Box textAlign="start" width={['100%', '100%', '70%']}>
              <Text color="palette.primary" fontWeight="medium">
                Mas... não fique triste! O Beduka reconhece o seu esforço e
                graças as nossas faculdades parceiras...
              </Text>
            </Box>
          </Box>

          <Divider size={3} orientation="horizontal" />

          <Box width="100%">
            <Flex
              alignItems="center"
              justifyContent="center"
              textAlign={['start', 'center']}
              padding="8px 24px"
              borderRadius="12px"
              background="palette.primary"
            >
              <Text
                textStyle="h2"
                fontWeight="bold"
                color="white"
                sx={{
                  b: {
                    color: '#61ECFF'
                  }
                }}
              >
                CONSEGUIMOS UMA{' '}
                <b>
                  BOLSA{' '}
                  {result?.bedukaBolsasOffers?.length
                    ? `DE ${percDiscount}% `
                    : ''}
                </b>{' '}
                PARA VOCÊ
              </Text>

              <Divider size={2} orientation="vertical" />

              <Text textStyle="h2" fontWeight="bold">
                🎉
              </Text>
            </Flex>
          </Box>

          <Divider size={4} orientation="horizontal" />

          {content}
        </Flex>
      )
    }

    const approvedContent = (
      <Box>
        <List
          spacing={3}
          mb="41px"
          color={colors.palette.primary}
          fontWeight={500}
        >
          {InstitutionDetailsItem({
            title: 'Curso',
            description: user.subscriptions[0].scholarship.course?.name || ''
          })}

          {InstitutionDetailsItem({
            title: 'Cidade',
            description: user.subscriptions[0].scholarship.camp?.city || ''
          })}

          {InstitutionDetailsItem({
            title: 'Polo',
            description: `${user.subscriptions[0].scholarship?.camp?.address}, ${user.subscriptions[0].scholarship?.camp?.city} - ${user.subscriptions[0].scholarship?.camp?.state}`
          })}

          {InstitutionDetailsItem({
            title: 'Modalidade/Turno',
            description: `${user.subscriptions[0].scholarship.modality} - ${user.subscriptions[0].scholarship.shift}`
          })}
        </List>

        <Divider size={3} orientation="horizontal" />

        <Box width="100%">
          <Flex
            alignItems="center"
            background="#B9E3EB"
            borderRadius="12px"
            padding="20px 42px"
            color={colors.palette.primary}
            width={['100%', '100%', 'max-content']}
            wrap="wrap"
          >
            <Box
              width={['100%', '100%', 'auto']}
              mr={[0, 0, '24px']}
              mb={['16px', '16px', 0]}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="36px"
                height="36px"
                borderRadius="full"
                background={colors.palette.primary}
                margin="0 auto"
              >
                <CheckIcon
                  size="24px"
                  fontWeight="bold"
                  color={colors.grayScale[11]}
                />
              </Box>
            </Box>

            <Box
              width={['100%', '100%', 'auto']}
              color="inherit"
              fontWeight={400}
              textAlign={['center', 'center', 'left']}
              mb={['16px', '16px', 0]}
            >
              <Text
                textStyle="h3"
                fontWeight="bold"
                color={colors.palette.primary}
                mb={['16px', '16px', 0]}
              >
                Você foi aprovado!
              </Text>

              <Divider size={1} orientation="horizontal" />

              <Text
                sx={{
                  mark: {
                    color: colors.palette.primary,
                    background: '#DAF0F5',
                    fontWeight: 600
                  }
                }}
              >
                E ganhou uma bolsa de{' '}
                <mark>
                  {result?.scholarship} na{' '}
                  {scholarshipSubscription.institution.acronym}
                </mark>
              </Text>
            </Box>

            <Box width={['100%', '100%', 'auto']} ml={[0, 0, '28px']}>
              <Text textAlign={['center', 'center', 'left']} fontSize="48px">
                🎉
              </Text>
            </Box>
          </Flex>
        </Box>

        <Divider size={3} orientation="horizontal" />

        <Box width="90%">
          <Text
            lineHeight="1.5em"
            textStyle="h5"
            fontWeight={400}
            color={colors.palette.primary}
            sx={{
              b: {
                color: colors.palette.secondary
              }
            }}
          >
            Estamos muito orgulhosos de você!
            <br />
            <br />
            Clique no botão abaixo para concluir o processo e <br />
            <b>garantir a sua bolsa</b>
          </Text>
        </Box>

        <Divider size={6} orientation="horizontal" />

        <Box width={['100%', '100%', '90%']}>
          <Flex
            justifyContent="space-around"
            alignItems="center"
            alignContent="center"
            flexWrap="wrap"
          >
            <Box width={['100%', '100%', '60%']} mb={['24px', '24px', 0]}>
              <Link to="/pagamento">
                <ButtonHighlightAction isFullWidth>
                  Garantir minha bolsa
                </ButtonHighlightAction>
              </Link>
            </Box>

            <Box width={['100%', '100%', '38.5%']}>
              <Flex justifyContent="center">{ScorePopover('top')}</Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    )

    if (!result) {
      return (
        <Flex
          height="100%"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Box>
            <Text
              textAlign="center"
              textStyle="h3"
              fontWeight="bold"
              color={colors.palette.primary}
            >
              Você não fez a prova. Por isso não tem classificação.
            </Text>

            <Divider size={2} orientation="horizontal" />

            <Text
              textAlign="center"
              textStyle="h3"
              fontWeight="bold"
              color={colors.palette.primary}
            >
              Porém, conseguimos uma oportunidade para você estudar com Bolsas
              através do nosso site:
            </Text>

            <Divider size={3} orientation="horizontal" />

            <Text
              textStyle="h2"
              textAlign="center"
              fontWeight="bold"
              color="white"
              sx={{
                b: {
                  color: '#61ECFF'
                }
              }}
            >
              <LinkChakra
                color={colors.palette.secondary}
                textDecoration="underline"
                textAlign="center"
                isExternal
                href="https://beduka.com/bolsas"
                fontWeight={600}
              >
                BEDUKA BOLSAS
              </LinkChakra>
            </Text>
          </Box>
        </Flex>
      )
    }
    return result?.winner ? approvedContent : disapprovedContent()
  }

  useDidMount(() => {
    getResultData()
  })

  return (
    <ProfileLayout>
      <Flex
        w="100%"
        maxWidth="none"
        h={'100%'}
        p={['40px 16px', '40px 16px', '40px 32px']}
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <Loading loading={loading} customLoadingElement={<ResultSkeleton />}>
          <Box width="90%">
            <Text
              textStyle="h3"
              fontWeight="bold"
              color={colors.palette.primary}
            >
              Resultado do Vale Bolsa
            </Text>
          </Box>

          <Divider size={3} orientation="horizontal" />

          <Box width="100%" height="100%">
            {ResultContent()}
          </Box>
        </Loading>
      </Flex>
    </ProfileLayout>
  )
}

export default Result
