import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

import logoSrc from '@/assets/vale-bolsa-logo.svg'

export const Content = styled(motion.nav)`
  position: fixed;

  width: 100%;
  top: 30px;

  padding: 0 16px;

  transition: all ease 0.3s;

  z-index: 999;

  ${media.lessThan('816px')`
    top: 0;
  `};
`

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1117px;

  margin: 0 auto !important;

  ${media.lessThan('816px')`
    padding: 0 4px;
  `}
`

export const Logo = styled(motion.img).attrs(() => ({
  src: logoSrc,
  alt: 'Vale Bolsa - Beduka'
}))`
  width: 120px;

  ${media.lessThan('816px')`
    width: 110px;
  `}
`

export const Navbar = styled(motion.div)`
  margin-left: 12px;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px 10px 25px;

  ${({ isMobile }) =>
    !isMobile &&
    `
    width: 100%;
    max-width: 950px;
  `}

  height: 56px;

  background: #fff;

  border-radius: 8px;

  transition: all ease 0.3s;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 0;
    margin: 0;

    list-style: none;

    gap: 4vw;

    li {
      text-align: center;
      a {
        color: #b4b4b6;
        font-weight: 600;
        font-size: 14px;
        transition: all ease 0.3s;
        cursor: pointer;

        &:hover {
          color: var(--title-text);
        }

        &.active {
          color: var(--title-text);
        }
      }
    }
  }

  div {
    button {
      background: #ebebed;
      color: #2c3440;

      border-radius: 4px;

      font-size: 14px;
      font-weight: 500;

      padding: 6px 14px;
      cursor: pointer;

      &:nth-child(2) {
        background: var(--button-action);
        color: #fff;
        margin-left: 10px;
      }
    }
  }

  ${media.lessThan('816px')`
    display: none;
  `}
`

export const CollapseNavbar = styled.div`
  button {
    padding: 0;

    height: 35px;
    border-radius: 35px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 20px;

    text-align: center;
    line-height: 35px;

    color: #fff;
    background: var(--button-action);
  }

  .back-button {
    background: #ebebed !important;
    color: #2c3440 !important;
  }
`

export const CollapseBackground = styled(motion.div).attrs({
  layoutId: 'menu-background'
})``

export const CollapseMenu = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  padding: 20px;

  background: #fff;

  z-index: 9999;

  button.close {
    position: absolute;

    top: 18px;
    right: 20px;

    padding: 0;

    width: 50px;
    height: 50px;
    border-radius: 50px;

    text-align: center;
    line-height: 65px;

    background: #fff;

    font-size: 30px;
  }
`
