import { ReactElement } from 'react'
import { Box, CircularProgress, Flex } from '@chakra-ui/react'
import { Divider } from '@/components/Elements'
type LoadingFullscreenProps = {
  loadingContent?: ReactElement
}

const LoadingFullscreenSkeleton: React.FC<LoadingFullscreenProps> = props => {
  const { loadingContent } = props
  return (
    <Box
      position={'fixed'}
      width={'100%'}
      height={'100%'}
      left={0}
      top={0}
      background="rgba(51,51,51,0.85)"
      zIndex={999}
    >
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Box textAlign="center">
          <CircularProgress
            isIndeterminate
            color="rgba(51,51,51,0.85)"
            size="42px"
          />

          <Divider size={2} orientation="horizontal" />

          {loadingContent && loadingContent}
        </Box>
      </Flex>
    </Box>
  )
}

export default LoadingFullscreenSkeleton
