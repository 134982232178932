import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { debugContextDevtool } from 'react-context-devtool'

import Provider from '@/contexts'

import { GlobalStyle } from '@/styles/global'

import App from './app'
import GlobalStateProvider from '@/store/GlobalState'
import { IsDevEnv } from '@/config/environment'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG || '6f7becb96455e423d7bb3b16b0d7d584',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'beta'],
  releaseStage: process.env.NODE_ENV
})

const ErrorBoundary = (Bugsnag.getPlugin('react') as any).createErrorBoundary(
  React
)

const rootElement = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <GlobalStateProvider>
          <Provider>
            <GlobalStyle />

            <App />
          </Provider>
        </GlobalStateProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  rootElement
)

// To make use of the context debug it is necessary to install the extension
// https://github.com/deeppatel234/react-context-devtool
debugContextDevtool(rootElement, {
  disable: !IsDevEnv
})
