import React, { useState } from 'react'
import { Grid, Flex, Box } from '@chakra-ui/layout'

import { ProfileNavigator, Portlet } from '@/components/Elements'
import ApiService from '@/services/ApiService'
import ErrorHandler from '@/services/ErrorHandlerService'
import useDidMount from '@/contexts/hooks/useDidMount'
import { useGlobalStateStore } from '@/store/GlobalState'

const ProfileLayout: React.FC = props => {
  const { children } = props
  const { user } = useGlobalStateStore()

  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState<any>()
  const getResultData = async () => {
    setLoading(true)

    try {
      const { data: result } = await ApiService.get('subscription/result')

      if (result.released) {
        setResult(result)
      }
    } catch (error) {
      ErrorHandler.handle(error)
    }

    setLoading(false)
  }

  useDidMount(() => {
    getResultData()
  })

  return (
    <Flex
      height="100%"
      overflow="none"
      justifyContent="center"
      alignContent="center"
      maxWidth="none"
      padding={[0, '24px']}
    >
      <Portlet
        width="100%"
        maxWidth="1410px"
        boxShadow="-2px 0px 10px #00000012"
        padding="0px"
      >
        <Grid templateColumns={['100%', '100%', '30% 70%']} height="100%">
          <Flex alignItems="center" height="100%">
            <ProfileNavigator
              defaultEmail={user?.email}
              defaultBirthDate={user?.profile.birth_date}
              data={user?.profile}
              loading={loading}
              result={result}
              subscriptionId={user?.subscriptions[0]?.id}
              scholarship={user?.subscriptions[0]?.scholarship}
            />
          </Flex>

          <Box
            background="linear-gradient(150deg, rgba(18,207,216,0.06) 0%, rgba(11,24,128,0.06) 50%, #b2afec14 100%);"
            borderRadius={[0, 0, '0 16px 16px 0']}
          >
            {children}
          </Box>
        </Grid>
      </Portlet>
    </Flex>
  )
}

export default ProfileLayout
