import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  padding: 15px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  width: 32%;
  margin-bottom: 15px;

  ${media.lessThan('medium')`
    margin-bottom: 25px;

    width: 100%;
  `}
`

export const Logo = styled.img.attrs({ alt: 'Bolsa Beduka' })`
  height: 40px;
  margin-bottom: 20px;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #000000;
`

export const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);

  margin-bottom: 15px;
`

export const Address = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;

  height: 42px;

  color: var(--tertiary);

  margin-bottom: 15px;
`

export const Features = styled.ul`
  li {
    font-size: 14px;
  }
`

export const Flex = styled.div`
  display: inline-grid;
  grid-template-columns: 48% 48%;
  grid-gap: 4%;

  margin-top: 25px;

  width: 100%;

  div {
    text-align: center;

    &:nth-child(1) {
      div {
        color: #23a0e9;
      }
    }

    &:nth-child(2) {
      div {
        color: #ea6b34;
      }
    }
  }
`

export const Label = styled.label`
  font-size: 10px;
  line-height: 12px;
  color: #85868a;
`

export const Value = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.02em;
`

export const Button = styled.button`
  background-color: var(--button-action);
  padding: 8px 25px;
  width: 100%;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  transition: all ease 0.3s;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
