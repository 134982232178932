class SanitizationUtils {
  public getOnlyNumber(rawValue: string): string {
    let formattedValue: string = rawValue

    if (rawValue) {
      formattedValue = rawValue.replace(/\D/g, '')
    }

    return formattedValue
  }

  public formatCurrencyToBRL(rawValue: number): string {
    let formattedCurrency = rawValue ? '0' : String(rawValue)

    formattedCurrency = rawValue?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL'
    })

    return formattedCurrency
  }
}

export default new SanitizationUtils()
