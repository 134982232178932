/* eslint-disable indent */
import { ReactElement } from 'react'
import { Box, Text, Flex, HStack, Img, Link } from '@chakra-ui/react'
import {
  MdRoom as LocationIcon,
  MdAccessTime as ShiftIcon,
  MdPeople as ModalityIcon
} from 'react-icons/md'

import {
  RegressiveDiscount,
  Divider,
  Portlet,
  ButtonHighlightAction
} from '@/components/Elements'
import { IOfferData } from '@/protocols/Offer.protocol'
import colors from '@/styles/colors'

import SanitizationUtils from '@/utils/sanitization.utils'
import { ICampaignExtraData } from '@/protocols/Campaign.protocol'

type OfferCardProps = {
  data: IOfferData
  extraData: ICampaignExtraData | undefined
}

const OfferCard = (props: OfferCardProps) => {
  const { data, extraData } = props

  const OfferDetail = (data: { icon: ReactElement; content: string }) => (
    <Box
      width="fit-content"
      display={['flex', 'flex', 'flex', 'block']}
      justifyContent={['center']}
    >
      <HStack spacing={1} alignItems="center" wrap="nowrap">
        <Box width="auto">{data.icon}</Box>

        <Box width="auto">
          <Text fontSize="calc(0.95rem * 1.05)" color={colors.grayScale[6]}>
            {data.content}
          </Text>
        </Box>
      </HStack>
    </Box>
  )

  const CallToAction = () => (
    <>
      <Link
        margin="0 auto"
        width="100%"
        maxWidth="50vw"
        isExternal
        href={
          extraData?.offer_for_disapproved?.is_active
            ? data.offer_url
            : undefined
        }
      >
        <ButtonHighlightAction
          disabled={!extraData?.offer_for_disapproved?.is_active}
          isFullWidth
          as="a"
        >
          {extraData?.offer_for_disapproved?.is_active
            ? 'Garantir minha bolsa'
            : 'Bolsa não mais disponível'}
        </ButtonHighlightAction>
      </Link>

      <Divider size={2} orientation="horizontal" />
      {data?.new_perc_discount?.length &&
        data?.new_perc_discount[0]?.discount !==
          data?.new_perc_discount[1]?.discount && (
          <RegressiveDiscount data={data.new_perc_discount} />
        )}
    </>
  )

  return (
    <Portlet
      position="relative"
      height={['fit-content']}
      width="100%"
      padding={[
        '28px 16px 24px',
        '28px 16px 24px',
        '28px 16px 24px',
        '28px 32px'
      ]}
      borderRadius="20px"
      boxShadow="2px 1px 3px #00000029"
      background="white"
    >
      <Flex
        justifyContent={['center', 'center', 'center', 'space-between']}
        alignItems="center"
        height="100%"
        flexWrap="wrap"
      >
        <Box
          order={[1, 1, 1, 0]}
          width={['100%', '100%', '100%', '55%']}
          marginTop={['32px', '32px', '32px', 0]}
        >
          <Text
            as="h3"
            textStyle="h6"
            fontWeight={500}
            color="palette.primary"
            textAlign={['center', 'center', 'center', 'start']}
          >
            {data.course_name}
          </Text>

          <Divider size={3} orientation={'horizontal'} />

          <HStack
            spacing={[2, 2, 0]}
            justifyContent={['center', 'space-between']}
            flexDirection="row"
            flexWrap="wrap"
          >
            {OfferDetail({
              icon: (
                <LocationIcon
                  fontSize="calc(0.95rem * 1.25)"
                  color={colors.grayScale[6]}
                />
              ),
              content: `${data.city_name} - ${data.state_uf}`
            })}
            {OfferDetail({
              icon: (
                <ShiftIcon
                  fontSize="calc(0.95rem * 1.25)"
                  color={colors.grayScale[6]}
                />
              ),
              content: data.shift
            })}

            {OfferDetail({
              icon: (
                <ModalityIcon
                  fontSize="calc(0.95rem * 1.25)"
                  color={colors.grayScale[6]}
                />
              ),
              content: data.modality
            })}
          </HStack>

          <Divider size={5} orientation={'horizontal'} />

          <HStack
            spacing={[0, 0, 5]}
            justifyContent={[
              'center',
              'space-around',
              'space-around',
              'flex-start'
            ]}
            flexWrap="wrap"
            position="relative"
            height={['fit-content', '90px']}
          >
            <Box
              width={['100%', '40%', '35%']}
              mb={['16px', 0]}
              display="flex"
              alignItems={['center', 'flex-start', 'flex-start']}
              flexDirection="column"
            >
              <Text
                position={['initial', 'absolute']}
                top={0}
                textTransform="uppercase"
                fontSize="calc(0.95rem * 0.975)"
                color={colors.grayScale[5]}
                fontWeight={500}
              >
                Mensalidade
              </Text>

              <Divider size={2} orientation={'horizontal'} />
              <Box
                position={['initial', 'absolute']}
                bottom={0}
                textAlign={['center', 'start']}
              >
                <Text textDecoration="line-through">
                  De{' '}
                  {SanitizationUtils.formatCurrencyToBRL(
                    data.monthly_payment_without_discount
                  )}
                </Text>

                <Text
                  color="palette.primary"
                  fontWeight={600}
                  sx={{
                    b: {
                      fontSize: 'calc(0.95rem * 1.4)',
                      marginLeft: '8px'
                    }
                  }}
                >
                  por
                  <b>
                    {SanitizationUtils.formatCurrencyToBRL(
                      data.monthly_payment_with_discount
                    )}
                  </b>
                </Text>
              </Box>
            </Box>

            <Box
              width={['40%', '20%', '15%']}
              display="flex"
              alignItems={['center', 'center', 'center', 'flex-start']}
              flexDirection="column"
            >
              <Text
                position={['initial', 'absolute']}
                top={0}
                textTransform="uppercase"
                fontSize="calc(0.95rem * 0.975)"
                color={colors.grayScale[5]}
                fontWeight={500}
              >
                Bolsa
              </Text>

              <Divider size={2} orientation={'horizontal'} />

              <Text
                position={['initial', 'absolute']}
                bottom={0}
                color={colors.palette.primary}
                fontSize={'calc(0.95rem * 1.4)'}
                marginLeft="8px"
                fontWeight={600}
              >
                {data?.new_perc_discount?.length
                  ? data.new_perc_discount[0]?.discount
                  : data?.perc_discount}{' '}
                %
              </Text>
            </Box>

            <Box
              width={['60%', '40%', '30%']}
              display="flex"
              alignItems={['center', 'flex-end', 'flex-end', 'flex-start']}
              flexDirection="column"
            >
              <Text
                position={['initial', 'absolute']}
                top={0}
                textTransform="uppercase"
                fontSize="calc(0.95rem * 0.975)"
                color={colors.grayScale[5]}
                fontWeight={500}
              >
                Economia total
              </Text>

              <Divider size={2} orientation={'horizontal'} />

              <Text
                position={['initial', 'absolute']}
                bottom={0}
                color={colors.palette.primary}
                fontSize={'calc(0.95rem * 1.4)'}
                marginLeft="8px"
                fontWeight={600}
              >
                {SanitizationUtils.formatCurrencyToBRL(data.total_savings)}
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box order={[0, 0, 0, 1]} width={['100%', '100%', '100%', '40%']}>
          <Flex flexDirection="column" justifyContent="center">
            <Img
              src={data?.institution_data?.logo_url}
              alt={data?.institution_data?.name}
              width="195px"
              margin="0 auto"
            />

            <Box marginTop="32px" display={['none', 'none', 'none', 'block']}>
              {CallToAction()}
            </Box>
          </Flex>
        </Box>

        <Box
          order={[2, 2, 2, 1]}
          marginTop="32px"
          width={['100%', '100%', '100%', '40%']}
          display={['block', 'block', 'block', 'none']}
        >
          <Flex flexDirection="column" justifyContent="center">
            {CallToAction()}
          </Flex>
        </Box>
      </Flex>
    </Portlet>
  )
}

export default OfferCard
