import {
  Box,
  Flex,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { IoPlay } from 'react-icons/io5'
import ReactPlayer from 'react-player/youtube'

const Testimonial = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  })

  return (
    <>
      <Box w="100%" p="4">
        <Flex
          mb={4}
          bg="black"
          bgSize="cover"
          bgPos="center"
          onClick={onOpen}
          borderRadius="3xl"
          alignItems="center"
          justifyContent="center"
          h={['330px', '330px', '360px']}
          bgImg={`url(${data.thumbnail})`}
        >
          <IconButton
            aria-label="Play"
            icon={<IoPlay />}
            size="lg"
            bg="white"
            cursor="pointer"
            isRound
            onClick={onOpen}
          />
        </Flex>
        <Heading
          maxW="100%"
          size="md"
          fontWeight="600"
          color="gray.500"
          mt={[10, 10, 0]}
          isTruncated
        >
          {data.name}
        </Heading>
        <Text
          maxW="100%"
          fontSize="2xl"
          fontWeight="700"
          color="gray.600"
          isTruncated
        >
          {data.course}
        </Text>
        <Text
          maxW="100%"
          color="#EE3DB6"
          fontWeight="bold"
          fontSize="20px"
          isTruncated
        >
          Bolsa de {data.scholarship || '100'}%
        </Text>
        <Text maxW="100%" fontSize="lg" fontWeight="500" color="#26328E" mb={2}>
          {data.institution} - {data.city} - {data.state}
        </Text>
        <Text fontSize="md" color="gray.600" fontStyle="italic">
          {data.phrase}
        </Text>
      </Box>
      <Modal
        size={isMobileDevice ? 'full' : '3xl'}
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalOverlay bg="rgba(0, 0, 0, .9)" />
        <ModalContent bg="#212121" pb={5}>
          <ModalHeader color="white">Agradecimentos de {data.name}</ModalHeader>
          <ModalCloseButton
            color="white"
            bg="rgba(0, 0, 0, 0.4)"
            cursor="pointer"
          />
          <ModalBody>
            <ReactPlayer
              url={data.video}
              playing={isOpen}
              width="100%"
              height="360px"
            />
            <Heading
              mt={8}
              size="lg"
              maxW="100%"
              isTruncated
              fontWeight="600"
              color="gray.100"
            >
              {data.name}
            </Heading>
            <Text
              maxW="100%"
              fontSize="lg"
              fontWeight="600"
              color="gray.200"
              isTruncated={[false, false, true]}
            >
              {data.course} - {data.institution}
            </Text>
            <Flex mb={2} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <Box width={['100%', '100%', 'auto']}>
                <Text
                  mr={4}
                  maxW="100%"
                  isTruncated
                  fontSize="lg"
                  fontWeight="600"
                  color="blue.600"
                >
                  {data.city} - {data.state}
                </Text>
              </Box>

              <Box width={['100%', '100%', 'auto']}>
                <Text
                  maxW="100%"
                  isTruncated
                  fontSize="lg"
                  color="#EA6D36"
                  fontWeight="bold"
                >
                  Bolsa de {data.scholarship || '100'}%
                </Text>
              </Box>
            </Flex>
            <Text fontSize="md" color="gray.300">
              {data.phrase}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Testimonial
