/* eslint-disable no-tabs */
import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  ListItem,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useToast,
  Divider as DividerChakra,
  Image,
  Link as LinkChakra
} from '@chakra-ui/react'
import { useHistory } from 'react-router'
import InputMask from 'react-input-mask'
import { ImCreditCard as CreditCardIcon } from 'react-icons/im'

import ProfileLayout from '@/components/Layouts/Profile'
import colors from '@/styles/colors'
import { Divider, Loading, StyledInput } from '@/components/Elements'
import {
  PaymentMethodProps,
  PaymentDataProps
} from '@/protocols/Payment.protocol'
import { ValueOf } from '@/protocols/CommonProtocol'
import useValidation, { ErrorType } from '@/contexts/hooks/useValidation'
import SanitizationUtils from '@/utils/sanitization.utils'
import { formatEmail } from '@/utils/email.util'
import ApiService, { ViaCepApiService } from '@/services/ApiService'
import useDidMount from '@/contexts/hooks/useDidMount'

import BoletoImg from '@/assets/icons/boleto-icon.png'
import MoipService from '@/services/MoipService'
import {
  IOrderInstallment,
  PaymentOrder
} from '@/protocols/PaymentOrder.protocol'
import { IScholarship } from '@/protocols/Scholarships.protocol'
import { ICreditCardData } from '@/protocols/Moip.protocol'
import { LoadingFullscreenSkeleton } from '@/components/Skeletons'
import { IExamResult } from '@/protocols/ExamResult.protocol'
import { formattedStringDateToStringDateEn } from '@/utils/dateUtils'

export type PaymentOfferDataProps = {
  order?: PaymentOrder
  examResult: IExamResult
  scholarship: IScholarship
  scholarships_installments: IOrderInstallment[]
}
const PaymentPage = () => {
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
  const [offerData, setOfferData] = useState<PaymentOfferDataProps>()
  const [paymentData, setPaymentData] = useState<PaymentDataProps>({
    method: 'CREDIT_CARD',
    installments: 1,
    holder_name: '',
    holder_cpf: '',
    holder_email: '',
    holder_birth_date: '',
    holder_cep_code: '',
    holder_address_street: '',
    holder_address_complement: '',
    holder_address_number: '',
    holder_address_neighborhood: '',
    holder_address_city: '',
    holder_address_uf: ''
  })
  const { validation, clearValidation, triggerValidation, addValidation } =
    useValidation()
  const toast = useToast()
  const history = useHistory()

  const getOfferData = async () => {
    try {
      const { data } = await ApiService.get('/payment/pay')

      const offer = data as PaymentOfferDataProps

      if (
        offer.order?.status &&
        ['WAITING', 'PAID'].includes(offer?.order?.status)
      ) {
        history.push('/minha-conta')
      } else {
        setOfferData(offer)
      }
    } catch (error) {
      toast({
        title: 'Ops, algo deu errado...',
        description: 'Erro no processamento, tente novamente'
      })

      history.push('/resultado')
    }
  }

  const handlePaymentDataChange = (
    type: keyof PaymentDataProps,
    value: ValueOf<PaymentDataProps>
  ) => {
    clearValidation(type)

    setPaymentData(currentState => ({
      ...currentState,
      [type]: value
    }))
  }

  const handleSearchCEP = async (cep_code: string | undefined) => {
    try {
      const { data } = await ViaCepApiService.get(`${cep_code}/json`)

      if (data?.erro) {
        throw data?.erro
      }

      data.logradouro &&
        handlePaymentDataChange('holder_address_street', data.logradouro)

      data.bairro &&
        handlePaymentDataChange('holder_address_neighborhood', data.bairro)

      data.localidade &&
        handlePaymentDataChange('holder_address_city', data.localidade)

      data.uf && handlePaymentDataChange('holder_address_uf', data.uf)
    } catch (error) {
      triggerValidation({
        message: '',
        response: {
          status: '400',
          data: {
            messages: {
              errors: [
                {
                  rule: 'invalid',
                  message: 'invalid_cep',
                  field: 'holder_cep_code'
                }
              ]
            }
          }
        }
      })
    }
  }

  const handlePaymentMethodChange = (index: number) => {
    const methodOptions: { [key: number]: PaymentMethodProps } = {
      0: 'CREDIT_CARD',
      1: 'BOLETO'
    }

    handlePaymentDataChange('method', methodOptions[index] || methodOptions[0])
  }

  const handleCreditCardDataChange = (
    type: keyof ICreditCardData,
    value: ValueOf<ICreditCardData>
  ) => {
    clearValidation('creditCard')

    clearValidation(type)

    setPaymentData(currentState => ({
      ...currentState,
      creditCardData: {
        ...currentState.creditCardData,
        [type]: value
      }
    }))
  }

  const handlePaymentSubmit = async () => {
    setLoadingPayment(true)

    try {
      let creditCardHash = null
      if (paymentData.method === 'CREDIT_CARD') {
        const generateCreditCardData = await MoipService.generateCreditCardHash(
          paymentData.creditCardData as ICreditCardData
        )

        if (
          !generateCreditCardData.isValid &&
          generateCreditCardData?.fieldError
        ) {
          generateCreditCardData.fieldError.forEach((element: any) => {
            addValidation(element)
          })

          toast({
            title: 'Atenção',
            description: 'Revise as informaçoes inseridas...',
            status: 'warning',
            isClosable: true
          })

          setLoadingPayment(false)
        } else {
          creditCardHash = generateCreditCardData?.hash
        }
      }

      if (
        (paymentData.method === 'CREDIT_CARD' && Boolean(creditCardHash)) ||
        paymentData.method === 'BOLETO'
      ) {
        const birthDateFormatted = formattedStringDateToStringDateEn(
          paymentData.holder_birth_date
        )

        const { data } = await ApiService.post('/payment/pay', {
          method: paymentData.method.toUpperCase(),
          installments: paymentData.installments,
          creditCardData: {
            hash: creditCardHash,
            holderName: paymentData.creditCardData?.holderName
          },
          holder_name: paymentData.holder_name,
          holder_cpf: paymentData.holder_cpf,
          holder_email: paymentData.holder_email,
          holder_birth_date: birthDateFormatted,
          holder_cep_code: SanitizationUtils.getOnlyNumber(
            paymentData?.holder_cep_code
          ),
          holder_address_street: paymentData?.holder_address_street,
          holder_address_number: paymentData?.holder_address_number,
          holder_address_complement: paymentData?.holder_address_complement,
          holder_address_neighborhood: paymentData?.holder_address_neighborhood,
          holder_address_city: paymentData?.holder_address_city,
          holder_address_uf: paymentData?.holder_address_uf
        })

        /*

				TODO - The timeout is temporary, it will be necessary
				to build a function to perform the verification
				of state change when it is a credit card

				*/

        setTimeout(() => {
          if (data?.transaction?.payment_data?.boleto?.link) {
            window.open(data.transaction.payment_data.boleto.link, '_blank')
          }

          history.push('/minha-conta')
        }, 10000)
      }
    } catch (error) {
      triggerValidation(error as ErrorType)
      toast({
        title: 'Erro!',
        description: 'Erro ao processar o pagamento. Tente novamente!',
        status: 'error'
      })
      setLoadingPayment(false)
    }
  }

  const getInstallmentsCreditCard = () => {
    const installments = offerData?.scholarships_installments

    if (!installments) return null

    const options = installments.map((installment, index) => {
      const menuName = `${
        installment.number_of_installments
      }x de ${SanitizationUtils.formatCurrencyToBRL(
        installment.price_per_installment
      )} ${
        installment?.has_interest ? 'com' : 'sem'
      } juros (${SanitizationUtils.formatCurrencyToBRL(
        installment.total_price
      )})`

      return (
        <option key={index} value={installment.number_of_installments}>
          {menuName}
        </option>
      )
    })

    return options
  }

  const tabIndex = () => {
    const tabOptions = {
      CREDIT_CARD: 0,
      BOLETO: 1
    }

    return tabOptions[paymentData.method] || tabOptions.CREDIT_CARD
  }

  const holderInputs = () => (
    <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Box width="100%">
        <Text textAlign="center" fontWeight="bold">
          Informe abaixo os dados do{' '}
          <b>
            {paymentData.method === 'CREDIT_CARD'
              ? 'titular do cartão'
              : 'pagante'}
          </b>
          .
        </Text>
      </Box>

      <Divider size={2} orientation="horizontal" />

      <Box
        width={[
          '100%',
          paymentData.method === 'CREDIT_CARD' ? '60%' : '100%',
          paymentData.method === 'CREDIT_CARD' ? '60%' : '32%',
          paymentData.method === 'CREDIT_CARD' ? '28%' : '32%'
        ]}
      >
        <StyledInput
          id="input_holder_name"
          label="Nome completo"
          name="holder_name"
          value={paymentData?.holder_name}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_name', target.value)
          }
          placeholder="Digite seu nome completo"
          isRequired
          helper={validation.holder_name}
          isInvalid={Boolean(validation.holder_name)}
        />
      </Box>

      <Box
        width={[
          '100%',
          '38%',
          paymentData.method === 'CREDIT_CARD' ? '38%' : '32%',
          paymentData.method === 'CREDIT_CARD' ? '19%' : '32%'
        ]}
      >
        <StyledInput
          id="input_holder_cpf"
          name="holder_cpf"
          label="CPF"
          as={InputMask}
          mask="999.999.999-99"
          placeholder="999.999.999-99"
          value={paymentData?.holder_cpf}
          isRequired
          onChange={({ target }) =>
            handlePaymentDataChange(
              'holder_cpf',
              SanitizationUtils.getOnlyNumber(target.value)
            )
          }
          helper={validation.holder_cpf}
          isInvalid={Boolean(validation.holder_cpf)}
        />
      </Box>

      <Box
        width={[
          '100%',
          '60%',
          paymentData.method === 'CREDIT_CARD' ? '60%' : '32%',
          paymentData.method === 'CREDIT_CARD' ? '26%' : '32%'
        ]}
      >
        <StyledInput
          id="input_holder_email"
          label="Email"
          name="holder_email"
          value={paymentData?.holder_email}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_email', formatEmail(target.value))
          }
          placeholder="seunome@email.com"
          isRequired
          helper={validation.holder_email}
          isInvalid={Boolean(validation.holder_email)}
        />
      </Box>

      {paymentData.method === 'CREDIT_CARD' && (
        <Box width={['100%', '38%', '38%', '21%']}>
          <StyledInput
            id="input_holder_birth_date"
            label="Data de nascimento:"
            name="holder_birth_date"
            as={InputMask}
            mask="99/99/9999"
            value={paymentData?.holder_birth_date}
            onChange={({ target }) =>
              handlePaymentDataChange('holder_birth_date', target.value)
            }
            placeholder="99/99/9999"
            isRequired
            helper={validation.holder_birth_date}
            isInvalid={Boolean(validation.holder_birth_date)}
          />
        </Box>
      )}

      <Box width={['100%', '22.5%', '20%']}>
        <StyledInput
          id="input_holder_cep_code"
          label="CEP"
          name="holder_cep_code"
          as={InputMask}
          mask="99999-999"
          value={paymentData?.holder_cep_code}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_cep_code', target.value)
          }
          onBlur={() => handleSearchCEP(paymentData.holder_cep_code)}
          placeholder="99999-999"
          isRequired
          helper={validation.holder_cep_code}
          isInvalid={Boolean(validation.holder_cep_code)}
        />
      </Box>

      <Box width={['68%', '37.5%', '30%']}>
        <StyledInput
          id="input_holder_address_street"
          label="Endereço"
          name="holder_address_street"
          placeholder="Digite sua Rua; Avenida, ..."
          value={paymentData?.holder_address_street}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_street', target.value)
          }
          isRequired
          helper={validation.holder_address_street}
          isInvalid={Boolean(validation.holder_address_street)}
        />
      </Box>

      <Box width={['30%', '15%', '20%']}>
        <StyledInput
          id="input_holder_address_number"
          label="Número"
          name="holder_address_number"
          placeholder="XXX"
          value={paymentData?.holder_address_number}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_number', target.value)
          }
          isRequired
          helper={validation.holder_address_number}
          isInvalid={Boolean(validation.holder_address_number)}
        />
      </Box>

      <Box width={['48%', '20%', '20%']}>
        <StyledInput
          id="input_holder_address_complement"
          label="Complemento"
          name="holder_address_complement"
          placeholder="Casa; AP XX; ..."
          value={paymentData?.holder_address_complement}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_complement', target.value)
          }
          helper={validation.holder_address_complement}
          isInvalid={Boolean(validation.holder_address_complement)}
        />
      </Box>

      <Box width={['50%', '37.5%', '35%']}>
        <StyledInput
          id="input_holder_address_neighborhood"
          label="Bairro"
          name="holder_address_neighborhood"
          placeholder="Digite seu bairro"
          value={paymentData?.holder_address_neighborhood}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_neighborhood', target.value)
          }
          isRequired
          helper={validation.holder_address_neighborhood}
          isInvalid={Boolean(validation.holder_address_neighborhood)}
        />
      </Box>

      <Box width={['65%', '40%']}>
        <StyledInput
          id="input_holder_address_city"
          name="holder_address_city"
          label="Cidade"
          tooltiplabel="Preenchimento automático por meio do CEP."
          placeholder="Preenchido automaticamente..."
          value={paymentData?.holder_address_city}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_city', target.value)
          }
          isRequired
          helper={validation.holder_address_city}
          isInvalid={Boolean(validation.holder_address_city)}
          isDisabled
          _disabled={{
            bg: '#F7F7F7',
            color: '#D3D3E0'
          }}
        />
      </Box>

      <Box width={['33%', '20%']}>
        <StyledInput
          id="input_holder_address_uf"
          name="holder_address_uf"
          label="Estado"
          tooltiplabel="Preenchimento automático por meio do CEP."
          placeholder="Preenchido automaticamente..."
          value={paymentData?.holder_address_uf}
          onChange={({ target }) =>
            handlePaymentDataChange('holder_address_uf', target.value)
          }
          isRequired
          helper={validation.holder_address_uf}
          isInvalid={Boolean(validation.holder_address_uf)}
          isDisabled
          _disabled={{
            bg: '#F7F7F7',
            color: '#D3D3E0'
          }}
        />
      </Box>
    </Flex>
  )

  const initialize = async () => {
    setLoadingPage(true)

    await getOfferData()

    setLoadingPage(false)
  }

  useDidMount(() => {
    initialize()
  })

  return (
    <ProfileLayout>
      {loadingPayment && (
        <LoadingFullscreenSkeleton
          loadingContent={
            <Box>
              <Text color="white" fontWeight="500">
                Processando pagamento...
              </Text>
              <Divider size={2} orientation="horizontal" />

              {paymentData.method === 'BOLETO' && (
                <Text color="white" fontWeight="500">
                  Após a geração do boleto é necessário aguardar alguns minutos
                  para realizar o pagamento no seu banco.
                </Text>
              )}
            </Box>
          }
        />
      )}

      <Flex
        w="100%"
        maxWidth="none"
        h={'100%'}
        p={['40px 16px', '40px 16px', '40px 32px']}
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <Loading loading={loadingPage}>
          <Box>
            <Text textStyle="h4" color={colors.palette.primary}>
              Concluir o processo e garantir a minha bolsa
            </Text>

            <Divider size={2} orientation="horizontal" />

            <Text
              color={colors.palette.primary}
              sx={{
                strong: {
                  color: colors.palette.lilac,
                  fontWeight: 600
                }
              }}
            >
              Para concluir o processo e{' '}
              <strong>
                garantir a sua bolsa de {offerData?.examResult?.scholarship} até
                o final do curso
              </strong>
              , você precisa pagar a taxa de Pré-Matrícula do Beduka, conforme o{' '}
              <LinkChakra
                isExternal
                href="https://prd-beduka-campaign-assets.s3.amazonaws.com/regulamento/2021.3_VALE_BOLSA_BEDUKA.pdf"
                textDecoration="underline"
                color="palette.secondary"
              >
                regulamento do programa
              </LinkChakra>
              .
            </Text>

            <Divider size={4} orientation="horizontal" />

            <Box width="100%">
              <Text
                fontWeight={500}
                sx={{
                  del: {
                    fontSize: '18px'
                  },
                  b: {
                    fontWeight: 600,
                    color: colors.palette.primary,
                    fontSize: '20px'
                  }
                }}
                textAlign="center"
              >
                Valor da pré matrícula de{' '}
                <del>
                  {SanitizationUtils.formatCurrencyToBRL(
                    offerData?.scholarship?.price || 0
                  )}
                </del>{' '}
                <br />
                por{' '}
                <b>
                  {SanitizationUtils.formatCurrencyToBRL(
                    offerData?.scholarship?.scholarship_price || 0
                  )}
                </b>
              </Text>
            </Box>

            <Divider size={2} orientation="horizontal" />

            <Box
              margin="0 auto"
              borderRadius="9px"
              width="max-content"
              textAlign="center"
              padding="8px 12px"
              background="#E4E4E4"
            >
              <Text color="palette.primary">
                Taxa paga apenas uma vez e sem renovações semestrais
              </Text>
            </Box>

            <Divider size={4} orientation="horizontal" />

            <Text fontSize="0.925rem" color={colors.palette.primary}>
              Selecione abaixo o meio de pagamento desejado, e preencha os
              campos de acordo com as <b>informações do PAGANTE</b> <br />
              (As informações não precisam ser necessariamente do aluno, mas
              devem ser obrigatoriamente do titular da compra).
            </Text>
          </Box>

          <Divider size={4} orientation="horizontal" />

          <Tabs
            isLazy
            variant="enclosed"
            index={tabIndex()}
            onChange={handlePaymentMethodChange}
          >
            <TabList>
              <Tab
                color={
                  paymentData.method === 'CREDIT_CARD'
                    ? '#FFFFFF !important'
                    : 'palette.primary'
                }
                borderRadius="14px 14px 0px 0px"
                textTransform="uppercase"
                background={
                  paymentData.method === 'CREDIT_CARD'
                    ? 'palette.primary'
                    : 'inherit'
                }
                padding="8px 24px"
                fontSize="14px"
              >
                Cartão
              </Tab>

              <Tab
                color={
                  paymentData.method === 'BOLETO'
                    ? '#FFFFFF !important'
                    : 'palette.primary'
                }
                borderRadius="14px 14px 0px 0px"
                textTransform="uppercase"
                background={
                  paymentData.method === 'BOLETO'
                    ? 'palette.primary'
                    : 'inherit'
                }
                padding="8px 24px"
                fontSize="14px"
              >
                Boleto
              </Tab>
            </TabList>

            <TabPanels
              background="white"
              boxShadow="0px 0px 6px #00000029"
              borderRadius="0px 20px 20px 20px"
            >
              <TabPanel padding="28px 24px">
                {holderInputs()}

                <Divider size={3} orientation="horizontal" />

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Box width={['100%', '100%', '70%']}>
                    <Flex flexWrap="wrap" justifyContent="space-between">
                      <Box width={['100%', '52.5%']}>
                        <StyledInput
                          tabIndex={1}
                          as={InputMask}
                          mask="9999 9999 9999 9999"
                          id="input_credit_card_number"
                          autoComplete="cc-number"
                          label="Número do cartão"
                          name="cardNumber"
                          value={paymentData.creditCardData?.cardNumber || ''}
                          onChange={({ target }) =>
                            handleCreditCardDataChange(
                              'cardNumber',
                              SanitizationUtils.getOnlyNumber(target.value)
                            )
                          }
                          placeholder="9999 9999 9999 9999"
                          isRequired
                          helper={validation.cardNumber}
                          isInvalid={Boolean(validation.cardNumber)}
                        />
                      </Box>

                      <Box width={['48%', '25%']}>
                        <StyledInput
                          tabIndex={2}
                          as={InputMask}
                          mask="99/99"
                          id="input_credit_card_expiration_date"
                          autoComplete="cc-exp"
                          label="Validade"
                          name="expiryDate"
                          value={paymentData.creditCardData?.expiryDate || ''}
                          onChange={({ target }) =>
                            handleCreditCardDataChange(
                              'expiryDate',
                              SanitizationUtils.getOnlyNumber(target.value)
                            )
                          }
                          placeholder="99/99"
                          isRequired
                          helper={validation.expiryDate}
                          isInvalid={Boolean(validation.expiryDate)}
                        />
                      </Box>

                      <Box width={['48%', '20%']}>
                        <StyledInput
                          tabIndex={3}
                          id="input_credit_card_cvc"
                          autoComplete="cc-csc"
                          label="CVC"
                          name="cvc"
                          maxLength={5}
                          value={paymentData.creditCardData?.cvc || ''}
                          onChange={({ target }) =>
                            handleCreditCardDataChange(
                              'cvc',
                              SanitizationUtils.getOnlyNumber(target.value)
                            )
                          }
                          placeholder="9999"
                          isRequired
                          helper={validation.cvc}
                          isInvalid={Boolean(validation.cvc)}
                        />
                      </Box>

                      <Divider size={2} orientation="horizontal" />

                      <Box width={['100%', '46%']}>
                        <FormControl>
                          <FormLabel
                            fontSize="calc(0.85rem * 1)"
                            fontWeight={500}
                            color={colors.grayScale[4]}
                          >
                            Seu nome (Como no cartão)
                          </FormLabel>
                          <StyledInput
                            tabIndex={4}
                            id="input_credit_card_holder_name"
                            autoComplete="cc-name"
                            name="holderName"
                            value={paymentData.creditCardData?.holderName || ''}
                            onChange={({ target }) =>
                              handleCreditCardDataChange(
                                'holderName',
                                target.value
                              )
                            }
                            placeholder="Digite como no cartão"
                            isRequired
                            helper={validation.holderName}
                            isInvalid={Boolean(validation.holderName)}
                          />
                        </FormControl>
                      </Box>

                      <Box width={['100%', '52.5%']}>
                        <FormControl
                          isRequired
                          id="select_credit_card_installments"
                        >
                          <FormLabel
                            fontSize="calc(0.85rem * 1)"
                            fontWeight={500}
                            color={colors.grayScale[4]}
                          >
                            Parcelas
                          </FormLabel>

                          <Select
                            tabIndex={5}
                            value={paymentData.installments}
                            onChange={({ target }) =>
                              handlePaymentDataChange(
                                'installments',
                                +target.value
                              )
                            }
                            background="white"
                            border={'none'}
                            boxShadow={'0px 0px 4px #00000029'}
                            isInvalid={validation.credit_card_installments}
                            errorBorderColor="crimson"
                          >
                            {getInstallmentsCreditCard()}
                          </Select>
                        </FormControl>
                      </Box>
                    </Flex>
                  </Box>

                  <Box
                    mt={['32px', '32px', 0]}
                    width={['100%', '100%', '30%']}
                    textAlign="center"
                  >
                    <Flex justifyContent="center" flexWrap={['wrap']}>
                      <Box
                        width="100%"
                        display={['none', 'none', 'flex']}
                        justifyContent="center"
                        flexWrap="wrap"
                      >
                        <CreditCardIcon
                          fontSize="98px"
                          color={colors.palette.primary}
                        />

                        <Divider size={4} orientation="horizontal" />
                      </Box>

                      <Button
                        width="90%"
                        onClick={handlePaymentSubmit}
                        colorScheme="highlightAction"
                        disabled={loadingPayment}
                        isLoading={loadingPayment}
                      >
                        Pagar carta de
                        <br /> pré-matrícula
                      </Button>
                      <Divider size={1} orientation="horizontal" />
                      <Text textStyle="caption" width={['80%']} fontSize="12px">
                        *Para garantir a sua bolsa até o final do curso.
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </TabPanel>

              <TabPanel padding="32px 24px">
                {holderInputs()}

                <Divider size={2} orientation="horizontal" />

                <DividerChakra borderColor={colors.grayScale[9]} />

                <Divider size={2} orientation="horizontal" />

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Box width={['100%', '100%', '70%']}>
                    <UnorderedList>
                      <ListItem mb="24px">
                        Você pode baixar o boleto e pagar no banco ou casa
                        lotérica mais próxima;
                      </ListItem>
                      <ListItem mb="24px">
                        Se preferir copie o código de barras e pague no seu
                        banco online;
                      </ListItem>
                      <ListItem>
                        Após a geração do boleto é necessário aguardar alguns
                        minutos para realizar o pagamento no seu banco.
                      </ListItem>
                    </UnorderedList>
                  </Box>

                  <Box
                    mt={'32px'}
                    width={['100%', '100%', '30%']}
                    textAlign="center"
                  >
                    <Flex justifyContent="center" flexWrap={['wrap']}>
                      <Box
                        width="100%"
                        display={['none', 'none', 'flex']}
                        justifyContent="center"
                        flexWrap="wrap"
                      >
                        <Image
                          src={BoletoImg}
                          alt="Boleto"
                          width={92}
                          height={'auto'}
                        />

                        <Divider size={4} orientation="horizontal" />
                      </Box>

                      <Button
                        onClick={handlePaymentSubmit}
                        colorScheme="highlightAction"
                      >
                        Gerar boleto
                      </Button>
                      <Divider size={1} orientation="horizontal" />
                      <Text
                        textStyle="caption"
                        width={['70%', '60%', '70%']}
                        fontSize="12px"
                      >
                        *Pague para garantir a sua bolsa até o final do curso.
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Loading>
      </Flex>
    </ProfileLayout>
  )
}

export default PaymentPage
