import { Box, Heading, useMediaQuery } from '@chakra-ui/react'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from 'pure-react-carousel'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { Element } from 'react-scroll'
import uniqid from 'uniqid'

import 'pure-react-carousel/dist/react-carousel.es.css'

import Testimonial from '@/components/Elements/Testimonial'
import { Divider } from '@/components/Elements'

const Testimonials = () => {
  const [isMobile] = useMediaQuery('(max-width: 780px)')

  const testimonials = [
    {
      name: 'Cauã Silva',
      institution: 'UNINASSAU',
      course: 'Direito',
      state: 'Pernambuco',
      city: 'Garanhus',
      phrase:
        '"Se tem uma palavra para definir, eu definiria como inacreditável."',
      thumbnail: '/testimonials/caua.png',
      video: 'https://youtu.be/ZIHlkeoWXVA'
    },
    {
      name: 'Rita Cardoso',
      institution: 'UNG',
      course: 'Ciência da Computação',
      state: 'São Paulo',
      city: 'Bonsucesso',
      phrase:
        '"Eu já estou quase encerrando o semestre e estou muito feliz com a instituição e com os professores. Estou amando o curso!"',
      thumbnail: '/testimonials/rita.png',
      video: 'https://youtu.be/_lOq5VySapA'
    },
    {
      name: 'Tiago Martins',
      institution: 'UNINASSAU',
      course: 'Psicologia',
      state: 'Pará',
      city: 'Belém',
      scholarship: 75,
      phrase:
        '"O desconto é real! Todas as mensalidades que paguei até agora foram com 75% de desconto"',
      thumbnail: '/testimonials/thiago.png',
      video: 'https://youtu.be/a0tv9gz0ztc'
    },
    {
      name: 'Denize Dias',
      institution: 'UNINASSAU',
      course: 'Enfermagem',
      state: 'Ceará',
      city: 'Maracanau',
      phrase:
        '"Obrigado Beduka por me dar a oportunidade de alavancar minha carreira."',
      thumbnail: '/testimonials/denize.png',
      video: 'https://youtu.be/hs0aPtZebEA'
    },
    {
      name: 'Lívia e Geovana Andrade',
      institution: 'UNINASSAU',
      course: 'Medicina V. e Ciência da Computação',
      state: 'Recife',
      city: 'Pernambuco',
      scholarship: '50 e 100',
      phrase: '"Eu e minha irmã somos o exemplo de que o Vale Bolsa é real".',
      thumbnail: '/testimonials/irmas.png',
      video: 'https://youtu.be/ZiYYBxMY_mI'
    },
    {
      name: 'Suzan Menezes',
      institution: 'UNG',
      course: 'Análise de Desenvolvimento de Sistemas',
      state: 'São Paulo',
      city: 'Guarulhos',
      phrase:
        '"Depois de tanto tempo, não conseguindo entrar em uma faculdade, graças ao Beduka, eu finalmente consegui".',
      thumbnail: '/testimonials/suzan.png',
      video: 'https://youtu.be/PzLk7BC64_c'
    }
  ]

  return (
    <Box as={Element} name="testimonials" w="100%" maxW="960px" m="0 auto">
      <Heading
        textAlign="center"
        fontSize={['32px', '32px', '36px']}
        lineHeight="1.3"
        fontWeight="bold"
        color={['#9B36DD']}
      >
        Conheça alguns <br />
        <span style={{ color: '#0B1880' }}>
          vencedores de edições anteriores
        </span>
      </Heading>

      <Divider size={2} orientation="horizontal" />

      <Box pos="relative">
        <CarouselProvider
          naturalSlideWidth={30}
          naturalSlideHeight={isMobile ? 47 : 60}
          visibleSlides={isMobile ? 1 : 3}
          totalSlides={testimonials.length}
          lockOnWindowScroll={true}
          infinite
        >
          <Slider style={{ minHeight: '630px' }}>
            {testimonials.map((item, index) => (
              <Slide key={uniqid()} index={index}>
                <Testimonial data={item} />
              </Slide>
            ))}
          </Slider>
          <ButtonBack className="carousel-btn carousel-btn-back">
            <AiOutlineArrowLeft />
          </ButtonBack>
          <ButtonNext className="carousel-btn carousel-btn-next">
            <AiOutlineArrowRight />
          </ButtonNext>
          {isMobile && <DotGroup className="carrousel-dot-group" />}
        </CarouselProvider>
      </Box>
    </Box>
  )
}

export default Testimonials
