import React, { createContext } from 'react'
import axios from 'axios'

import AuthService from '@/services/AuthService'

export const ApiContext = createContext()

function ApiProvider({ children }) {
  const token = AuthService.authToken
  const CAMPAIGN_ID = 1

  let baseURL = 'http://localhost:3333'

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    baseURL = 'https://api.vale-bolsa.beduka.com'
  } else if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
    baseURL = 'https://beta.api.vale-bolsa.beduka.com'
  }

  const http = axios.create({
    baseURL
  })

  http.interceptors.request.use(function (config) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    config.headers.campaignId = CAMPAIGN_ID

    return config
  })

  http.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return (
    <ApiContext.Provider
      value={{
        http
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

export default ApiProvider
