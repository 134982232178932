import axios from 'axios'
import axiosRetry from 'axios-retry'

import AppUrl from '@/config/url'

import AuthService from '@/services/AuthService'

const CAMPAIGN_ID = 1

const ApiService = axios.create({
  baseURL: `${AppUrl.api_url}`
})

axiosRetry(ApiService, {
  retries: 1,
  retryDelay: retryCount => 1000 * retryCount
})

ApiService.interceptors.request.use(async config => {
  const isLoggedIn = AuthService.authToken

  if (isLoggedIn) {
    config.headers.Authorization = `Bearer ${AuthService.authToken}`
  }

  config.headers.campaignId = CAMPAIGN_ID

  return config
})

ApiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default ApiService

export const ViaCepApiService = axios.create({
  baseURL: 'https://viacep.com.br/ws/'
})
