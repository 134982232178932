import { Box, Heading, Flex, Text, Image, Button } from '@chakra-ui/react'
import { scroller } from 'react-scroll'
import uniqid from 'uniqid'
import { useGlobalStateStore } from '@/store/GlobalState'
import { Divider } from '@/components/Elements'
import { formattedDateToNumericPT } from '@/utils/dateUtils'

function HowParticipate() {
  const { campaign } = useGlobalStateStore()

  const signUpView = ['subscription'].includes(campaign.campaignStatus)

  const steps = [
    {
      title: 'Inscrição',
      description:
        'Faça gratuitamente a sua inscrição, escolhendo o curso, faculdade e cidade de interesse.',
      color: '#12CFD8'
    },
    {
      title: 'Prova',
      description: `Faça a Prova de Conhecimento do Beduka, que acontecerá nos dias ${campaign?.exam_date_instructions?.replace(
        '<br/>',
        ''
      )}.`,
      color: '#0B1880'
    },
    {
      title: 'Resultado',
      description: `Confira o resultado no dia ${formattedDateToNumericPT(
        campaign.result_release_initial_date
      )} aqui no site do Vale Bolsa.`,
      color: '#9B36DD'
    },
    {
      title: 'Matrícula',
      description:
        'Caso aprovado, emita e pague a carta de Pré-Matrícula do Beduka que te dará direito a uma bolsa de estudos até o final do curso.',
      color: '#EE3DB6'
    }
  ]
  return (
    <Box
      bg="#E8F5F7"
      p={['60px 20px 25px 20px', '60px 20px 25px 20px', '90px 0']}
    >
      <Box pos="relative" w="100%" maxW="1117px" m="0 auto">
        <Heading
          textAlign={['center']}
          fontSize={['32px', '32px', '36px']}
          color="#0B1880"
          mb={[9, 9, 16]}
        >
          Quais são as etapas <br /> do Vale Bolsa?
        </Heading>
        <Image
          d={['none', 'none', 'block']}
          pos="absolute"
          bottom="-60px"
          left="50%"
          transform="translateX(-50%)"
          src="/vectors/waves.svg"
          alt="..."
          w="280px"
        />
        <Box pos="relative" w="100%" maxW="690px" m="0 auto">
          {steps.map((step, index) => (
            <Flex
              key={uniqid()}
              height="100%"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              mb={[7, 7, 10]}
            >
              <Box
                height={['165px', '135px', '135px']}
                width={['60px', '60px', '88px']}
              >
                <Flex
                  height="100%"
                  fontSize={['28px', '28px', '36px']}
                  color="white"
                  bg={step.color}
                  alignItems="center"
                  borderLeftRadius="2xl"
                  justifyContent="center"
                  zIndex={99}
                >
                  {index + 1}
                </Flex>
              </Box>
              <Box
                bg="white"
                p={['20px 10px', '20px 10px', '30px 40px']}
                borderRightRadius="2xl"
                height={['165px', '135px', '135px']}
                w={[
                  'calc(100% - 60px)',
                  'calc(100% - 60px)',
                  'calc(100% - 88px)'
                ]}
                zIndex={99}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignContent="center"
                >
                  <Text
                    fontSize={'2xl'}
                    fontWeight="bold"
                    lineHeight="1.2"
                    color={step.color}
                  >
                    {step.title}
                  </Text>

                  <Divider size={1} orientation="horizontal" />

                  <Text color="gray.600" fontSize={['13px', '13px', '14px']}>
                    {step.description}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          ))}
          {signUpView && (
            <Flex
              alignItems={['left', 'left', 'center']}
              justifyContent={['left', 'left', 'center']}
            >
              <Button
                size="2xl"
                bg="#EE3DB6"
                color="white"
                cursor="pointer"
                p={['15px 25px', '15px 25px', '20px 55px']}
                mt={['20px']}
                mb={['30px', '30px', 0]}
                w={['100%', '100%', 'fit-content']}
                fontSize={['16px', '16px', '20px']}
                _hover={{ bg: '#EE3DB6', opacity: 0.8 }}
                _active={{ bg: '#EE3DB6', opacity: 0.8 }}
                onClick={() => {
                  scroller.scrollTo('subscription', {
                    smooth: true,
                    spy: true,
                    duration: 500
                  })
                }}
              >
                Inscrever agora
              </Button>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default HowParticipate
