import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'

import { Container } from '@/styles/global'
import { Page, Grid } from '@/styles/pages/Success'

import successSrc from '@/assets/images/success.png'
import animationData from '@/assets/email-animation.json'

import useForm from '@/contexts/hooks/use-form'
import { useGlobalStateStore } from '@/store/GlobalState'
import useDidMount from '@/contexts/hooks/useDidMount'
import { Text } from '@chakra-ui/layout'
import colors from '@/styles/colors'
import { Divider } from '@/components/Elements'
import UserService from '@/services/UserService'

const secondsTimeLeft = 10

const SubscriptionSuccessPage = () => {
  const { success, reset } = useForm() as {
    success: boolean
    reset: () => void
  }
  const history = useHistory()
  const globalStateStore = useGlobalStateStore()

  const [timeLeft, setTimeLeft] = useState<number>(secondsTimeLeft)

  const timerCountdown = () => {
    let timeInSeconds = secondsTimeLeft

    const interval = setInterval(async function () {
      if (timeInSeconds > 0) {
        timeInSeconds -= 1
        setTimeLeft(timeInSeconds)
      } else {
        const userInfo = await UserService.getInfo()

        if (userInfo?.userData) {
          globalStateStore.setUserData(userInfo?.userData)
        }

        window.location.href = 'https://www.espaco.vale-bolsa.beduka.com/'

        reset()

        clearInterval(interval)
      }
    }, 1000)
  }

  useDidMount(() => {
    if (!success) {
      history.push('/')
    } else {
      timerCountdown()
    }
  })

  return (
    <Page>
      <Container>
        <Grid>
          <motion.main layoutId="successCard">
            <Text textStyle="h4" color={colors.palette.primary}>
              Parabéns! Inscrição realizada com sucesso
            </Text>

            <Divider size={2} orientation="horizontal" />

            <div className="message-animation">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData
                }}
                height={250}
                width={250}
                isStopped={false}
                isPaused={false}
              />
            </div>
            <p>
              Enviamos uma senha com instruções para o seu e-mail para
              acompanhar sua prova e resultado. Não esqueça de verificar a Caixa
              de Spam ou Lixeira.
              <br />
              <br />
              <b>Boa sorte!</b>
              <br />
              <small style={{ color: '#000' }}>
                Você será redirecionado em {timeLeft} segundos
              </small>
            </p>
          </motion.main>
          <div className="rocket-animation">
            <motion.img
              layoutId="successImg"
              src={successSrc}
              alt="Vale Bolsa - Beduka"
            />
          </div>
        </Grid>
      </Container>
    </Page>
  )
}

export default SubscriptionSuccessPage
