class StorageService {
  localSet(key: string, data: any): void {
    const stringData = JSON.stringify(data)

    const encodedData = btoa(stringData)

    localStorage.setItem(key, encodedData)
  }

  localGet<ExpectedData>(key: string): ExpectedData | null {
    const encodedData = localStorage.getItem(key)

    if (!encodedData) {
      return null
    }

    const stringData = atob(encodedData)

    const data: ExpectedData = JSON.parse(stringData)

    return data
  }

  localDelete(key: string): void {
    localStorage.removeItem(key)
  }
}

export default new StorageService()
