import {
  Box,
  Text,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'
import { MdInfo as InfoIcon } from 'react-icons/md'

import { Divider } from '@/components/Elements'
import { PercDiscount } from '@/protocols/Offer.protocol'

import colors from '@/styles/colors'

type RegressiveDiscountProps = {
  data: PercDiscount[]
}

const RegressiveDiscount = ({ data }: RegressiveDiscountProps) => (
  <Popover placement="top-start" isLazy>
    <PopoverTrigger>
      <Link
        color="palette.gray"
        fontSize="0.975rem"
        fontWeight="medium"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <InfoIcon
          style={{
            marginRight: '4px'
          }}
        />
        Desconto regressivo
      </Link>
    </PopoverTrigger>

    <PopoverContent
      className="custom-popover"
      marginTop="16px"
      color="palette.primary"
      boxShadow="0px 0px 10px #00000029"
      borderRadius="24px"
      padding="16px 8px"
      width="450px"
      maxWidth="90vw"
    >
      <PopoverArrow />
      <PopoverCloseButton
        top="1rem"
        right="1rem"
        border={`1px solid ${colors.palette.primary}`}
        borderRadius="full"
      />
      <PopoverHeader border="none">
        <Text textStyle="h5" color="palette.primary">
          Desconto regressivo
        </Text>
      </PopoverHeader>

      <PopoverBody>
        <Box maxHeight="350px" overflow="auto" className="custom-scrollbar">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Semestre</Th>

                <Th isNumeric>Bolsa</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.period}º Semestre</Td>
                  <Td isNumeric>{item.discount}%</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Divider size={2} orientation="horizontal" />

        <Text textAlign="center" fontSize="0.875rem" color="#4a5568">
          O desconto é aplicado de acordo com o semestre
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

export default RegressiveDiscount
