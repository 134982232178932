import { useState, useEffect, ReactElement } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import {
  Flex,
  Box,
  Stack,
  Heading,
  Text,
  Button,
  Image,
  List,
  ListItem,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Skeleton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import InputMask from 'react-input-mask'
import {
  MdLogout as LogoutIcon,
  MdKeyboardArrowRight as RightIcon,
  MdNotifications as NotificationIcon
} from 'react-icons/md'
import { FaWhatsapp as WhatsappIcon } from 'react-icons/fa'

import { Divider } from '@/components/Elements'

import ResultModal from '@/components/Elements/ResultModal'
import logoSrc from '@/assets/vale-bolsa-logo.svg'

import AuthService from '@/services/AuthService'
import colors from '@/styles/colors'
import { IScholarshipData } from '@/protocols/User.protocol'
import { useGlobalStateStore } from '@/store/GlobalState'
import ApiService from '@/services/ApiService'
import { formattedStringDateToStringDateEn } from '@/utils/dateUtils'

dayjs.locale('pt-br')

interface IProfileNavigator {
  defaultEmail: string
  defaultBirthDate: string | Date
  data: any
  loading: boolean
  result: any
  subscriptionId: number
  scholarship: IScholarshipData
}

function ProfileNavigator({
  defaultEmail,
  defaultBirthDate,
  data,
  loading,
  result,
  subscriptionId,
  scholarship
}: IProfileNavigator) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenModal, onClose: onCloseModal } = useDisclosure()

  const toast = useToast()
  const history = useHistory()
  const location = useLocation()
  const { campaign: campaignData, order: orderData } = useGlobalStateStore()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [birthDate, setBirthDate] = useState<string>('')
  const [hasAlteredEmail, setAlteredEmail] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')

  const [submitLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (defaultEmail) {
      setEmail(defaultEmail)
      setFirstName(data.first_name)
      setLastName(data.last_name)
      setBirthDate(dayjs(defaultBirthDate).format('DD/MM/YYYY'))
    }
  }, [defaultEmail])

  async function submit() {
    setLoading(true)

    try {
      await ApiService.patch('user', {
        email: hasAlteredEmail ? email : undefined,
        firstName,
        lastName,
        birthDate: formattedStringDateToStringDateEn(birthDate),
        password,
        repeatPassword
      })

      toast({
        title: 'Sucesso!',
        description: 'Os dados da conta foram atualizados',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      setPassword('')
      setRepeatPassword('')
      setAlteredEmail(false)
    } catch (err: any) {
      toast({
        title: 'Oops... ocorreu um problema',
        description: err.response.data
          ? err.response.data.errors[0].message
          : 'Ocorreu um problema não reconhecido',
        status: 'warning',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  const logout = () => {
    AuthService.logout('/')
  }

  const MenuOptionComponent = (data: {
    path?: string
    title: string
    description: string
    rightIcon: ReactElement
    notificationColor?: {
      background: string
      icon: string
    }
    eventClick: () => void
  }) => {
    const isActive = data?.path ? location.pathname.includes(data?.path) : false

    return (
      <Skeleton isLoaded={!loading}>
        <ListItem
          fontSize={['13px', '13px', '14px']}
          lineHeight="1"
          fontWeight="400"
          py={4}
          borderRadius="15px"
          cursor="pointer"
          transition="all ease .3s"
          onClick={data.eventClick}
          boxShadow="0px 3px 6px #00000029"
          _hover={{
            opacity: 0.8
          }}
          background={isActive ? colors.palette.lilac : 'white'}
          position="relative"
        >
          <Flex
            borderLeft={`solid 5px ${colors.palette.lilac}`}
            width="100%"
            _hover={{
              borderLeftColor: `${colors.palette.lilac}B3`
            }}
            padding="4px 16px 4px 24px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="75%">
              <Text
                color={isActive ? colors.grayScale[11] : colors.palette.lilac}
                textStyle="h6"
                fontWeight={550}
              >
                {data.title}
              </Text>

              <Divider size={1} orientation="horizontal" />

              <Text
                fontSize="12px"
                color={isActive ? colors.grayScale[10] : '#686868'}
              >
                {data.description}
              </Text>
            </Box>

            <Box width="15%">
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                color={isActive ? colors.grayScale[10] : colors.palette.lilac}
              >
                {data.rightIcon}
              </Flex>
            </Box>
          </Flex>

          {data.notificationColor && (
            <Box
              background={data?.notificationColor.background}
              borderRadius="full"
              padding="5px"
              position="absolute"
              top={'-8px'}
              right={2}
            >
              <NotificationIcon
                color={data?.notificationColor.icon}
                fontSize="16px"
              />
            </Box>
          )}
        </ListItem>
      </Skeleton>
    )
  }
  const ResumeScholarship = () => {
    const orderStatus = orderData?.status

    if (!orderStatus) {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Resumo',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        description: 'Veja um resumo da bolsa selecionada',
        eventClick: () => history.push('/minha-conta')
      })
    }

    if (orderStatus === 'PAID') {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Pagamento aprovado!',
        description: 'Pegue sua carta matrícula',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        notificationColor: {
          background: '#71DB99',
          icon: '#fff'
        },
        eventClick: () => history.push('/minha-conta')
      })
    }

    if (orderStatus === 'NOT_PAID') {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Pagamento recusado!',
        description: 'Atenção! Tente outro meio de pagamento',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        notificationColor: {
          background: '#EE3D3D',
          icon: '#fff'
        },
        eventClick: () => history.push('/minha-conta')
      })
    }

    if (orderStatus === 'WAITING') {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Processando pagamento',
        description: 'Seu pagamento ainda está em aberto',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        notificationColor: {
          background: '#eeeb3d',
          icon: '#fff'
        },
        eventClick: () => history.push('/minha-conta')
      })
    }

    if (orderStatus === 'CREATED') {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Pagamento pendente',
        description: 'Selecione um meio de pagamento',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        notificationColor: {
          background: '#eed33d',
          icon: '#fff'
        },
        eventClick: () => history.push('/minha-conta')
      })
    }

    if (orderStatus === 'REVERTED') {
      return MenuOptionComponent({
        path: '/minha-conta',
        title: 'Pagamento estornado',
        description: 'Houve um estorno do seu pagamento',
        rightIcon: <RightIcon color="inherit" fontSize="40px" />,
        notificationColor: {
          background: '#EE3DB6',
          icon: '#fff'
        },
        eventClick: () => history.push('/minha-conta')
      })
    }
  }

  const inProgress = () => {
    const today = dayjs()
    const startDate = dayjs('2021-02-26 08:00:00')
    const endDate = dayjs('2021-02-27 18:00:00')

    return today.isBefore(endDate) && today.isAfter(startDate)
  }
  return (
    <>
      <Box width="100%" p={['40px 16px', '40px 16px', '50px 24px']}>
        <Stack
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
          mb={['32px', '32px', '50px']}
        >
          <Link to="/">
            <Image src={logoSrc} alt="Vale Bolsa - Beduka" height="54px" />
          </Link>

          <Button
            display="flex"
            leftIcon={<LogoutIcon />}
            variant="unstyled"
            color="gray.600"
            cursor="pointer"
            onClick={logout}
          >
            Sair
          </Button>
        </Stack>
        <Skeleton isLoaded={!loading} height="38px">
          <Text color={colors.palette.primary} textStyle="h2">
            Olá, {data?.first_name}!
          </Text>
        </Skeleton>

        <Divider orientation="horizontal" size={4} />

        <Text color={colors.palette.primary} fontWeight={500}>
          Por aqui você acompanha o status de sua inscrição e resultado da sua
          prova.
        </Text>

        <Divider orientation="horizontal" size={4} />

        <Flex justifyContent="center">
          <List spacing={3} mb="41px">
            {ResumeScholarship()}

            {MenuOptionComponent({
              title: 'Dados pessoais e senha',
              rightIcon: <RightIcon color="inherit" fontSize="40px" />,
              description: 'Visualize ou altere seus dados',
              eventClick: onOpen
            })}

            {inProgress() &&
              MenuOptionComponent({
                title: 'Acessar Prova',
                description: 'Sua prova já está disponível.',
                rightIcon: <RightIcon color="inherit" fontSize="40px" />,
                eventClick: () => window.open('http://bit.ly/37IHjuz', '_blank')
              })}
            {campaignData.campaignStatus === 'result_release' &&
              MenuOptionComponent({
                path: '/resultado',
                title: 'Resultado',
                rightIcon: <RightIcon color="inherit" fontSize="40px" />,
                description: 'Confira a classificação aqui',
                eventClick: () => history.push('/resultado')
              })}
            {MenuOptionComponent({
              title: 'Está com dúvidas?',
              rightIcon: (
                <WhatsappIcon
                  color={colors.palette.secondary}
                  fontSize="32px"
                />
              ),
              description:
                'Clique aqui para entrar em contato conosco pelo WhatsApp',
              eventClick: () =>
                window.open(
                  'https://api.whatsapp.com/send?phone=5581989583016',
                  '_blank'
                )
            })}
          </List>
        </Flex>
      </Box>

      <Drawer isOpen={isOpen} size="lg" placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent background="#fff">
            <DrawerCloseButton />
            <DrawerHeader>Meus dados</DrawerHeader>

            <DrawerBody>
              <FormControl id="firstName" mb={2}>
                <FormLabel>Nome</FormLabel>
                <Input
                  onChange={e => {
                    setFirstName(e.target.value)
                  }}
                  value={firstName}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                />
              </FormControl>
              <FormControl id="lastName" mb={2}>
                <FormLabel>Sobrenome</FormLabel>
                <Input
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                  value={lastName}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                />
              </FormControl>
              <FormControl id="cpf" mb={2}>
                <FormLabel>CPF</FormLabel>
                <Input
                  readOnly
                  disabled
                  value={data ? data.cpf : ''}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                />
              </FormControl>
              <FormControl id="email" mb={2}>
                <FormLabel>Endereço de e-mail</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={e => {
                    setAlteredEmail(true)
                    setEmail(e.target.value)
                  }}
                />
              </FormControl>
              <FormControl id="birthDate" mb={2}>
                <FormLabel>Data de nascimento</FormLabel>
                <Input
                  as={InputMask}
                  mask="99/99/9999"
                  value={birthDate}
                  onChange={e => setBirthDate(e.target.value)}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                >
                  {(inputProps: any) => <Input {...inputProps} />}
                </Input>
              </FormControl>
              <Heading size="md" mt={5} mb={4}>
                Alterar senha
              </Heading>
              <FormControl id="password">
                <FormLabel>Nova senha</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                />
                <FormHelperText color="gray.600">
                  Só é necessário informar caso deseje alterar sua senha
                </FormHelperText>
              </FormControl>
              <FormControl id="repeatPassword" mt={2}>
                <FormLabel>Repita sua nova senha</FormLabel>
                <Input
                  type="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                  borderColor="gray.300"
                  _placeholder={{ color: 'gray.600' }}
                />
              </FormControl>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button color="blue" onClick={submit} isLoading={submitLoading}>
                Salvar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {!result && (
        <Modal isOpen={isOpenModal} onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Resultado</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* <Text>
                O resultado sairá somente no dia{' '}
                {dayjs(releaseDate).format('DD')} de{' '}
                {dayjs(releaseDate).format('MMMM')}. Fique atento aqui no site e
                no seu e-mail, avisaremos por lá também!
              </Text> */}
              <Text>Não foi encontrado resultado para sua inscrição.</Text>
              {/* <br />
              <Text>Boa sorte!!</Text> */}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onCloseModal}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {!!result && (
        <ResultModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          result={result}
          scholarshipData={scholarship}
          subscriptionId={subscriptionId}
        />
      )}
    </>
  )
}

export default ProfileNavigator
