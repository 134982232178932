import React, { useState } from 'react'
import { scroller } from 'react-scroll'
import { Box, Flex, Image, Text, Button } from '@chakra-ui/react'
import CampaignService from '@/services/CampaignService'
import useDidMount from '@/contexts/hooks/useDidMount'
import { ICampaignData } from '@/protocols/Campaign.protocol'
import { useGlobalStateStore } from '@/store/GlobalState'

const TestDate = () => {
  const { campaign } = useGlobalStateStore()
  const [campaignData, setCampaignData] = useState<ICampaignData | null>(null)

  const viewAnchorButton = ['subscription', 'exam', 'result_release'].includes(
    campaign.campaignStatus
  )

  const getCampaignData = async () => {
    try {
      const campaignData = await CampaignService.getCampaignData()

      setCampaignData(campaignData)
    } catch (error) {
      console.log(error)
    }
  }
  useDidMount(() => {
    getCampaignData()
  })

  const anchorText = () => {
    type ITextOption = 'subscription' | 'exam' | 'result_release'

    const textOptions = {
      subscription: 'Inscreva-se',
      exam: 'Participar agora',
      result_release: 'Ver resultado',
      default: ''
    }

    return (
      textOptions[campaignData?.campaignStatus as ITextOption] ||
      textOptions.default
    )
  }

  return (
    <Box bg="#0B1880" p={['60px 20px', '60px 20px', '100px 0 130px 0']}>
      <Flex
        w="100%"
        maxW="1117px"
        m="0 auto"
        flexDir={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDir={['column', 'column', 'row']}
          alignItems="center"
          justifyContent={['center', 'center', 'flex-start']}
        >
          <Image
            src="/icons/date.svg"
            alt="Calendário"
            w={['55px', '55px', '70px']}
            mb={[4, 4, 0]}
          />
          <Box ml={4}>
            {campaignData?.exam_initial_date && campaignData?.exam_final_date && (
              <Text
                fontSize="26px"
                textAlign={['center', 'center', 'left']}
                fontWeight="500"
                color="white"
                dangerouslySetInnerHTML={{
                  __html: campaignData.exam_date_instructions
                }}
              />
            )}
          </Box>
        </Flex>

        {viewAnchorButton && (
          <Button
            ml={[0, 0, 12]}
            mt={[6, 6, 0]}
            bg="#ee0bb6"
            color="white"
            size="lg"
            cursor="pointer"
            _hover={{ bg: '#ee3db6' }}
            _active={{ bg: '#ee3db6' }}
            onClick={() => {
              scroller.scrollTo('subscription', {
                smooth: true,
                spy: true,
                duration: 500
              })
            }}
          >
            {anchorText()}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

export default TestDate
