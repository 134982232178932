import { ICampaignData } from '@/protocols/Campaign.protocol'
import ApiService from './ApiService'

class CampaignService {
  private static cache: ICampaignData | null = null

  async getCampaignData() {
    try {
      /**
       * That's a way to avoid making multiple repeated requests
       * throughout the application lifetime.
       */
      if (CampaignService.cache) {
        return CampaignService.cache
      }

      const { data } = await ApiService.get('/campaign/find')

      const campaignData = data || null

      CampaignService.cache = campaignData

      return campaignData
    } catch (error) {
      return null
    }
  }
}

export default new CampaignService()
