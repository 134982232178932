import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
const pageVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 1,
    transition: { duration: 0.5, ...transition, delay: 0 }
  }
}

export const Page = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  layout: true,
  variants: pageVariants
})`
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  min-height: 100vh;

  background-color: #fff;

  display: flex;

  padding: 50px 15px;
`

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: calc(100% - 460px) 440px;
  grid-gap: 20px;

  width: 100%;
  height: 100%;

  padding-top: 10vh;

  justify-content: center;
  align-items: center;

  div {
    img {
      max-width: 100%;
    }
  }

  main {
    padding: 25px;
    border-radius: 10px;
    background-color: #f3fafb;
  }

  div.message-animation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .rocket-animation {
    display: flex;
    justify-content: center;

    img {
      max-width: 275px;
    }
  }

  ${media.lessThan('900px')`
    display: flex;
    flex-direction: column;

    padding-top: 0;

    div, main {
      width: 100%;

      h1 {
        line-height: 1.1;
      }
    }

    div.rocket-animation,
    div.message-animation {
      display: none
    }
  `}
`
