import AuthProvider from './AuthProvider'
import ApiProvider from './ApiProvider'
import FormProvider from './FormProvider'

function Contexts({ children }) {
  return (
    <AuthProvider>
      <ApiProvider>
        <FormProvider>{children}</FormProvider>
      </ApiProvider>
    </AuthProvider>
  )
}

export default Contexts
