// eslint-disable-next-line no-use-before-define

import { Box, Flex, Skeleton, SkeletonText } from '@chakra-ui/react'
import { Divider } from '@/components/Elements'

const ResultSkeleton: React.FC = () => (
  <Box>
    <Box width="100%">
      <Skeleton width="full" height={110} margin="0 auto" />
    </Box>

    <Divider size={4} orientation="horizontal" />

    <Box width="90%">
      <SkeletonText noOfLines={5} spacing="4" />
    </Box>

    <Divider size={4} orientation="horizontal" />

    <Box width="100%">
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Box width={['100%', '100%', '60%']} mb={['24px', '24px', 0]}>
          <Skeleton width="full" height={45} margin="0 auto" />
        </Box>

        <Box width={['100%', '100%', '38.5%']}>
          <Skeleton width="full" height={45} margin="0 auto" />
        </Box>
      </Flex>
    </Box>
  </Box>
)

export default ResultSkeleton
