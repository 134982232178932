import { Box } from '@chakra-ui/react'

type DividerProps = {
  orientation: 'horizontal' | 'vertical'
  size: number
}

const Divider: React.FC<DividerProps> = props => {
  const { orientation, size } = props
  const customProps = {
    ...(orientation === 'horizontal'
      ? { height: 8 * size, width: '100%' }
      : {}),
    ...(orientation === 'vertical' ? { height: 'auto', width: 8 * size } : {}),
    border: 'none'
  }

  return <Box background="transparent" style={{ ...customProps }} />
}

export default Divider
