import { Box, BoxProps } from '@chakra-ui/react'

type PortletProps = BoxProps & {
  style?: React.CSSProperties
}

const Portlet: React.FC<PortletProps> = props => {
  const { children, style, ...rest } = props

  return (
    <Box
      padding="32px 24px"
      shadow="md"
      borderRadius="16px"
      style={{ ...style }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Portlet
