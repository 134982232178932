import { Button, ButtonProps } from '@chakra-ui/button'

// eslint-disable-next-line @typescript-eslint/ban-types
type IButtonHighlightAction = ButtonProps & {}

const ButtonHighlightAction: React.FC<IButtonHighlightAction> = props => {
  const { children } = props

  return (
    <Button
      {...props}
      as="a"
      cursor="pointer"
      variant="solid"
      colorScheme={props.disabled ? 'blackAlpha' : 'pink'}
      borderRadius="14px"
      padding="24px"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _focus={{
        boxShadow: '0 0 1px 2px #f276f7bf, 0 1px 1px rgba(0, 0, 0, .15)'
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonHighlightAction
