import Hero from '../sections/Hero'
// import Scholarship from '../sections/Scholarship'
import HowWork from '../sections/HowWork'

import Menu from '@/components/Elements/Menu'
import Footer from '@/components/Elements/Footer'
import TestDate from '../sections/TestDate'
import Institutions from '../sections/Institutions'
import Testimonials from '../sections/Testimonials'
import HowParticipate from '../sections/HowParticipate'
import WhatsappButton from '@/components/Elements/WhatsappButton'
import About from '../sections/About'

import useForm from '@/contexts/hooks/use-form'

import { Divider } from '@/components/Elements'
import useDidMount from '@/contexts/hooks/useDidMount'

const HomePage = () => {
  const { reset } = useForm() as { reset: () => void }

  useDidMount(() => {
    reset()
  })

  return (
    <main>
      <Menu inProcess={false} scrollTop={0} />
      <Hero />
      <About />

      <Divider size={4} orientation="horizontal" />

      <HowWork />

      <Divider size={12} orientation="horizontal" />

      <TestDate />
      <Institutions />

      <Divider size={4} orientation="horizontal" />

      <Testimonials />

      <Divider size={4} orientation="horizontal" />

      <HowParticipate />
      <Footer />
      <WhatsappButton />
    </main>
  )
}

export default HomePage
